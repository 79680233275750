import { IClass } from '../../models/Video';
import { assetsUrl, baseUrl } from '../../services/instance';
import TextRoboto from '../text/text-roboto';
import TrendVideoBox from './TrendVideoBox';

type Props = {
  videos: IClass[];
};

export default function TrendVideos({ videos }: Props) {
  return (
    <div className='w-full md:flex md:flex-col md:h-full md:w-[28%] pr-[1%] '>
      <div className='md:text-right pt-8 flex-shrink-0'>
        <TextRoboto className='uppercase text-md lg:text-lg font-bold'>
          Videos of the week
        </TextRoboto>
      </div>
      <div className='md:pt-[5%]  flex-shrink-1 flex md:h-full md:flex-col md:gap-y-6 pl-3 overflow-x-scroll overflow-y-hidden md:overflow-y-scroll md:overflow-x-hidden scrollbar-hide'>
        {videos?.map((video, index) => (
          <TrendVideoBox
            classId={video.PK}
            title={video.title}
            key={index}
            thumbnail={`${assetsUrl}${video.thumbnail.file}`}
            count={index + 1}
            video={video}
          />
        ))}
      </div>
    </div>
  );
}
