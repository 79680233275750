import React, { FC } from 'react';
import Modal from 'react-modal';
import { defaultModalStyles } from '../../utils/modal';
import close from '../../assets/img/close.svg';
import classNames from 'classnames';

type BaseModalProps = {
  open: boolean;
  handleCancel: () => void;
  children: React.ReactNode;
  className?: string;
  buttonClassName?: string;
};

export const BaseModal: FC<BaseModalProps> = ({
  open,
  handleCancel,
  children,
  className,
  buttonClassName,
}) => {
  return (
    <Modal
      isOpen={open}
      style={defaultModalStyles}
      onRequestClose={handleCancel}
      overlayClassName='!z-[2100]'
      className={classNames(
        'bg-mat-black rounded-[32px] p-10 min-w-[320px] w-full max-w-[500px]',
        className,
      )}
    >
      <div className='relative'>
        <button
          className={classNames(
            'absolute text-white z-10 top-0 right-0 translate-x-[110%] translate-y-[-210%] md:translate-x-[210%] ',
            buttonClassName,
          )}
          onClick={handleCancel}
        >
          <img src={close} className='w-[26px]' alt='Close' />
        </button>
      </div>
      {children}
    </Modal>
  );
};
