import { FiltersType, FilterTypeRange } from '../hooks/useFilterState';
import { DEFAULT_FILTER_VALUE, SORTS } from '../components/Filter/filter';
import { formatTime, parseTime } from './time';

enum FILTER_TYPES {
  sort = 'sort',
  values = 'values',
  range = 'range',
}

const rangeCommandIndex: Record<string, number> = {
  ge: 0,
  le: 1,
};

const sortSerializeMap: Record<string, { key: string; value: string }> = {
  [SORTS.NEWEST]: { key: '3', value: 'desc' },
  [SORTS.OLDEST]: { key: '3', value: 'asc' },
  [SORTS.EASYEST]: { key: '2', value: 'asc' },
};

const sortDeserializeMap: Record<string, string> = {
  '3:desc': SORTS.NEWEST,
  '3:asc': SORTS.OLDEST,
  '2:asc': SORTS.EASYEST,
  '2:desc': SORTS.HARDEST,
};
const filterType: Record<keyof FiltersType, FILTER_TYPES> = {
  type: FILTER_TYPES.values,
  sort: FILTER_TYPES.sort,
  level: FILTER_TYPES.values,
  instructor: FILTER_TYPES.values,
  category: FILTER_TYPES.values,
  duration: FILTER_TYPES.range,
};

export const getDefaultFilters = (): FiltersType => ({
  type: new Set(),
  sort: '',
  level: new Set([DEFAULT_FILTER_VALUE]),
  instructor: new Set(),
  category: new Set(),
  duration: [0, 60],
});

export const serializeFilters = (filters: FiltersType): URLSearchParams => {
  const searchParams = new URLSearchParams();
  Object.entries(filters).forEach(([filterKey, value]) => {
    switch (filterType[filterKey as keyof FiltersType]) {
      case FILTER_TYPES.values:
        if ((value as Set<string>).has(DEFAULT_FILTER_VALUE)) {
          return;
        }
        if ((value as Set<string>).size > 0) {
          Array.from((value as Set<string>).values()).forEach((value) => {
            searchParams.append(`filter:${filterKey}:eq`, value);
          });
        }
        break;
      case FILTER_TYPES.range:
        const filterValue = value as FilterTypeRange;
        searchParams.append(`filter:${filterKey}:ge`, formatTime(filterValue[0] * 60, true));
        searchParams.append(`filter:${filterKey}:le`, formatTime(filterValue[1] * 60, true));
        break;
      case FILTER_TYPES.sort:
        const sortValue = sortSerializeMap[value as string];
        if (sortValue) {
          searchParams.set(`sort:${sortValue.key}`, sortValue.value);
        }
        return '';
    }
  });
  return searchParams;
};

export const deserializeFilters = (searchParams: URLSearchParams): FiltersType => {
  const filters: FiltersType = getDefaultFilters();
  searchParams.forEach((filterValue, filterKey) => {
    const [type, key, command] = filterKey.split(':') as [string, keyof FiltersType, string];
    const filterName = type === 'filter' ? key : 'sort';
    switch (filterType[filterName]) {
      case FILTER_TYPES.values:
        if ((filters[filterName] as Set<string>).has(DEFAULT_FILTER_VALUE)) {
          (filters[filterName] as Set<string>).delete(DEFAULT_FILTER_VALUE);
        }
        if (filterValue) {
          (filters[filterName] as Set<string>).add(filterValue);
        }
        break;
      case FILTER_TYPES.range:
        const index = rangeCommandIndex[command];
        if (index > -1) {
          const parsedValue = +(parseTime(filterValue) / 60).toFixed(0);
          (filters[filterName] as FilterTypeRange)[index] = isNaN(parsedValue) ? 0 : parsedValue;
        }
        break;
      case FILTER_TYPES.sort:
        const sortValue = sortDeserializeMap[`${key}:${filterValue}`];
        (filters[filterName] as string) = sortValue;
        break;
    }
  });
  return filters;
};
