import {FC, ReactNode} from "react";

type UnderlineButtonProps = {
    className?: string,
    children?: ReactNode;
    onClick?: () => void;
}

const UnderlineButton: FC<UnderlineButtonProps> = ({className, onClick, children }) => {
    return (
        <button onClick={onClick} className={`border-b p-0 border-white text-xs font-bold tracking-2.8px ${className}`}>{children ? children : "REMOVE"}</button>
    )
}

export default UnderlineButton;