import lockIcon from '../assets/img/lockIcon.svg';
import TextRoboto from '../components/text/text-roboto';

type Props = {
  type?: string;
  instructor?: string;
  duration?: string;
  level?: string;
  title?: string;
  style?: string;
  locked?: boolean;
};
export default function PausedVideoLayer({
  duration,
  style,
  instructor,
  level,
  type,
  title,
  locked,
}: Props) {
  return (
    <div className='absolute top-0 left-0 w-full h-full z-10 p-6 bg-black-transparent'>
      <div className='flex justify-between'>
        <div className='flex items-center bg-orange h-5 rounded-[2px] px-3'>
          <TextRoboto className='font-bold text-[8px] text-white uppercase tracking-2.3px'>
            {level && level}
          </TextRoboto>
        </div>
        {locked && <img src={lockIcon && lockIcon} alt={title} />}
      </div>
      <div className='absolute bottom-[10%]'>
        <div className='mb-[4%]'>
          <TextRoboto className='text-white text-[18px] font-bold tracking-2px'>
            {title && title}
          </TextRoboto>
        </div>
        <div className='text-[12px] font-bold opacity-[0.5] font-roboto tracking-1.2px'>
          {style && <div>Style: {style}</div>}
          <div>Teacher: {instructor && instructor}</div>
          <div>Class Type: {type && type}</div>
          <div>Duration: {duration && duration}</div>
        </div>
      </div>
    </div>
  );
}
