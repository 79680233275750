import { IClass } from './../models/Video';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from './../redux/reduxHooks/useAppDispatch';
import { useCallback } from 'react';
import { useAppSelector } from '../redux/reduxHooks/useAppSelector';
import { setIsLoginModalOpen } from '../redux/auth/auth-slice';
import { setSubscriptionPopupOpen } from '../redux/app/app-slice';

export function useTakeTraining(classElement: IClass, toPreview: boolean = false) {
  const { isSubscriptionValid, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useCallback(() => {
    if (classElement.premium && !isSubscriptionValid) {
      return user ? dispatch(setSubscriptionPopupOpen(true)) : dispatch(setIsLoginModalOpen(true));
    }
    navigate(`/player/${classElement.PK}`, { state: { toPreview } });
  }, [isSubscriptionValid, classElement.PK]);
}
