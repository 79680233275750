import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICategorie } from "../../models/Categorie"

interface IInitialState {
    categories: ICategorie[]
}
const initialState: IInitialState = {
    categories: []
}
const categoriesSlice = createSlice({
    initialState,
    name: 'categories',
    reducers: {
        setCategories(state: IInitialState, action: PayloadAction<ICategorie[]>) {
            state.categories = action.payload
        },
    }
})

export const { setCategories } = categoriesSlice.actions
export const categoriesReducer = categoriesSlice.reducer