import { useEffect } from 'react';
import arrowLeft from '../../assets/img/arrowLeft.svg';
import close from '../../assets/img/close.svg';
import { useTranslation } from 'react-i18next';
import { SubscriptionForm } from '../../components/SubscriptionForm/SubscriptionForm';

const Payment = ({ currentStep, onSetStep, previousStep, goToStep }: any) => {
  useEffect(() => {
    onSetStep(currentStep);
  }, [onSetStep, currentStep]);
  const { t } = useTranslation();

  return (
    <div className='bg-primaryGray text-white w-full lg:h-[calc(100vh-63px)] lg:flex lg:flex-col justify-center items-center'>
      <div className='lg:absolute max-w-3xl mx-auto lg:mx-0 lg:max-w-none  pb-10 lg:pb-0 top-10 w-[100%] flex justify-between '>
        <button
          onClick={previousStep}
          type='button'
          className='border hover:bg-[#49FCD9] border-white w-10 h-10 lg:w-[50px] lg:h-[50px] rounded-full flex justify-center items-center'
        >
          <img src={arrowLeft} alt='Arrow Left' className='h-3/5' />
        </button>
        <button
          onClick={() => goToStep(1)}
          type='button'
          className='border hover:bg-[#49FCD9] border-white w-10 h-10 lg:w-[50px] lg:h-[50px] rounded-full flex justify-center items-center'
        >
          <img src={close} alt='Close' className='h-3/5' />
        </button>
      </div>
      <div className='text-center text-[40px] uppercase mb-10'>
        {t('subscription.payment-title')}
      </div>
      <SubscriptionForm />
    </div>
  );
};

export default Payment;
