import { AppDispatch } from '../store';
import { setIsLoading, setStripeClientSecret, setStripeId } from './payment-slice';
import { payment } from '../../services/payment';

export const createCustomer = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true));
    const stripeId = payment.createStripeCustomer();
    return dispatch(setStripeId(stripeId));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const createStripeSubscription =
  (customerStripeId: string, stripeId: string, planId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await payment.createStripeSubscription(null, stripeId, planId);
      dispatch(setStripeClientSecret(response.data.clientSecret));
    } catch (e) {
      console.log(e);
    }
    dispatch(setIsLoading(false));
  };

export const buyPaypalSubscription =
  (paypalId: string, planId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await payment.paypalSubscription(paypalId, planId);
      window.location.href = response.data;
    } catch (e) {
      console.log(e);
    }
    dispatch(setIsLoading(false));
  };
