import ScrollToTop from '../../components/scroll-to-top';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getPlaylist } from '../../redux/playlist/playlist-actions';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/store';
import { Loader } from '../../components/loaders/loader';
import VideosStack from '../../videos/ViedeosStack/VideosStack';
import { useTranslation } from 'react-i18next';

export default function MainTraining() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { currentPlaylist, isLoading, playlistClasses } = useSelector(
    (state: AppState) => state.playlist,
  );

  useEffect(() => {
    if (id) {
      dispatch(getPlaylist(id));
    }
  }, [id]);

  return (
    <ScrollToTop>
      {isLoading && <Loader withOverlay={true} />}
      {currentPlaylist && (
        <div className='pl-[1%] text-white'>
          <div className=''>
            <div className='h-[264px] bg-[#0c0c0c] flex items-center justify-center'>
              <div className='instructor-name relative'>
                <p
                  style={{ color: '#747474' }}
                  className='uppercase text-2xl bg-[#0c0c0c] w-[171px] absolute -top-4 left-1/2 transform -translate-x-1/2 px-2 text-center'
                >
                  {t('my-training.title')}
                </p>
                <p className='uppercase text-white text-[64px]'>{currentPlaylist.title}</p>
              </div>
            </div>
            <div className='pt-10 pr-[4%]'>
              <VideosStack videos={playlistClasses} emptyMessage={t('my-training.empty')} />
            </div>
          </div>
        </div>
      )}
    </ScrollToTop>
  );
}
