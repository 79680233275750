import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IType } from '../../models/Type';
import { IClass } from '../../models/Video';

interface IInitialState {
  isFilterOpen: boolean;
  allTypes: IType[] | null;
  category: string;
  filteredClasses: IClass[] | null;
  isLoading: boolean;
  selectedCategories: string[];
}
const initialState: IInitialState = {
  isFilterOpen: false,
  allTypes: null,
  category: 'all',
  filteredClasses: null,
  isLoading: false,
  selectedCategories: [],
};
const filterSlice = createSlice({
  initialState,
  name: 'filter',
  reducers: {
    setIsFilterOpen(state: IInitialState, action: PayloadAction<boolean>) {
      state.isFilterOpen = action.payload;
    },
    setAllTypes(state: IInitialState, action: PayloadAction<IType[]>) {
      state.allTypes = action.payload;
    },
    setCategory(state: IInitialState, action: PayloadAction<string>) {
      state.category = action.payload;
    },
    setFilteredClasses(state: IInitialState, action: PayloadAction<IClass[] | null>) {
      state.filteredClasses = action.payload;
    },
    setLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSelectedCategories(state: IInitialState, action: PayloadAction<string[]>) {
      state.selectedCategories = action.payload;
    },
  },
});

export const {
  setIsFilterOpen,
  setAllTypes,
  setCategory,
  setFilteredClasses,
  setLoading,
  setSelectedCategories,
} = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
