import { auth } from '../../services/auth';
import { AppDispatch } from '../store';
import {
  setAccessToken,
  setChangePasswordLoading,
  setEmailToConfirm,
  setRegistrationError,
  setUpdateUserLoading,
  setUser,
  updateUser,
} from './auth-slice';
import { account, AccountChangePassword, AccountUpdateData } from '../../services/account';
import { IUser } from '../../models/User';

export const checkForAuthUser = () => async (dispatch: AppDispatch) => {
  const user = await auth.getAuthenticatedUser();
  if (user) {
    dispatch(setUser(user.attributes));
    dispatch(setAccessToken(user.signInUserSession.accessToken.jwtToken));
  } else {
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
  }
  return user;
};

export const appSignOut = () => async (dispatch: AppDispatch) => {
  try {
    await auth.signOut();
    dispatch(setUser(null));
    dispatch(setAccessToken(null));
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export const userSignUp =
  (values: { password: string; email: string; name: string }) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setRegistrationError(''));
      const user = await auth.signUp(values); //@ts-ignore
      if (user) dispatch(setEmailToConfirm(user.username));
    } catch (error: any) {
      dispatch(setRegistrationError(error.message));
      console.log('error signing up: ', error);
    }
  };

export const getAccount = () => async (dispatch: AppDispatch) => {
  try {
    const response = await account.getAccount();
    dispatch(setUser(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInfo = (userData: AccountUpdateData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setUpdateUserLoading(true));
    await account.updateAccount(userData);
    dispatch(updateUser(userData as IUser));
  } catch (error) {
    console.log(error);
  }
  dispatch(setUpdateUserLoading(false));
};

export const changePassword = (data: AccountChangePassword) => async (dispatch: AppDispatch) => {
  dispatch(setChangePasswordLoading(true));
  try {
    await account.changePassword(data);
  } catch (err) {
    console.log(err);
  }
  dispatch(setChangePasswordLoading(false));
};
