import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
  isLoading: boolean;
  stripeId: string;
  stripeClientSecret: string;
  isPurchaseSuccessful: boolean;
}

const initialState: IInitialState = {
  isLoading: false,
  stripeId: '',
  stripeClientSecret: '',
  isPurchaseSuccessful: false,
};

const paymentSlice = createSlice({
  initialState,
  name: 'playlist',
  reducers: {
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setStripeId(state: IInitialState, action: PayloadAction<string>) {
      state.stripeId = action.payload;
    },
    setStripeClientSecret(state: IInitialState, action: PayloadAction<string>) {
      state.stripeClientSecret = action.payload;
    },
    setIsPurchaseSuccessful(state: IInitialState, action: PayloadAction<boolean>) {
      state.isPurchaseSuccessful = action.payload;
    },
  },
});

export const { setIsLoading, setStripeId, setStripeClientSecret, setIsPurchaseSuccessful } =
  paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
