import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useMediaQuery, { breakpoints } from '../../../hooks/useMediaQuery';
import classNames from 'classnames';
import { useAppDispatch } from '../../../redux/reduxHooks/useAppDispatch';
import { search } from '../../../redux/search/search-actions';
import { useDebounceEffect } from '../../../hooks/useDebounceEffect';
import { useCombobox } from 'downshift';
import { useAppSelector } from '../../../redux/reduxHooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../loaders/loader';
import { SearchResult } from '../../../services/search';
import { getDefaultFilters, serializeFilters } from '../../../utils/filters';
import { setIsLoginModalOpen } from '../../../redux/auth/auth-slice';

const titlesForTypes: Record<string, string> = {
  class: 'Classes',
  instructor: 'Instructors',
  categories: 'Categories',
};

const getUrlForSearchItem = (item: SearchResult) => {
  const filters = getDefaultFilters();
  let searchParams;
  switch (item.type) {
    case 'class':
      return `/player/${item.PK}`;
    case 'instructor':
    case 'choreographer':
      return `/instructors/${item.PK}`;
    case 'category':
      filters.category.add(item.PK);
      searchParams = serializeFilters(filters);
      return `/classes?${searchParams.toString()}`;
    case 'type':
      filters.type.add(item.PK);
      searchParams = serializeFilters(filters);
      return `/classes?${searchParams.toString()}`;
    default:
      return '';
  }
};

function SearchBar() {
  const { t } = useTranslation();

  const matches = useMediaQuery(breakpoints.sm);
  const [open, setOpen] = useState(() => !matches);
  const [query, setQuery] = useState('');
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { isLoading, searchResult } = useAppSelector((state) => state.search);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(!matches);
  }, [matches]);

  useDebounceEffect(
    () => {
      if (query) {
        dispatch(search(query));
      }
    },
    [query],
    1000,
  );

  const onSearchClick = useCallback(() => {
    if (matches) {
      setOpen((current) => !current);
    }
  }, [matches]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items: searchResult,
    onInputValueChange: ({ inputValue }) => {
      setQuery(inputValue ?? '');
    },
    itemToString: (item) => (item ? item.title : ''),
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        if (changes.selectedItem.type === 'class' && !user) {
          dispatch(setIsLoginModalOpen(true));
        } else {
          const url = getUrlForSearchItem(changes.selectedItem);
          if (url) {
            navigate(url);
          }
        }
      }
    },
  });

  const groupedSearchResult = useMemo<
    Record<string, Array<SearchResult & { index: number }>>
  >(() => {
    return searchResult.reduce((result, item, currentIndex) => {
      if (!result[item.type]) {
        result[item.type] = [];
      }
      result[item.type].push({ ...item, index: currentIndex });
      return result;
    }, {} as Record<string, Array<SearchResult & { index: number }>>);
  }, [searchResult]);

  const ListLoader = searchResult.length ? (
    <Loader withOverlay={true} show={isLoading} />
  ) : (
    <li className='flex justify-center '>
      <Loader show={isLoading} />
    </li>
  );

  return (
    <div className='flex items-center w-100 flex-shrink-1 md:ml-20 mr-5'>
      <SearchIcon onClick={onSearchClick} className='cursor-pointer' />
      <div
        className={classNames(' ml-2 w-96  relative flex-shrink-1 ', {
          'w-0 overflow-hidden': !open,
        })}
        {...getLabelProps()}
      >
        <input
          className='search-input outline-0 border-b py-1  border-black w-full focus:border-white'
          type='text'
          {...getInputProps()}
          placeholder={t('header.search-text')}
        />
        <ul
          className={classNames(
            'absolute left-0 text-white max-h-[90vh] overflow-auto scrollbar-hide text-left w-[100%] bg-mat-black z-50 cursor-pointer',
            {
              'border-formGrey border border-t-0': isOpen && query,
            },
          )}
          {...getMenuProps()}
        >
          {isOpen && isLoading ? ListLoader : null}
          {isOpen && (
            <>
              {Object.entries(groupedSearchResult).map(([type, values]) => (
                <div key={type}>
                  <li className='p-2 bg-gray-transparent'>{titlesForTypes[type] || type}</li>
                  {values.map((item) => (
                    <li
                      {...getItemProps({ item, index: item.index })}
                      className={classNames('p-2', {
                        'black-900': highlightedIndex === item.index,
                        'font-bold': selectedItem === item,
                      })}
                      key={item.PK}
                    >
                      {item.title}
                    </li>
                  ))}
                </div>
              ))}
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
export default SearchBar;
