import { ReactNode } from 'react';

export default function TextPoppins({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <span className={`font-poppins ${className}`}>{children}</span>;
}
