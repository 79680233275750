import { Styles } from 'react-modal';

export const defaultModalStyles: Styles = {
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    opacity: 1,
    zIndex: 100,
  },
  overlay: {
    position: 'fixed',
    zIndex: 99,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0, 0.8)',
  },
};
