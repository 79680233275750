import { ReactNode } from 'react';
import TextPoppins from '../text/text-poppins';

type Props = {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
  textColor?: string;
};

export default function BigButton({
  children,
  className,
  icon,
  disabled,
  onClick,
  textColor,
}: Props) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={` relative text-${textColor} rounded-[23px] py-2 uppercase tracking-2.8px ${className}`}
    >
      {icon ? <span className='absolute left-4 top-1/2 -translate-y-1/2'>{icon}</span> : null}
      <TextPoppins>{children}</TextPoppins>
    </button>
  );
}
