import { useCallback } from 'react';
import { setSubscriptionPopupOpen } from '../../redux/app/app-slice';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { BaseModal } from '../modals/BaseModal';
import { SubscriptionForm } from '../SubscriptionForm/SubscriptionForm';
import TextPoppins from '../text/text-poppins';

export default function SubscriptionPopup() {
  const { subscriptionPopupOpen } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(setSubscriptionPopupOpen(false));
  }, []);

  return (
    <BaseModal
      open={subscriptionPopupOpen}
      handleCancel={onClose}
      className='max-w-[980px] md:min-w-[980px] !px-0 !pt-0 !pb-3'
      buttonClassName='md:!top-6 md:!right-7 !translate-x-0 !-translate-y-full md:!translate-y-0 border border-white rounded-full w-[40px] h-[40px] flex items-center justify-center'
    >
      <h3 className='text-white text-center text-[30px] py-6 text-normal uppercase'>
        <TextPoppins>Start your annual plan</TextPoppins>
      </h3>
      {subscriptionPopupOpen ? (
        <SubscriptionForm rounded={false} className='!w-full !max-w-full' />
      ) : null}
    </BaseModal>
  );
}
