import React, { FC, useMemo } from 'react';
import soundOff from '../../assets/img/soundOff.svg';
import soundOn from '../../assets/img/soundOn.svg';
import info from '../../assets/img/Info.svg';
import { useTakeTraining } from '../../hooks/useTakeTraining';
import { IClass } from '../../models/Video';

type BannerIconControlsProps = {
  hotMuted: boolean;
  classId: string;
  handleMute: () => void;
  video: IClass;
};

export const BannerIconControls: FC<BannerIconControlsProps> = ({
  classId,
  hotMuted,
  handleMute,
  video,
}) => {
  const onTakeTrainingClick = useTakeTraining(video, true);
  return (
    <>
      {hotMuted ? (
        <img className='w-7 cursor-pointer' onClick={handleMute} src={soundOff} alt='mute icon' />
      ) : (
        <img className='w-7 cursor-pointer' onClick={handleMute} src={soundOn} alt='mute icon' />
      )}
      <img
        className='w-7 cursor-pointer'
        onClick={onTakeTrainingClick}
        src={info}
        alt='vid info icon'
      />
    </>
  );
};
