import { useEffect, useRef } from 'react';
import { useAppSelector } from '../redux/reduxHooks/useAppSelector';
import { useAppDispatch } from '../redux/reduxHooks/useAppDispatch';
import { setClassToHistory } from '../redux/history/history-actions';

export const useSetClassToHistory = (classId: string, currentTime: number, isPlay: boolean) => {
  const currentTimeRef = useRef<number>(0);
  const requestSentRef = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!user || !classId) {
      return;
    }
    if (currentTime && !currentTimeRef.current) {
      currentTimeRef.current = currentTime;
    } else if (!requestSentRef.current && currentTime && currentTime > currentTimeRef.current) {
      requestSentRef.current = true;
      dispatch(setClassToHistory(classId));
    }
  }, [currentTime, user, classId]);
};
