import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { auth } from '../../services/auth';
import PrimaryButton from '../buttons/primary-button';
import FormInputField from '../Input/FormInputField';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export default function ForgotPassword() {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const initalValues = useMemo(() => ({ email: '' }), []);
  const onSubmit = useCallback(async (values: { email: string }) => {
    const response = await auth.forgotPassword(values.email);
    setMessage(
      response
        ? 'You should recieve message with code on your email, please check it'
        : 'Something went wrong, please try to do it leter',
    );
  }, []);

  return (
    <Formik initialValues={initalValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <h3 className='text-white text-l mb-2 uppercase'>
            {t('account.change-password-form-title')}
          </h3>
          {message && <div className='mb-5'>{message}</div>}
          <div className='mb-5'>
            <FormInputField
              name='email'
              label={t('sign-up.create-account-field-email')}
              type='email'
            />
          </div>
          <PrimaryButton type='submit' disabled={isSubmitting} className='w-[48%] self-end'>
            Reset password
          </PrimaryButton>
        </form>
      )}
    </Formik>
  );
}
