import { useMemo } from 'react';
import { IRelation } from '../models/Video';

export const useRelationProps = (fields: string[], data?: { relations: IRelation[] } | null) => {
  return useMemo(() => {
    if (!data) {
      return {};
    }
    return data.relations.reduce((result, item) => {
      const type = item.GSI1 || item._type;
      if (fields.includes(type)) {
        if (!result[type]) {
          result[type] = [];
        }
        result[type].push(item);
      }
      return result;
    }, {} as Record<string, Array<IRelation>>);
  }, [data]);
};
