import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchResult } from '../../services/search';

interface IInitialState {
  isLoading: boolean;
  searchResult: SearchResult[];
}

const initialState: IInitialState = {
  isLoading: false,
  searchResult: [],
};

const searchSlice = createSlice({
  initialState,
  name: 'search',
  reducers: {
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSearchResult(state: IInitialState, action: PayloadAction<SearchResult[]>) {
      state.searchResult = action.payload.sort((itemA, itemB) =>
        itemA.type > itemB.type ? -1 : 1,
      );
    },
  },
});

export const { setSearchResult, setIsLoading } = searchSlice.actions;

export const searchReducer = searchSlice.reducer;
