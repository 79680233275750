import { INSTANCE } from './instance';

export const plans = {
  getPlans: () => {
    return INSTANCE.get('plan');
  },
  declineSubscription: () => {
    return INSTANCE.post('subscription/decline');
  },
};
