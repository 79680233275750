import { useCallback, useState } from 'react'

export const useToggle = (initialState = false): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState(() => initialState)

  const toggle = useCallback(() => {
    setState((current) => !current)
  }, [])

  const on = useCallback(() => {
    setState(true)
  }, [])

  const off = useCallback(() => {
    setState(false)
  }, [])

  return [state, toggle, on, off]
}
