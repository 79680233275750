import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClass } from '../../models/Video';

export interface IFilter {
  title: string;
  sectionPlace: string;
  items: IClass[];
}

interface IInitialState {
  previewMuted: boolean;
  hotMuted: boolean;
  hotVideo: IClass | null;
  filtersBottom: IFilter[];
  videosOfTheWeek: IClass[] | null;
  isVideoLoading: boolean;
  error: string | undefined;
  playingPreviewVideoClassId?: string;
}
const initialState: IInitialState = {
  previewMuted: true,
  hotMuted: true,
  hotVideo: null,
  filtersBottom: [],
  videosOfTheWeek: null,
  isVideoLoading: false,
  error: undefined,
};
const videoSlice = createSlice({
  initialState,
  name: 'videos',
  reducers: {
    setPreviewMuted(state: IInitialState, action: PayloadAction<boolean>) {
      state.previewMuted = action.payload;
    },
    setHotMuted(state: IInitialState, action: PayloadAction<boolean>) {
      state.hotMuted = action.payload;
    },
    setHotVideo(state: IInitialState, action: PayloadAction<IClass>) {
      state.hotVideo = action.payload;
    },
    setIsVideoLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isVideoLoading = action.payload;
    },
    setFiltersBottom: (state: IInitialState, action: PayloadAction<IFilter[]>) => {
      state.filtersBottom = action.payload;
    },
    setVideosOfTheWeek: (state: IInitialState, action: PayloadAction<IClass[]>) => {
      state.videosOfTheWeek = action.payload;
    },
    setPlayingPreviewVideoClassId: (
      state: IInitialState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.playingPreviewVideoClassId = action.payload;
    },
  },
});

export const {
  setPreviewMuted,
  setHotMuted,
  setHotVideo,
  setIsVideoLoading,
  setFiltersBottom,
  setVideosOfTheWeek,
  setPlayingPreviewVideoClassId,
} = videoSlice.actions;
export const videosReducer = videoSlice.reducer;
