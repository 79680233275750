import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreatePlaylist } from '../../../hooks/useCreatePlaylist';
import { Playlist } from '../../../redux/playlist/playlist-slice';
import { useAppSelector } from '../../../redux/reduxHooks/useAppSelector';
import SecondaryButton from '../../buttons/secondary-button';
import { Loader } from '../../loaders/loader';

type TrainingPopupContentProps = {
  open: boolean;
  playlistButtonRenderer: (playlist: Playlist) => React.ReactNode;
};

export default function TrainingPopupContent({
  open,
  playlistButtonRenderer,
}: TrainingPopupContentProps) {
  const { user } = useAppSelector((state) => state.auth);
  const { isLoading, playlists } = useAppSelector((state) => state.playlist);
  const { training, setTraining, onCreate } = useCreatePlaylist(open);
  const { t } = useTranslation();

  return user ? (
    <div className='lg:w-[270px] text-white  h-[200px] bg-[#101010] rounded-[20px] py-5 px-6'>
      {isLoading && <Loader withOverlay={true} />}
      <div className='flex items-center justify-between'>
        <input
          type={'text'}
          value={training}
          onChange={(e) => setTraining(e.target.value)}
          className='outline-none shadow-none border-b w-[147px] text-white bg-transparent border-[#858585] text-xs h-5'
          placeholder={t('trainings.training-popup-input-text')}
        />
        <SecondaryButton className='py-2 px-3' onClick={onCreate}>
          {t('trainings.training-popup-create-button')}
        </SecondaryButton>
      </div>
      <div className='mt-6'>
        {playlists.map((item) => (
          <div key={item.PK} className='flex items-center justify-between'>
            <p className='uppercase text-sm'>{item.title}</p>
            {playlistButtonRenderer(item)}
          </div>
        ))}
      </div>
    </div>
  ) : null;
}
