import { useEffect, useState } from 'react';
import TextRoboto from '../text/text-roboto';

type Props = {
  title: string;
  onClick: () => void;
  theme?: 'main' | 'secondary';
};

export default function FilterStaticButton({ title, theme, onClick }: Props) {
  const [background, setBackground] = useState<string | undefined>(undefined);

  useEffect(() => {
    switch (theme) {
      case 'main':
        setBackground('orange');
        break;

      case 'secondary':
        setBackground('aqua');
        break;

      default:
        setBackground(undefined);
        break;
    }
  }, [theme]);

  return (
    <button
      key={title}
      onClick={onClick}
      className={`${theme && 'bg-' + background} ${
        !theme && 'border'
      } h-[30px] flex-shrink-0 text-center cursor-pointer text-xs rounded-3xl w-[30%] max-w-[140px] md:w-[140px]`}
    >
      <TextRoboto
        className={`${
          theme ? 'text-black' : 'text-white'
        } tracking-2.8px uppercase text-xxs md:text-xs`}
      >
        {title}
      </TextRoboto>
    </button>
  );
}
