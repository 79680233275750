import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';

type ResetFormikProps = {
  needToReset: boolean;
};

export const ResetFormik: FC<ResetFormikProps> = ({ needToReset }) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (needToReset) {
      resetForm();
    }
  }, [needToReset]);

  return null;
};
