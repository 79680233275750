import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../components/scroll-to-top';
import TextRoboto from '../../components/text/text-roboto';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import CategoryBox from './CategoryBox';
import { useTranslation } from 'react-i18next';
import { ICategorie } from '../../models/Categorie';
import { getDefaultFilters, serializeFilters } from '../../utils/filters';

export default function Category() {
  const navigate = useNavigate();

  const { categories } = useAppSelector((state) => state.categories);
  const { t } = useTranslation();

  const goToCategory = (category: ICategorie) => {
    const filters = getDefaultFilters();
    filters.category.add(category.PK);
    const searchParams = serializeFilters(filters);

    navigate(`/classes?${searchParams.toString()}`);
  };
  return (
    <ScrollToTop>
      <div className={`pb-8 pl-[4%] pr-[4%] text-white`}>
        <div className='text-right my-12'>
          <TextRoboto className='uppercase text-[30px] tracking-12.5%'>
            {t('categories.title')}
          </TextRoboto>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[4%] gap-y-12'>
          {categories &&
            categories.map((category) => (
              <CategoryBox
                key={category.title}
                category={category}
                handleClick={() => goToCategory(category)}
              />
            ))}
        </div>
      </div>
    </ScrollToTop>
  );
}
