import Filter from '../../components/Filter/filter';
import { Loader } from '../../components/loaders/loader';
import ScrollToTop from '../../components/scroll-to-top';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import VideosStack from '../../videos/ViedeosStack/VideosStack';

export default function Classes() {
  const { filteredClasses, isLoading } = useAppSelector((state) => state.filter);

  return (
    <ScrollToTop>
      <div className='pl-[1%] pr-[1%]  md:pr-[4%] z-[2000]'>
        <Filter />
        <VideosStack
          isLoading={isLoading}
          videos={filteredClasses}
          emptyMessage='not one class does not fit your filters, try to choose something else'
        />
        <Loader show={isLoading} global={true} />
      </div>
    </ScrollToTop>
  );
}
