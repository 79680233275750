import { Formik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setError, setIsLoginModalOpen } from '../../redux/auth/auth-slice';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { auth } from '../../services/auth';
import BigButton from '../buttons/big-button';
import PrimaryButton from '../buttons/primary-button';
import MiddleTextDivider from '../dividers/middle-text-divider';
import FormInput from '../Input/FormInput';
import { Loader } from '../loaders/loader';
import TextPoppins from '../text/text-poppins';
import { useTranslation } from 'react-i18next';
import facebookIcon from '../../assets/icons/facebook.svg';
import googleIcon from '../../assets/icons/google.svg';
import appleIcon from '../../assets/icons/apple.svg';

type LoginFormProps = { onForgotPassword: () => void };

export default function LoginForm({ onForgotPassword }: LoginFormProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { authError } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  type Errors = {
    email: string;
    password: string;
  };
  type Values = {
    email: string;
    password: string;
  };
  const registerClick = () => {
    dispatch(setIsLoginModalOpen(false));
    navigate('/sign-up');
  };

  const validate = (values: Values) => {
    const errors = {} as Errors;

    if (!values.email) errors.email = 'required';
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
      errors.email = 'invalid email address';
    if (!values.password) errors.password = 'required';
    return errors;
  };
  useEffect(() => {
    return () => {
      dispatch(setError(null));
    };
  }, []);

  return (
    <div className='rounded-[23px] py-10  px-[10%] md:px-[20%] bg-mat-black'>
      <Formik
        onSubmit={async (values) => {
          dispatch(setError(null));
          const { email, password } = values;
          const { errorMessage, user } = await auth.signIn(email, password);
          console.log(errorMessage, user);

          if (errorMessage) return dispatch(setError(errorMessage));
          if (user) {
            navigate('/');
            window.location.reload();
            return;
          }
        }}
        initialValues={{
          email: '',
          password: '',
        }}
        validate={validate}
      >
        {({ errors, touched, handleSubmit, isSubmitting, handleChange, values, handleBlur }) => (
          <form onSubmit={handleSubmit} className='w-full flex flex-col gap-y-5'>
            <div className='text-center'>
              <h1>
                <TextPoppins className='text-white uppercase text-[28px] tracking-2.8px'>
                  {t('login.title')}
                </TextPoppins>
              </h1>
            </div>
            <FormInput
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              touched={touched.email}
              name='email'
              label={t('login.field-email')}
              type='email'
              onChange={handleChange}
            />
            <FormInput
              onBlur={handleBlur}
              value={values.password}
              error={errors.password}
              touched={touched.password}
              name='password'
              label={t('login.field-password')}
              type='password'
              onChange={handleChange}
            />
            <PrimaryButton type='submit' disabled={isSubmitting} className='w-[48%] self-end'>
              {t('login.login-button')}
            </PrimaryButton>
            {isSubmitting && (
              <div className='self-center'>
                <Loader />
              </div>
            )}
          </form>
        )}
      </Formik>
      {authError && <div className='text-s text-aqua text-center'>{authError}</div>}
      <div className='mt-8 text-center'>
        <MiddleTextDivider color='white' textColor='white' px='3vw'>
          {t('login.or')}
        </MiddleTextDivider>
        <div className='flex flex-col mt-8 gap-y-4'>
          <BigButton
            icon={<img className='w-6' src={facebookIcon} />}
            onClick={auth.facebookSignIn}
            textColor='white'
            className='py-3 text-[12px] md:text-[14px] font-bold bg-[#395185]'
          >
            {t('login.facebook-button')}
          </BigButton>
          <BigButton
            icon={<img className='w-6' src={googleIcon} />}
            onClick={auth.googleSignIn}
            textColor='black'
            className='py-3 text-[12px] md:text-[14px] font-bold bg-white'
          >
            {t('login.google-button')}
          </BigButton>
          <BigButton
            textColor='white'
            icon={<img className='w-6' src={appleIcon} />}
            className='py-3 text-[12px] md:text-[14px] font-bold bg-[#000000]'
          >
            {t('login.apple-button')}
          </BigButton>
        </div>
        <h1 className='text-white uppercase text-[28px]'>{t('login.registration-title')}</h1>
        <BigButton
          textColor='black'
          className='py-2 text-[11px] mt-5 w-full bg-aqua'
          onClick={registerClick}
        >
          {t('login.registration-button')}
        </BigButton>
      </div>
    </div>
  );
}
