import SecondaryButton from '../../components/buttons/secondary-button';
import ScrollToTop from '../../components/scroll-to-top';
import TextRoboto from '../../components/text/text-roboto';
import MyTrainingThumbnail from './my-training-thumbnail';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/store';
import { useCreatePlaylist } from '../../hooks/useCreatePlaylist';
import { Loader } from '../../components/loaders/loader';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { Playlist } from '../../redux/playlist/playlist-slice';
import { assetsUrl } from '../../services/instance';

export default function MainTrainingList() {
  const playlists = useSelector((state: AppState) => state.playlist.playlists);
  const isLoading = useSelector((state: AppState) => state.playlist.isLoading);
  const { training, setTraining, onCreate } = useCreatePlaylist();
  const { t } = useTranslation();

  return (
    <ScrollToTop>
      <div className=' relative text-white'>
        <div className='pr-[4%] pl-[4%]'>
          <div className='flex justify-between items-center flex-wrap md:flex-nowrap'>
            <div className='flex items-center w-full md:w-[30%] order-2 md:order-1 md:my-12 mb-12'>
              <input
                value={training}
                type='text'
                className='w-full min-w-[200px] px-[14px] h-[60px] border border-aqua bg-transparent text text-xs focus:outline-none text-[#858585] font-roboto tracking-2.8px'
                placeholder={t('my-trainings.input-text')}
                onChange={(e) => setTraining(e.target.value)}
              />
              <div className='relative'>
                <SecondaryButton onClick={onCreate} className='ml-5 px-10 py-2'>
                  {t('my-trainings.create-button')}
                </SecondaryButton>
              </div>
            </div>
            <TextRoboto className='text-[30px] my-12 md:my-0 uppercase lg:mt-0 tracking-12.5% md:order-2'>
              {t('my-trainings.title')}
            </TextRoboto>
          </div>
          {playlists.map((item) => (
            <div key={item.PK} className='mb-10 flex flex-col items-center'>
              <MyTrainingThumbnail item={item} />
            </div>
          ))}
        </div>
        <Loader withOverlay={true} show={isLoading} />
      </div>
    </ScrollToTop>
  );
}
