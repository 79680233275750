import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import { IUser } from '../../models/User';
import { INSTANCE } from '../../services/instance';

interface IInitialState {
  user: IUser | null;
  isSubscriptionValid: boolean;
  accessToken: string | null;
  isAuthLoading: boolean;
  isLoginModalOpen: boolean;
  emailToConfirm: string | null;
  authError: string | null;
  updateUserLoading: boolean;
  changePasswordLoading: boolean;
  registrationError: string;
}
const initialState: IInitialState = {
  user: null,
  authError: null,
  isSubscriptionValid: false,
  accessToken: null,
  isAuthLoading: false,
  isLoginModalOpen: false,
  emailToConfirm: null,
  updateUserLoading: false,
  changePasswordLoading: false,
  registrationError: '',
};
const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setUser(state: IInitialState, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
      if (state.user?.subscriptionTo) {
        state.isSubscriptionValid = isAfter(parseISO(state.user?.subscriptionTo), new Date());
      }
    },
    updateUser(state: IInitialState, action: PayloadAction<IUser>) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setIsAuthLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isAuthLoading = action.payload;
    },
    setAccessToken(state: IInitialState, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
      (INSTANCE.defaults.headers as any)['Authorization'] = `Bearer ${action.payload}`;
    },
    setIsLoginModalOpen(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoginModalOpen = action.payload;
    },
    setEmailToConfirm(state: IInitialState, action: PayloadAction<string>) {
      state.emailToConfirm = action.payload;
    },
    setError(state: IInitialState, action: PayloadAction<string | null>) {
      state.authError = action.payload;
    },
    setUpdateUserLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.updateUserLoading = action.payload;
    },
    setChangePasswordLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.changePasswordLoading = action.payload;
    },
    setRegistrationError(state: IInitialState, action: PayloadAction<string>) {
      state.registrationError = action.payload;
    },
  },
});

export const {
  setUser,
  setAccessToken,
  setIsAuthLoading,
  updateUser,
  setIsLoginModalOpen,
  setEmailToConfirm,
  setError,
  setUpdateUserLoading,
  setChangePasswordLoading,
  setRegistrationError,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
