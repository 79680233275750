import { FC, useMemo } from 'react';
import paypalIcon from '../../assets/img/paypallmini.png';
import dottedIcon from '../../assets/img/dottedIcon.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { format, parseISO } from 'date-fns';
import { addMonths } from 'date-fns/esm';

type AccountSubscriptionProps = {
  setOpen: (state: boolean) => void;
};

export const AccountSubscription: FC<AccountSubscriptionProps> = ({ setOpen }) => {
  const { t } = useTranslation();
  const { user, isSubscriptionValid } = useAppSelector((state) => state.auth);
  const plans = useAppSelector((state) => state.plans.plans);
  const monthDelta = useMemo(() => {
    if (user?.planId && plans) {
      const plan = plans.find((plan) => plan.PK === user.planId);
      return plan?.type === 'Month' ? -1 : -12;
    }
    return 0;
  }, [plans, user?.planId]);
  return (
    <div className='w-[100%] px-3 py-5'>
      <div className='text-left text-lg uppercase mb-5'>{t('account.subscription-title')}</div>
      <div>
        {user?.subscriptionMethod && user?.subscriptionTo ? (
          <>
            <div className='mb-5'>{t('account.subscription-payments-title')}</div>
            <div className='flex justify-between items-center'>
              <div className='flex items-center'>
                <div className='mr-5'>
                  {format(addMonths(parseISO(user.subscriptionTo), monthDelta), 'dd.MM.yyyy')} -{' '}
                  {format(parseISO(user.subscriptionTo), 'dd.MM.yyyy')}
                </div>
                <div>
                  <span className='h-11 py-2 px-1 font-bold italic bg-aqua rounded-md text-black'>
                    {user?.subscriptionMethod}
                  </span>
                </div>
                {!isSubscriptionValid ? (
                  <div className='ml-3 text-aqua'>{t('account.subscription-expired')}</div>
                ) : null}
              </div>
              <div>
                <button onClick={() => setOpen(true)}>
                  <img src={dottedIcon} alt='Settings' />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className=''>{t('account.subscription-empty')}</div>
        )}
      </div>
    </div>
  );
};
