import { BasicContent } from '../../components/BasicContent/BasicContent';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSplitName } from '../../hooks/useSplitName';
import { getInstructorImgUrl } from '../../utils/images';
import { classes } from '../../services/classes';
import { IClass } from '../../models/Video';
import VideosStack from '../../videos/ViedeosStack/VideosStack';
import { useRelationProps } from '../../hooks/useRelationProps';

export default function Instructor() {
  const { id } = useParams();
  const { allInstructors } = useAppSelector((state) => state.instructors);
  const [recentClasses, setRecentClasses] = useState<IClass[]>([]);

  const instructor = useMemo(
    () => allInstructors?.find((item) => item.PK === id),
    [allInstructors, id],
  );

  const getRecentClasses = useCallback(async () => {
    const recentClasses = await classes.getAllClasses({
      'filter:instructor:eq': instructor!.PK,
    });
    setRecentClasses(recentClasses.data);
  }, [instructor]);

  useEffect(() => {
    if (instructor) {
      setRecentClasses([]);
      getRecentClasses();
    }
  }, [instructor]);

  const attributes = useRelationProps(['category', 'style'], instructor);

  const { t } = useTranslation();
  const { firstName, secondName } = useSplitName(instructor?.name || '');

  return (
    <BasicContent loading={false}>
      {instructor ? (
        <>
          <div>
            <div className='h-[264px] bg-black flex items-center justify-center'>
              <div className='instructor-name relative'>
                <p
                  style={{ color: '#747474' }}
                  className='uppercase text-2xl bg-black absolute -top-4 left-1/2 transform -translate-x-1/2 px-2'
                >
                  {t('instructor.title')}
                </p>
                <p className='uppercase text-xl md:text-[64px]'>
                  {firstName} <span className='text-orange'>{secondName}</span>
                </p>
              </div>
            </div>
            <div className='w-full details pt-10 text-right flex justify-end'>
              <div className='info flex flex-col mr-[70px] text-2xl'>
                {attributes.category && (
                  <div className='mb-12'>
                    <p className='text-aqua uppercase'>{t('instructor.attr-category')}</p>
                    <p className='mt-4'>
                      {attributes.category.map((item) => item.title).join(', ')}
                    </p>
                  </div>
                )}
                {attributes.style && (
                  <div className='mb-12'>
                    <p className='text-aqua uppercase'>{t('instructor.attr-style')}</p>
                    <p className='mt-4'>{attributes.style.map((item) => item.title).join(', ')}</p>
                  </div>
                )}
                {instructor.about && (
                  <div className=''>
                    <p className='text-aqua uppercase'>{t('instructor.attr-about')}</p>
                    <p className='mt-4'>{instructor.about}</p>
                  </div>
                )}
              </div>
              <div>
                <div
                  className='min-w-[25vw] aspect-w-[412] aspect-h-[624] bg-cover bg-no-repeat bg-center'
                  style={{
                    backgroundImage: `url(${getInstructorImgUrl(instructor)})`,
                  }}
                />
              </div>
            </div>
          </div>
          {recentClasses.length ? (
            <div className='mt-[100px] px-4'>
              <h3 className='text-lg font-bold'>{t('instructor.recent-classes')}</h3>
              <div className='pt-10 px-6'>
                <VideosStack videos={recentClasses} />{' '}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        t('instructor.not-found')
      )}
    </BasicContent>
  );
}
