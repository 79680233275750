import { FC, useState } from 'react';
import TextRoboto from '../text/text-roboto';
import SecondaryButton from '../buttons/secondary-button';
import TextPoppins from '../text/text-poppins';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TrainingListPopup from '../popups/TrainingPopup/TrainingListPopup';

export const Playlists: FC = () => {
  const [createTrainingPopup, setCreateTrainingPopup] = useState(false);
  const playlists = useSelector((state: AppState) => state.playlist.playlists);
  const { t } = useTranslation();

  return (
    <div>
      <div className='flex justify-between  px-10'>
        <div className='flex items-center justify-between w-full'>
          <TextRoboto className='font-bold  text-sm md:text-md  tracking-2.8px uppercase'>
            {t('sections.my-training')} <span className='text-take-training'>{`>`}</span>
          </TextRoboto>
          <div className='relative'>
            <SecondaryButton
              className='px-6 py-1 text-black text-xs cursor-pointer uppercase'
              onClick={() => setCreateTrainingPopup(true)}
            >
              {t('trainings.create-training-button')} +
            </SecondaryButton>
          </div>
          {createTrainingPopup && (
            <div
              onMouseLeave={() => setCreateTrainingPopup(false)}
              className='absolute -right-1 -top-1 z-[1000]'
            >
              <TrainingListPopup open={createTrainingPopup} />
            </div>
          )}
        </div>
      </div>
      <div className='flex md:flex-wrap  pl-10 pt-5 overflow-auto scrollbar-hide max-w-full gap-10'>
        {playlists.length ? (
          <>
            {playlists.map((item, index) => (
              <Link
                to={`/mein-trainings/${item.PK}`}
                key={item.PK}
                className={`w-[260px] py-16 text-center  flex-shrink-0 flex-grow-0 bg-catergory-box overflow-hidden text-ellipsis rounded-xl`}
              >
                <TextPoppins className='text-[28px] font-bold whitespace-nowrap overflow-hidden text-ellipsis w-full max-w-full px-2'>
                  {item.title}
                </TextPoppins>
              </Link>
            ))}
            <div className='w-10 h-full flex-shrink-0'></div>
          </>
        ) : (
          <p>{t('trainings.empty-training-list')}</p>
        )}
      </div>
    </div>
  );
};
