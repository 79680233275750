import { RefObject } from "react";

type Props = {
  onClick?: (e: MouseEvent) => void;
  src: string;
  alt?: string;
  className?: string;
  text?: string;
  parameter?: string;
  active?: boolean;
  buttonRef?: RefObject<HTMLDivElement>;
};

const PlayerButton = ({
  onClick,
  src,
  alt,
  className,
  text,
  parameter,
  active,
  buttonRef,
}: Props) => {
  return (
    <div className="flex flex-col items-center relative" ref={buttonRef}>
      {parameter && (
        <div
          className={`absolute -left-4 -rotate-[30deg] text-[8px] ${
            active ? "text-aqua" : "text-white"
          }`}
        >
          {parameter}
        </div>
      )}
      {
        //@ts-ignore
        <button onClick={onClick}>
          <img className={`${className} cursor-pointer`} src={src} alt={alt} />
        </button>
      }
      <span className="uppercase text-[10px] text-white">{text}</span>
    </div>
  );
};
export default PlayerButton;
