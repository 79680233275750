import { useRef, useEffect, useCallback, ChangeEvent, useState, useMemo } from 'react';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import titleImage from '../../assets/img/titleImage.svg';
import PlayerControls from './controls';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import {
  resetToDefault,
  setCurrentClass,
  setIsBackViewMode,
  setIsLiked,
  setIsLoading,
  setIsLoop,
  setIsMirror,
  setIsMute,
  setIsPlay,
  setProgress,
  setProgressSliderValues,
  setRangesValue,
  setScreenClicked,
  setShowControls,
  setShowSettings,
  setShowSpeedPopup,
  setShowViewSection,
  setSpeed,
} from '../../redux/player/player-slice';
import { fetchClass } from '../../redux/player/player-actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/loaders/loader';
import { useSetClassToHistory } from '../../hooks/useSetClassToHistory';
import { useTranslation } from 'react-i18next';
import { getInstructorImgUrl } from '../../utils/images';
import { IInstuctor } from '../../models/Instructor';
import { useRelationProps } from '../../hooks/useRelationProps';
import { getValues } from '../../components/Filter/filter';
import classNames from 'classnames';
import PreviewVideo from '../../videos/previewVideo';
import { setIsLoginModalOpen } from '../../redux/auth/auth-slice';

const format = (seconds: number) => {
  if (seconds === 0) {
    return `00:00`;
  } else {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${mm}:${ss}`;
  }
};

const Player = () => {
  const videoRef = useRef<ReactPlayer | undefined>(null);
  const playerContainer = useRef<HTMLDivElement>(null);
  const videoContainer = useRef<HTMLDivElement>(null);
  const videoWrapper = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const speedButtonRef = useRef<HTMLDivElement>(null);
  const speedRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const viewSectionRef = useRef<HTMLDivElement>(null);
  const viewSectionButtonRef = useRef<HTMLButtonElement>(null);
  const settingsButtonRef = useRef<HTMLDivElement>(null);
  const topControlsRef = useRef<HTMLDivElement>(null);
  const bottomControlsRef = useRef<HTMLDivElement>(null);
  const { user, isSubscriptionValid } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const {
    isLoading,
    showSettings,
    showControls,
    isBackViewMode,
    isLoop,
    isMirror,
    isMute,
    isPlay,
    progress,
    progressSliderValues,
    rangesValue,
    screenClicked,
    showSpeedPopup,
    showViewSection,
    speed,
    currentClass,
    link,
  } = useAppSelector((state) => state.player);
  const { t } = useTranslation();
  const { levels } = getValues(t);
  const location = useLocation();
  const state: { toPreview: boolean } = (location.state as { toPreview: boolean }) || {
    toPreview: false,
  };
  const playHandle = () => {
    if (isPlay) dispatch(setIsPlay(false));
    else dispatch(setIsPlay(true));
  };

  const navigate = useNavigate();

  const { endValue, progressValue, startValue } = progressSliderValues;
  const currentTime = videoRef && videoRef.current ? videoRef.current.getCurrentTime() : 0;
  const duration = videoRef && videoRef.current ? videoRef.current.getDuration() : 0;
  const elapsedTime = format(Number(currentTime));
  const totalDuration = format(Number(duration));
  const [videoResolution, setVideoResolution] = useState('auto');
  const playPauseVideo = (e: MouseEvent) => {
    if (
      !topControlsRef.current?.contains(e.target as Node | null) &&
      !bottomControlsRef.current?.contains(e.target as Node | null)
    ) {
      dispatch(setScreenClicked(true));
    } else dispatch(setScreenClicked(false));
    dispatch(setIsPlay(!isPlay));
  };

  useSetClassToHistory(currentClass?.PK || '', currentTime, isPlay);

  const handleSectionChange = (value: number) => {
    //@ts-ignore
    videoRef.current?.seekTo(value / 100);
    dispatch(setProgress(value / 100));
  };

  const handleVideoProgress = (e: ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    videoRef.current?.seekTo(e.target.value / 100);
    const value = Number(e.target.value);
    dispatch(setProgress(value / 100));
  };

  const handleProgress = ({ played }: { played: number }) => {
    dispatch(setProgress(played));
  };

  const backVideo = () => {
    videoRef.current?.seekTo(videoRef.current.getCurrentTime() - 15);
  };
  const nextVideo = () => {
    videoRef.current?.seekTo(videoRef.current.getCurrentTime() + 15);
  };
  const muteVideo = () => {
    dispatch(setIsMute(!isMute));
  };
  const displayFullScreen = () => {
    if (screenfull.isEnabled) {
      //@ts-ignore
      screenfull.toggle(videoWrapper.current);
      handleReady();
    }
  };
  const speedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    dispatch(setSpeed(value));
  };
  const resetSpeed = () => {
    dispatch(setSpeed(1));
  };

  const loopVideo = () => {
    dispatch(setIsLoop(!isLoop));
    dispatch(
      setRangesValue({
        startValue: progress * 100,
        endValue: progress * 100 + 10,
      }),
    );
  };

  const onVideoResolutionChange = (value: string) => {
    setVideoResolution(value);
    //@ts-ignore
    if (videoRef.current) videoRef.current.resolution = value;
  };

  const mirrorVideo = () => {
    dispatch(setIsMirror(!isMirror));
  };

  const handleReady = () => {
    if (videoContainer.current && playerContainer.current) {
      const ratio = videoContainer.current.clientWidth / (videoContainer.current.clientHeight / 2);
      playerContainer.current.style.maxWidth = window.innerHeight * ratio + 'px';
      playerContainer.current.style.maxHeight = window.innerWidth / ratio + 'px';
    }
    if (videoContainer.current && container.current) {
      let top = videoContainer.current.style.top ? videoContainer.current.style.top : '0px';
      if (top !== '0px') {
        videoContainer.current.style.top = '-' + videoContainer.current.clientHeight / 2 + 'px';
      }
      if (window.innerWidth < 767) {
        container.current.style.height = videoContainer.current.clientHeight / 2 + 'px';
      } else {
        container.current.style.height = 'calc(100vh - 63px)';
      }
    }
  };

  const handleChangeView = () => {
    dispatch(setIsBackViewMode(!isBackViewMode));
  };

  let timeout: any;

  const onTouchStartScreenHandle = () => {
    dispatch(setShowControls(true));
  };
  const onTouchEndScreenHandle = () => {
    handleShowControls();
  };

  const handleShowControls = useCallback(() => {
    dispatch(setShowControls(true));
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setShowControls(false));
      dispatch(setShowSpeedPopup(false));
      dispatch(setShowSettings(false));
      dispatch(setShowViewSection(false));
    }, 3000);
  }, []);

  const handleSliderChange = (value: number[]) => {
    dispatch(
      setProgressSliderValues({
        startValue: value[0],
        progressValue: value[1],
        endValue: value[2],
      }),
    );
  };

  const hidePopups = (e: MouseEvent) => {
    if (
      !viewSectionButtonRef.current?.contains(e.target as Node | null) &&
      !viewSectionRef.current?.contains(e.target as Node | null) &&
      !speedRef.current?.contains(e.target as Node | null) &&
      !speedButtonRef.current?.contains(e.target as Node | null) &&
      !settingsRef.current?.contains(e.target as Node | null) &&
      !settingsButtonRef.current?.contains(e.target as Node | null)
    ) {
      dispatch(setShowSettings(false));
      dispatch(setShowViewSection(false));
      dispatch(setShowSpeedPopup(false));
    }
  };

  const screenClickHandle = (e: MouseEvent) => {
    if (
      !topControlsRef.current?.contains(e.target as Node | null) &&
      !bottomControlsRef.current?.contains(e.target as Node | null)
    ) {
      if (!showSpeedPopup && !showViewSection && !showSettings) {
        playHandle();
        dispatch(setScreenClicked(true));
      } else {
        hidePopups(e);
      }
    } else {
      hidePopups(e);
    }
  };

  useEffect(() => {
    topControlsRef.current?.addEventListener('click', hidePopups);
    bottomControlsRef.current?.addEventListener('click', hidePopups);
  }, [topControlsRef.current, bottomControlsRef.current]);

  useEffect(() => {
    if (currentClass) {
      if (state.toPreview) {
        setTimeout(() => {
          document.getElementById('preview')?.scrollIntoView();
        }, 200);
      }
    }
  }, [currentClass]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', handleReady);
    return () => {
      window.removeEventListener('resize', handleReady);
      topControlsRef.current?.removeEventListener('click', hidePopups);
      bottomControlsRef.current?.removeEventListener('click', hidePopups);
      dispatch(setCurrentClass(null));
    };
  }, []);

  useEffect(() => {
    if (videoContainer.current) {
      if (isBackViewMode) {
        videoContainer.current.style.top = '-' + videoContainer.current.clientHeight / 2 + 'px';
      } else {
        videoContainer.current.style.top = '0';
      }
    }
  }, [isBackViewMode]);

  useEffect(() => {
    container.current?.addEventListener('mousemove', handleShowControls);
  }, [container.current]);

  useEffect(() => {
    if (rangesValue.endValue && isLoop && progress && progress * 100 > rangesValue.endValue) {
      if (rangesValue.startValue) {
        videoRef.current?.seekTo(rangesValue.startValue / 100);
        dispatch(setProgress(rangesValue.startValue / 100));
      }
    }
  }, [isLoop, rangesValue, progress]);

  useEffect(() => {
    if (progressValue) {
      videoRef.current?.seekTo(progressValue / 100);
      dispatch(setProgress(progressValue / 100));
    }
  }, [progressValue]);

  useEffect(() => {
    dispatch(
      setRangesValue({
        startValue: startValue,
        endValue: endValue,
      }),
    );
    if (startValue) {
      videoRef.current?.seekTo(startValue / 100);
      dispatch(setProgress(startValue / 100));
    }
  }, [startValue, endValue]);

  useEffect(() => {
    if (!isLoading) handleReady();
  }, [isLoading]);

  useEffect(() => {
    dispatch(resetToDefault());
  }, []);

  const params = useParams();
  const { classId } = params;

  const properties = useRelationProps(['instructor', 'choreographer'], currentClass);

  useEffect(() => {
    if (classId) dispatch(fetchClass(classId));
  }, [classId]);

  useEffect(() => {
    if (!user) {
      dispatch(setIsLoginModalOpen(true));
      navigate('/');
    }
  }, [user]);

  return currentClass && !isLoading ? (
    <>
      <div className={`min-h-screen ${isLoading && 'hidden'}`}>
        <div
          onTouchStart={onTouchStartScreenHandle}
          onTouchEnd={onTouchEndScreenHandle}
          onTouchMove={handleShowControls}
          ref={container}
        >
          <div
            ref={videoWrapper}
            className='overflow-hidden flex justify-center items-center w-full h-full bg-black '
          >
            <div
              id='fullscreen'
              className='relative overflow-hidden w-full h-full'
              ref={playerContainer}
            >
              <div
                //@ts-ignore
                onClick={screenClickHandle}
                ref={videoContainer}
                className={cx('absolute', { mirror: isMirror })}
              >
                <ReactPlayer
                  playsinline
                  preload='auto'
                  //@ts-ignore
                  ref={videoRef}
                  height='100%'
                  width='100%'
                  url={link}
                  playing={isPlay}
                  playbackRate={speed}
                  onProgress={handleProgress}
                  loop={isLoop}
                  muted={isMute}
                  onReady={handleReady}
                />
              </div>
              <PlayerControls
                handleShowControls={handleShowControls}
                duration={duration}
                format={format}
                screenClicked={screenClicked}
                bottomControlsRef={bottomControlsRef}
                topControlsRef={topControlsRef}
                viewSectionButtonRef={viewSectionButtonRef}
                speedButtonRef={speedButtonRef}
                settingsButtonRef={settingsButtonRef}
                viewSectionRef={viewSectionRef}
                settingsRef={settingsRef}
                speedRef={speedRef}
                showViewSection={showViewSection}
                setShowViewSection={setShowViewSection}
                showSettings={showSettings}
                setShowSettings={setShowSettings}
                showSpeedPopup={showSpeedPopup}
                setShowSpeedPopup={setShowSpeedPopup}
                progress={progress}
                handleVideoProgress={handleVideoProgress}
                playPauseVideo={playPauseVideo}
                backVideo={backVideo}
                nextVideo={nextVideo}
                loopVideo={loopVideo}
                isLoop={isLoop}
                resetSpeed={resetSpeed}
                speed={speed}
                speedHandler={speedHandler}
                setVideoResolution={onVideoResolutionChange}
                videoResolution={videoResolution}
                isMute={isMute}
                muteVideo={muteVideo}
                displayFullScreen={displayFullScreen}
                isPlay={isPlay}
                mirrorVideo={mirrorVideo}
                isMirror={isMirror}
                handleChangeView={handleChangeView}
                isBackViewMode={isBackViewMode}
                elapsedTime={elapsedTime}
                totalDuration={totalDuration}
                handleSectionChange={handleSectionChange}
                showControls={showControls}
                handleSliderChange={handleSliderChange}
                rangesValue={rangesValue}
              />
            </div>
          </div>
        </div>

        <section id='preview' className={`py-[5%] px-[10%] text-white`}>
          <h1 className='font-bold text-[32px] md:text-[48px] uppercase text-center md:text-left'>
            {currentClass.title}
          </h1>
          <div className='mt-28  w-full'>
            {properties.instructor && properties.instructor.length && (
              <div>
                <img
                  className='w-[250px]'
                  src={getInstructorImgUrl(properties.instructor[0])}
                  alt='Title Logo'
                />
              </div>
            )}
            <div className='md:flex w-full'>
              <div className='w-full md:w-5/12 pr-[8%]'>
                <div className='mb-[9vw] uppercase text-center md:text-right'>
                  {properties.instructor && properties.instructor.length && (
                    <p className='text-[16px] my-10'>
                      {t('player.property-teacher')}{' '}
                      <span className='text-[20px] ml-2'>{properties.instructor[0].name}</span>
                    </p>
                  )}
                  {properties.choreographer && properties.choreographer.length && (
                    <p className='text-[16px] my-10'>
                      {t('player.property-choreography')}{' '}
                      <span className='text-[20px] ml-2'>{properties.choreographer[0].name}</span>
                    </p>
                  )}
                </div>
                <div className='md:float-right'>
                  <div className='text-center uppercase'>
                    <p className='text-[16px] my-10'>
                      {t('player.property-level')}
                      <span className='text-[20px] text-aqua ml-2'>
                        {' '}
                        {levels.get(currentClass.level)?.label}
                      </span>
                    </p>
                    {properties.style && (
                      <p className='text-[16px] my-5'>
                        {t('player.property-style')}
                        <span className='text-[20px] text-aqua ml-2'>
                          {' '}
                          {properties.style.map((item) => item.name).join(', ')}
                        </span>
                      </p>
                    )}
                    <p className='text-[16px] my-10'>
                      {t('player.property-time')}{' '}
                      <span className='text-[20px] text-aqua ml-2'>{currentClass.duration}</span>
                    </p>
                    <p className='text-[16px] my-10'>
                      {t('player.property-type')}{' '}
                      <span className='text-[20px] text-aqua ml-2'> warmUp</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-7/12'>
                <PreviewVideo video={currentClass} />
              </div>
            </div>
            <div className='flex w-[80%] self-start'>{/* <PreviewVideo /> */}</div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <div className='w-full h-screen flex justify-center items-center'>
      <Loader />
    </div>
  );
};

export default Player;
