import { INSTANCE } from './instance';
import axios, { Canceler } from 'axios';

export type SearchResult = {
  PK: string;
  SK: string;
  title: string;
  type: string;
};

let searchCancelToken: Canceler;

export const search = {
  search: (searchQuery: string, withCancel?: boolean) => {
    if (withCancel && searchCancelToken) {
      searchCancelToken();
    }
    return INSTANCE.get(`search`, {
      params: {
        q: searchQuery,
      },
      cancelToken: new axios.CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        searchCancelToken = c;
      }),
    });
  },
};
