import { AppDispatch } from '../store';
import { Plan, setIsLoading, setPlans } from './plans-slice';
import { plans } from '../../services/plans';

export const fetchPlans = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await plans.getPlans();
    dispatch(setPlans(response.data as Plan[]));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const declinePlan = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    await plans.declineSubscription();
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};
