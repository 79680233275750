import { INSTANCE } from './instance';

export const payment = {
  createStripeCustomer: () => {
    return 'stripe/customer';
  },
  createStripeSubscription: (paymentMethod: any, stripeId: string, planId: string) => {
    return INSTANCE.post('stripe/subscription', {
      paymentMethod: paymentMethod,
      stripeId: stripeId,
      planId: planId,
      successUrl: `${window.location.href}?subscription=success`,
      cancelUrl: `${window.location.href}?subscription=fail`,
    });
  },
  paypalSubscription: (paypalId: string, planId: string) => {
    return INSTANCE.post('paypal/subscription', {
      planId: planId,
      paypalId,
      successUrl: `${window.location.href}?subscription=success`,
      cancelUrl: `${window.location.href}?subscription=fail`,
    });
  },
};
