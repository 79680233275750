import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export const auth = {
  signIn: async (email: string, password: string) => {
    let user;
    let errorMessage;
    try {
      const userResponse = await Auth.signIn({
        username: email,
        password: password,
      });
      console.log(userResponse);

      user = userResponse;
      errorMessage = null;
      return { user, errorMessage };
    } catch (error) {
      console.log(error);

      errorMessage = 'Incorrect username or password';
      user = null;
      return { user, errorMessage };
    }
  },
  facebookSignIn: async () => {
    try {
      const response = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });

      console.log('response', response);
    } catch (error) {
      console.log(error);
    }
  },
  googleSignIn: async () => {
    try {
      const response = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  },
  signOut: async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log(error);
    }
  },
  getAuthenticatedUser: async () => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  signUp: async (values: { password: string; email: string; name: string }) => {
    const { password, email, name } = values;
    const { user } = await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        name: name,
      },
      autoSignIn: {
        enabled: true,
      },
    });
    console.log(user);
    return user;
  },
  confirmEmail: async (values: { email: string; confirmCode: string }) => {
    const { confirmCode, email } = values;
    try {
      const response = await Auth.confirmSignUp(email, confirmCode);
      console.log(response);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  forgotPassword: async (email: string) => {
    try {
      return Auth.forgotPassword(email);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  forgotPasswordSubmit: async (email: string, code: string, password: string) => {
    try {
      return Auth.forgotPasswordSubmit(email, code, password);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};
