import axios from 'axios';

export const INSTANCE = axios.create({
  baseURL: 'https://2nfiecvsv9.execute-api.eu-central-1.amazonaws.com/staging/api/',
});

export const baseUrl =
  'https://2nfiecvsv9.execute-api.eu-central-1.amazonaws.com/staging/api/media';
export const videoPreviewUrl =
  'https://vod-preview-staging-eu-central-1.s3.eu-central-1.amazonaws.com/';
export const assetsUrl = ' https://vod-assets-staging-eu-central-1.s3.eu-central-1.amazonaws.com/';
