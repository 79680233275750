import { FC } from 'react'
import { Link, useMatch } from 'react-router-dom'
import classNames from 'classnames'

type MenuSectionTitleProps = {
  label: string
}
const MenuSectionTitle: FC<MenuSectionTitleProps> = ({ label }) => {
  return (
    <>
      <hr className='menu-divider self-end mt-3 mb-3' />
      <div className='h-[51px] menu-item  flex flex-1 justify-center md:justify-start md:flex-auto items-center'>
        <div className='flex w-fit lg:ml-16 md:ml-12 ml-6 mr-6 gap-x-7 font-bold font-roboto tracking-1.2px'>
          <div>{label}</div>
        </div>
      </div>
    </>
  )
}
export default MenuSectionTitle
