import { FC, ReactNode } from 'react'
import TextRoboto from '../text/text-roboto'
import ScrollToTop from '../scroll-to-top'
import { Loader } from '../loaders/loader'

type BasicContentProps = {
  children?: ReactNode
  title?: string
  loading?: boolean
}

export const BasicContent: FC<BasicContentProps> = ({ loading, children, title }) => {
  return (
    <ScrollToTop>
      <div className={`pb-8 pl-[4%] pr-[4%] text-white`}>
        {title && (
          <div className='text-right my-12'>
            <TextRoboto className='uppercase text-[30px] tracking-12.5%'>{title}</TextRoboto>
          </div>
        )}
        {children}
      </div>
      <Loader withOverlay={true} show={loading} />
    </ScrollToTop>
  )
}
