import { FC, useCallback, useMemo, useState } from 'react';
import { CategoryIcon } from '../../../assets/icons/CategoryIcon';
import { ClassesIcon } from '../../../assets/icons/ClassesIcon';
import { DashboardIcon } from '../../../assets/icons/DashboardIcon';
import { HistoryIcon } from '../../../assets/icons/HistoryIcon';
import { InstructorsIcon } from '../../../assets/icons/InstructorsIcon';
import { MeinTrainingsIcon } from '../../../assets/icons/MeinTrainingsIcon';
import MainMenuItem from './MainMenuItem';
import MenuSectionTitle from './MenuSectionTitle';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/reduxHooks/useAppSelector';

type MenuItem = {
  label: string;
  icon?: FC<{ active: boolean }>;
  path?: string;
  type: 'section-title' | 'main-menu' | 'second-menu';
  hidden?: boolean;
};

type Props = {
  open: boolean;
};
const MainMenu = ({ open }: Props) => {
  const [slideTop, setSlideTop] = useState(0);
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);

  const menuItems = useMemo<Array<MenuItem>>(() => {
    const items: Array<MenuItem> = [
      {
        label: t('sections.home'),
        icon: DashboardIcon,
        path: '/',
        type: 'main-menu',
      },
      {
        label: t('sections.category'),
        icon: CategoryIcon,
        path: '/category',
        type: 'main-menu',
      },
      {
        label: t('sections.classes'),
        icon: ClassesIcon,
        path: '/classes',
        type: 'main-menu',
      },
      {
        label: t('sections.my-training'),
        icon: MeinTrainingsIcon,
        path: '/mein-trainings',
        type: 'main-menu',
      },
      {
        label: t('sections.instructors'),
        icon: InstructorsIcon,
        path: '/instructors',
        type: 'main-menu',
      },
      {
        label: t('sections.history'),
        icon: HistoryIcon,
        path: '/history',
        type: 'main-menu',
        hidden: !user,
      },
      {
        type: 'section-title',
        label: t('sections.followed-categories'),
      },
      {
        type: 'second-menu',
        label: t('sections.my-classes'),
        path: '/my-classes',
      },
      {
        type: 'section-title',
        label: t('sections.explore-more'),
      },
      {
        type: 'second-menu',
        label: t('sections.community'),
        path: '/community',
      },
      {
        type: 'second-menu',
        label: t('sections.contact'),
        path: '/contact',
      },
    ];
    return items.filter((item) => !item.hidden);
  }, [user]);

  const onActiveItem = useCallback((top: number) => {
    setSlideTop(top);
  }, []);

  return (
    <div id='menu' className='w-full flex md:flex-col md:mb-[5%]'>
      {menuItems.map((item) => {
        switch (item.type) {
          case 'main-menu':
            return (
              <MainMenuItem
                open={open}
                key={item.label}
                path={item.path!}
                Icon={item.icon}
                label={item.label}
                onActiveItem={onActiveItem}
              />
            );
          case 'second-menu':
            return (
              open && (
                <MainMenuItem
                  onActiveItem={onActiveItem}
                  key={item.label}
                  path={item.path!}
                  label={item.label}
                  open={open}
                />
              )
            );
          case 'section-title':
            return open && <MenuSectionTitle key={item.label} label={item.label} />;
        }
      })}
      <div className='slide hidden md:block' style={{ top: slideTop }}></div>
    </div>
  );
};
export default MainMenu;
