import UnderlineButton from '../../buttons/underline-button';
import SecondaryButton from '../../buttons/secondary-button';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import { Loader } from '../../loaders/loader';
import { useAppDispatch } from '../../../redux/reduxHooks/useAppDispatch';
import {
  addClassToPlaylist,
  removeClassFromPlaylist,
} from '../../../redux/playlist/playlist-actions';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/reduxHooks/useAppSelector';
import { setIsLoginModalOpen } from '../../../redux/auth/auth-slice';
import TrainingPopupContent from './TrainingPopupContent';
import { Playlist } from '../../../redux/playlist/playlist-slice';

type CreateTrainingPopupProps = {
  open: boolean;
  classId: string;
};

export default function ClassTrainingPopup({ open, classId }: CreateTrainingPopupProps) {
  const { user } = useAppSelector((state) => state.auth);
  const playlists = useSelector((state: AppState) => state.playlist.playlists);
  const dispatch = useAppDispatch();

  const onAdd = (playlistId: string) => {
    dispatch(addClassToPlaylist(playlistId, classId));
  };

  const onRemove = (playlistId: string) => {
    dispatch(removeClassFromPlaylist(playlistId, classId));
  };

  const { t } = useTranslation();
  const playlistIncludeClassMap = useMemo(
    () =>
      playlists.reduce((result, item) => {
        result[item.PK] = Boolean(item.relations?.find((rel) => rel?.PK === classId));
        return result;
      }, {} as { [playlistId: string]: boolean }),
    [classId, playlists],
  );

  useEffect(() => {
    if (open && !user) {
      dispatch(setIsLoginModalOpen(true));
    }
  }, [open]);

  const playlistButtonRenderer = useCallback(
    (playlist: Playlist) => {
      return (
        <>
          {playlistIncludeClassMap[playlist.PK] ? (
            <UnderlineButton className='uppercase' onClick={() => onRemove(playlist.PK)}>
              {t('trainings.training-popup-remove-class-button')}
            </UnderlineButton>
          ) : (
            <UnderlineButton className='uppercase' onClick={() => onAdd(playlist.PK)}>
              {t('trainings.training-popup-add-button')}
            </UnderlineButton>
          )}
        </>
      );
    },
    [playlistIncludeClassMap],
  );

  return <TrainingPopupContent open={open} playlistButtonRenderer={playlistButtonRenderer} />;
}
