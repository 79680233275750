import { INSTANCE } from './instance';

export const userAction = {
  getLikedClass: () => {
    return INSTANCE.get('useraction/like/class');
  },
  addLikedClass: (id: string) => {
    return INSTANCE.post('useraction/like/class', {
      id,
    });
  },
  removeLikedClass: (id: string) => {
    return INSTANCE.delete('useraction/like/class', {
      data: { id },
    });
  },
  setClassToHistory: (id: string) => {
    return INSTANCE.post('useraction/history/class', {
      id,
    });
  },
  getListOfClassHistory: () => {
    return INSTANCE.get(`useraction/history/class`, {});
  },
};
