import { categories } from "../../services/categories";
import { AppDispatch } from "../store";
import { setCategories } from "./categories-slice";

export const fetchAllCategories = () => async (dispatch: AppDispatch) => {
    try {
        const response = await categories.getAllCategories()
        dispatch(setCategories(response.data))
        console.log(response);
    } catch (error) {
        console.log(error);
    }
}