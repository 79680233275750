import PlayerButton from './PlayerButton';
import loopIcon from '../../../assets/icons/loopIcon.svg';
import mirrorIcon from '../../../assets/icons/mirrorIcon.svg';
import viewIcon from '../../../assets/icons/viewIcon.svg';
import speedIcon from '../../../assets/icons/speedIcon.svg';
import activeLoopIcon from '../../../assets/icons/activeLoopIcon.svg';
import activeMirrorIcon from '../../../assets/icons/activeMirrorIcon.svg';
import activeViewIcon from '../../../assets/icons/activeViewIcon.svg';
import activeSpeedIcon from '../../../assets/icons/activeSpeedIcon.svg';
import SpeedSlider from './SpeedSlider';
import { ChangeEvent, RefObject } from 'react';

type Props = {
  isLoop: boolean;
  loopVideo: () => void;
  mirrorVideo: () => void;
  onChangeView: () => void;
  resetSpeed: () => void;
  speed: number;
  speedPopup: boolean;
  isMirror: boolean;
  isView: boolean;
  setSpeedPopup: () => void;
  speedHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  speedRef: RefObject<HTMLDivElement>;
  speedButtonRef: RefObject<HTMLDivElement>;
};
export default function VideoStyles({
  isLoop,
  loopVideo,
  mirrorVideo,
  onChangeView,
  resetSpeed,
  speed,
  speedPopup,
  isMirror,
  isView,
  setSpeedPopup,
  speedHandler,
  speedRef,
  speedButtonRef,
}: Props) {
  const buttonClasses = 'h-[20px] w-[20px]  md:h-[40px] md:w-[40px]';
  return (
    <div className='flex items-center gap-x-12 justify-center w-full '>
      {isLoop ? (
        <PlayerButton
          active={true}
          parameter='on'
          text='loop'
          className={buttonClasses}
          src={activeLoopIcon}
          onClick={loopVideo}
          alt='loop_icon'
        />
      ) : (
        <PlayerButton
          active={false}
          parameter='off'
          text='loop'
          className={buttonClasses}
          src={loopIcon}
          onClick={loopVideo}
          alt='loop_icon'
        />
      )}
      {isMirror ? (
        <PlayerButton
          parameter='on'
          active={true}
          text='mirror'
          className={buttonClasses}
          src={activeMirrorIcon}
          onClick={mirrorVideo}
          alt='mirror_icon'
        />
      ) : (
        <PlayerButton
          active={false}
          parameter='off'
          text='mirror'
          className={buttonClasses}
          src={mirrorIcon}
          onClick={mirrorVideo}
          alt='mirror_icon'
        />
      )}
      {isView ? (
        <PlayerButton
          parameter='back'
          active={true}
          text='view'
          className={buttonClasses}
          src={activeViewIcon}
          onClick={onChangeView}
          alt='view_icon'
        />
      ) : (
        <PlayerButton
          active={false}
          parameter='front'
          text='view'
          className={buttonClasses}
          src={viewIcon}
          onClick={onChangeView}
          alt='view_icon'
        />
      )}
      {speedPopup ? (
        <PlayerButton
          buttonRef={speedButtonRef}
          parameter={'x' + speed}
          active={true}
          text='speed'
          className={buttonClasses}
          src={activeSpeedIcon}
          onClick={setSpeedPopup}
          alt='speed_icon2'
        />
      ) : (
        <PlayerButton
          active={false}
          parameter={'x' + speed}
          text='speed'
          className={buttonClasses}
          src={speedIcon}
          onClick={setSpeedPopup}
          alt='speed_icon'
        />
      )}

      <div
        ref={speedRef}
        className={`absolute rounded-2xl md:bottom-36 lg:bottom-1 right-0 w-[24%] px-3 py-1 transition-all duration-500 bg-black-transparent ${
          speedPopup ? 'lg:-translate-x-[60%]' : 'translate-x-[150%]'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <SpeedSlider resetSpeed={resetSpeed} speed={speed} speedHandler={speedHandler} />
      </div>
    </div>
  );
}
