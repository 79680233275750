import { useMemo, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import DacneLevel from './DanceLevel';
import Styles from './Styles';
import CreateAccount from './CreateAccount';
import Payment from './Payment';
import sidebarBg from '../../assets/img/Sidebar.png';
import 'animate.css';
import TextPoppins from '../../components/text/text-poppins';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState<number>(() => {
    const searchStep = searchParams.get('step');
    return searchStep ? +searchStep : 1;
  });
  const { t } = useTranslation();

  const steps: Array<{ step: number; title: string }> = useMemo(
    () => [
      {
        step: 1,
        title: t('sign-up.step-level'),
      },
      {
        step: 2,
        title: t('sign-up.step-interest'),
      },
      {
        step: 3,
        title: t('sign-up.step-create-account'),
      },
      {
        step: 4,
        title: t('sign-up.step-payment'),
      },
    ],
    [],
  );

  //@ts-ignore
  const handleInstance = (wizard: StepWizardProps) => {
    console.log('handleInstance', step);
    if (step) {
      setStep(step);
    }
  };
  let custom = {
    enterRight: 'elementToFadeIn',
    enterLeft: 'elementToFadeIn',
    exitRight: 'elementToFadeOut',
    exitLeft: 'elementToFadeOut',
  };

  const match = useMediaQuery(breakpoints.lg);
  const styleStep = step - 1;

  return (
    <div className='lg:flex bg-primaryGray overflow-hidden'>
      <div
        className={`w-full lg:w-[40%] flex justify-center bg-cover bg-center bg-no-repeat `}
        style={
          match
            ? {
                backgroundImage: `url('${sidebarBg}')`,
              }
            : undefined
        }
      >
        <ul className='w-full lg:w-3/6 ma-auto lg:h-full z-0 flex lg:flex-col items-start justify-center text-white flex-shrink-0'>
          <li className='w-full step-item relative hidden lg:block'>
            <div
              className='w-full lg:flex bg-primaryGray border p-[10px] border-teal-300 radius-93 absolute left-0 top-0 transition-transform'
              style={{
                transform: `translateY(calc(${styleStep * 60}px + ${styleStep * 2.5}rem))`,
              }}
            >
              <div
                className={classNames(
                  'flex bg-primaryGray lg:bg-transparent opacity-0 items-center justify-center rounded-full mx-auto lg:mx-0 lg:mr-10 border border-white w-[40px] h-[40px] ',
                  {
                    'lg:text-3xl bg-teal-300 text-primaryGray lg:text-white': true,
                  },
                )}
              >
                1
              </div>
              <TextPoppins className='lg:text-3xl text-transparent lg:text-lg font-bold lg:inline-block'>
                {steps.find((item) => item.step === step)!.title}
              </TextPoppins>
            </div>
          </li>
          {steps.map((item) => (
            <li
              key={item.step}
              className={classNames('mb-5 lg:mb-10 w-[100%] step-item relative p-[10px]', {
                'border border-transparent': step === item.step && match,
              })}
            >
              <div className='lg:flex items-center w-full relative'>
                <div
                  className={classNames(
                    'flex bg-primaryGray lg:bg-transparent items-center justify-center rounded-full mx-auto lg:mx-0 lg:mr-10 border border-white w-[40px] h-[40px] ',
                    {
                      'lg:text-3xl bg-teal-300 text-primaryGray lg:text-white': step === item.step,
                    },
                  )}
                >
                  {item.step}
                </div>
                <TextPoppins
                  className={classNames(
                    ' block mt-2 lg:mt-0 lg:text-md text-center lg:inline-block ',
                    {
                      'text-white  lg:text-lg font-bold': step === item.step,
                      'text-teal-300': step !== item.step,
                    },
                  )}
                >
                  {item.title}
                </TextPoppins>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className='min-w-[60%] lg:w-[60%] px-5 lg:px-10'>
        {
          <StepWizard
            initialStep={step}
            onStepChange={({ activeStep }) => setStep(activeStep)}
            transitions={custom}
          >
            <DacneLevel onSetStep={setStep} />
            <Styles onSetStep={setStep} />
            <CreateAccount onSetStep={setStep} />
            <Payment onSetStep={setStep} />
          </StepWizard>
        }
      </div>
    </div>
  );
};

export default SignUp;
