import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClass } from '../../models/Video';

interface IInitialState {
  isLoading: boolean;
  classHistory: Array<IClass>;
}

const initialState: IInitialState = {
  isLoading: false,
  classHistory: [],
};

const playlistSlice = createSlice({
  initialState,
  name: 'history',
  reducers: {
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setClassHistory(state: IInitialState, action: PayloadAction<Array<IClass>>) {
      state.classHistory = action.payload;
    },
  },
});

export const { setIsLoading, setClassHistory } = playlistSlice.actions;

export const historyReducer = playlistSlice.reducer;
