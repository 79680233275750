import { Modal } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setIsLoginModalOpen } from '../../redux/auth/auth-slice';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { auth } from '../../services/auth';
import SecondaryButton from '../buttons/secondary-button';
import TextRoboto from '../text/text-roboto';
import { useTranslation } from 'react-i18next';

export default function ConfirmEmailModal({ email }: { email: string }) {
  const [codeValue, setCodeValue] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleCodeValue = (e: any) => {
    const value = e.target.value;
    setCodeValue(value);
  };

  const sendCode = async () => {
    const values = {
      email: email,
      confirmCode: codeValue,
    };
    const success = await auth.confirmEmail(values);
    if (!success) {
      setError('Failed to confirm the email');
      setCodeValue('');
    } else {
      navigate('/');
      dispatch(setIsLoginModalOpen(true));
    }
  };

  return (
    <Modal
      open={true}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <div className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] max-w-2xl w-4/5 md:w-3/5  lg:w-1/5 border rounded-xl p-5 text-center'>
        <div className='mb-10'>
          <TextRoboto className='text-white text-s tracking-1.2px'>
            {t('confirm-email-modal.title')}
          </TextRoboto>
        </div>
        <input
          value={codeValue}
          onChange={handleCodeValue}
          placeholder={t('confirm-email-modal.field')}
          type='text'
          className='w-full p-2 outline-none rounded-xl text-center text-s'
        />
        {error && <div className='p-5 text-aqua'>{error}</div>}
        <SecondaryButton
          className={`px-5 py-1 mt-2 ${codeValue === '' && 'hidden'}`}
          onClick={sendCode}
        >
          {t('confirm-email-modal.button')}
        </SecondaryButton>
      </div>
    </Modal>
  );
}
