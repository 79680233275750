import classNames from 'classnames';
import React from 'react';

type ButtonFilterWrapperProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export default function ButtonFilterWrapper({
  title,
  children,
  className,
}: ButtonFilterWrapperProps) {
  return (
    <div
      className={classNames(
        'flex flex-row flex-wrap lg:flex-nowrap justify-between md:justify-start gap-x-4',
        className,
      )}
    >
      <div className='text-14 lg:hidden w-full flex-shrink-0 mb-4 text-category text-right md:text-left'>
        {title}
      </div>
      {children}
    </div>
  );
}
