import { FC, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { defaultModalStyles } from '../../utils/modal';
import { BaseModal } from '../../components/modals/BaseModal';
import { Formik } from 'formik';
import { AccountChangePassword } from '../../services/account';
import FormInputField from '../../components/Input/FormInputField';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { changePassword } from '../../redux/auth/auth-actions';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { Loader } from '../../components/loaders/loader';
import * as Yup from 'yup';
import { passwordValidation } from '../../utils/validation';

type AccountChangePasswordPopupProps = {
  open: boolean;
  handleCancel: () => void;
};

const validationSchema = Yup.object().shape({
  proposedPassword: passwordValidation,
  previousPassword: passwordValidation,
});

export const AccountChangePasswordPopup: FC<AccountChangePasswordPopupProps> = ({
  open,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const { changePasswordLoading } = useAppSelector((state) => state.auth);
  const initialValues = useMemo<AccountChangePassword>(
    () => ({ proposedPassword: '', previousPassword: '' }),
    [open],
  );
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(async (data: AccountChangePassword) => {
    await dispatch(changePassword(data));
    handleCancel();
  }, []);

  return (
    <BaseModal open={open} handleCancel={handleCancel}>
      <Loader withOverlay={true} show={changePasswordLoading} />
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h3 className='text-white text-l mb-2 uppercase'>
              {t('account.change-password-form-title')}
            </h3>
            <div className='mb-5'>
              <FormInputField
                name='proposedPassword'
                label={t('account.change-password-form-old-password')}
                type='password'
                placeholder={t('account.change-password-form-old-password')}
              />
            </div>
            <div className='mb-5'>
              <FormInputField
                name='previousPassword'
                label={t('account.change-password-form-new-password')}
                type='password'
                placeholder={t('account.change-password-form-new-password')}
              />
            </div>
            <div className='mb-5 flex justify-between uppercase text-white text-l'>
              <button type='button' onClick={handleCancel} className='uppercase'>
                {t('account.change-password-form-button-cancel')}
              </button>
              <button type='submit' className='uppercase'>
                {t('account.change-password-form-button-save')}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </BaseModal>
  );
};
