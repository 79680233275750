import Home from './pages/Home/Home';
import { Routes, Route } from 'react-router-dom';
import Player from './pages/Player/Player';
import SignUp from './pages/SignUp/SignUp';
import Header from './components/header/header';
import { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { COGNITO } from './configs/aws';
import Login from './pages/Login/login';
import { useAppDispatch } from './redux/reduxHooks/useAppDispatch';
import LoginModal from './components/modals/login-modal';
import { useAppSelector } from './redux/reduxHooks/useAppSelector';
import { Loader } from './components/loaders/loader';
import { loadApp } from './redux/app/app-actions';
import useMediaQuery, { breakpoints } from './hooks/useMediaQuery';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import en from './assets/translations/en.json';
import { setIsTouchScreen } from './redux/app/app-slice';
import { setUser } from './redux/auth/auth-slice';
import SubscriptionPopup from './components/SubscribtionPopup/SubscriptionPoup';

i18n.use(initReactI18next).init({
  resources: { en },
  lng: 'en',
  defaultNS: 'default',
});

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  oauth: {
    domain: 'sda-auth-user-staging.auth.eu-central-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code',
  },
});

function App() {
  const matches = useMediaQuery(breakpoints.md);
  const [open, setOpen] = useState(() => matches);

  useEffect(() => {
    setOpen(matches);
  }, [matches]);
  const dispatch = useAppDispatch();

  const { initialized } = useAppSelector((state) => state.app);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('Hub.listen', event, data);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          dispatch(setUser(data));
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
        case 'signOut':
          dispatch(setUser(null));
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        dispatch(setUser(currentUser));
      })
      .catch(() => console.log('Not signed in'));

    return unsubscribe;
  }, []);

  useEffect(() => {
    dispatch(loadApp());

    function isTouchEnabled() {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0
      );
    }

    if (isTouchEnabled()) {
      dispatch(setIsTouchScreen(true));
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div className='w-full min-h-screen bg-mat-black'>
        <LoginModal />
        <SubscriptionPopup />
        <Header open={open} setOpen={setOpen} />
        <div className='py-[63px] min-h-screen'>
          {initialized ? (
            <Routes>
              <Route path='*' element={<Home open={open} />} />
              <Route path='/player/:classId' element={<Player />} />
              <Route path='/sign-up' element={<SignUp />} />
              <Route path='/login' element={<Login />} />
            </Routes>
          ) : (
            <Loader withOverlay={true} />
          )}
        </div>
      </div>
    </I18nextProvider>
  );
}

export default App;
