import { useEffect } from 'react';
import beginner from '../../assets/img/e-lvl.png';
import advanced from '../../assets/img/advanced.jpg';
import expert from '../../assets/img/expert.jpg';
import TextPoppins from '../../components/text/text-poppins';
import DanceLevelCard from '../../components/cards/dance-level-card';
import { useTranslation } from 'react-i18next';

const DacneLevel = ({ nextStep, currentStep, onSetStep }: any) => {
  useEffect(() => {
    onSetStep(currentStep);
  }, [onSetStep, currentStep]);

  const { t } = useTranslation();

  return (
    <div className='bg-[#1C1C1C] text-white w-full lg:h-[calc(100vh-63px)] flex flex-col justify-center items-center'>
      <div className='text-center text-3xl mb-10'>
        <TextPoppins className='uppercase text-white tracking-2.8px text-[40px]'>
          {t('sign-up.level-title')}
        </TextPoppins>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-6'>
        <DanceLevelCard level={t('sign-up.level-beginner')} onClick={nextStep} pic={beginner} />
        <DanceLevelCard level={t('sign-up.level-intermediate')} onClick={nextStep} pic={advanced} />
        <DanceLevelCard level={t('sign-up.level-expert')} onClick={nextStep} pic={expert} />
      </div>
    </div>
  );
};

export default DacneLevel;
