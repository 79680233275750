import React, { FC } from 'react';
import arrowLeft from '../../assets/img/arrowLeft.svg';
import PrimaryButton from '../../components/buttons/primary-button';
import arrowRight from '../../assets/img/arrowRight.svg';
import { useTranslation } from 'react-i18next';

type NavigationControlsProps = {
  previousStep: () => void;
  nextStep: () => void;
  nextButtonDisabled?: boolean;
};

export const NavigationControls: FC<NavigationControlsProps> = ({
  previousStep,
  nextStep,
  nextButtonDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <div className='lg:absolute top-10 w-[100%] mb-5 flex justify-between'>
      <button
        onClick={previousStep}
        type='button'
        className='border border-white w-[50px] h-[50px] hover:bg-[#49FCD9] rounded-full flex justify-center items-center'
      >
        <img src={arrowLeft} alt='Arrow Left' />
      </button>
      <PrimaryButton
        onClick={nextStep}
        type='button'
        disabled={nextButtonDisabled}
        className='hover:bg-aqua radius-93 border border-white w-52 text-s'
      >
        <div className='flex items-center justify-center gap-x-4'>
          {t('sign-up.navigation-next')} <img src={arrowRight} alt='Arrow Right' />
        </div>
      </PrimaryButton>
    </div>
  );
};
