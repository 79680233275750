import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import LockIcon from '../../assets/img/lock.svg';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextRoboto from '../text/text-roboto';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { setIsPurchaseSuccessful } from '../../redux/payment/payment-slice';
import { getAccount } from '../../redux/auth/auth-actions';
import {payment} from '../../services/payment'

type StripeControlWrapperProps = {
  label?: string;
  children: React.ReactNode;
};

const StripeControlWrapper: React.FC<StripeControlWrapperProps> = ({ children, label }) => {
  return (
    <fieldset className={classNames('border relative rounded-md h-[50px] border-formGrey', {})}>
      {label && (
        <legend className={classNames('ml-[8%] pl-[2%] w-[30%] text-formGrey min-w-fit')}>
          <TextRoboto className='uppercase text-[12px] tracking-12.5%'>{label}</TextRoboto>
        </legend>
      )}
      <div className='pl-4'>{children}</div>
    </fieldset>
  );
};

const CARD_ELEMENT_OPTIONS = {
  theme: 'none',
  style: {
    base: {
      color: '#000',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      padding: '4px',
      '::placeholder': {
        color: '#fff',
      },
    },
    invalid: {
      color: 'rgb(220,38,38)',
      iconColor: 'rgb(220,38,38)',
    },
  },
};

export const StripeForm = (props: any) => {
  const [stripeId, setStripeId] = useState(props.plan?.plan?.stripeId);
  const [planId, setPlanId] = useState(props.plan?.plan?.PK);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
	const name = "name";
	const email = "test.email@gmail.com";
	 const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement)!,
        billing_details: {
          name,
          email,
        },
      });
	  const response = await payment.createStripeSubscription(paymentMethod?.paymentMethod?.id, props.plan?.plan?.stripeId, props.plan?.plan?.PK);
    
const confirmPayment = await stripe?.confirmCardPayment(
        response.data.clientSecret
      );
	  if (confirmPayment.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(confirmPayment.error.message);
    } else {
      dispatch(setIsPurchaseSuccessful(true));
      dispatch(getAccount());
      console.log(confirmPayment);
    }
  };

  return (
    <form action='/' onSubmit={handleSubmit}>
      <div>
        <div className='mb-1 text-right'>
          <div className='flex justify-end uppercase tracking-wider text-14'>
            <img src={LockIcon} alt='Lock' className='mr-2' />
            <div>{t('subscription.payment-secure')}</div>
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <StripeControlWrapper label={t('subscription.payment-card-number')}>
          <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
        </StripeControlWrapper>
      </div>
      <div className='flex justify-between'>
        <div className='w-[50%] pr-2'>
          <StripeControlWrapper label={t('subscription.payment-card-date')}>
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
          </StripeControlWrapper>
        </div>
        <div className='w-[50%] pl-2'>
          <StripeControlWrapper label={t('subscription.payment-card-cvc')}>
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
          </StripeControlWrapper>
        </div>
      </div>
      <button
        type='submit'
        className={classNames(
          ' block text-14 uppercase lg:absolute lg:bottom-2 lg:left-1/4 lg:translate-x-[-50%] w-80 mx-auto lg:mx-0 mt-2 lg:mt-0 text-center p-3 radius-22',
        )}
        style={{ background: '#F96F3D' }}
      >
        {t('subscription.payment-card-button')}
      </button>
    </form>
  );
};
