import pic from '../../assets/img/category bg pic.png';
import TextPoppins from '../../components/text/text-poppins';
import { ICategorie } from '../../models/Categorie';
import { baseUrl } from '../../services/instance';

type Props = {
  handleClick: () => void;
  category: ICategorie;
};
export default function CategoryBox({ handleClick, category }: Props) {
  const { image, title } = category;

  const url = `${baseUrl}/${image.type}/${image.file}`;

  return (
    <div
      onClick={handleClick}
      className='relative cursor-pointer overflow-hidden flex items-center rounded-xl'
    >
      <div
        className='aspect-w-[345] w-full aspect-h-[194] bg-cover bg-center bg-no-repeat '
        style={{ backgroundImage: `url(${url})` }}
      ></div>
      <div className='absolute top-0 left-0 w-full h-full bg-gray-transparent flex justify-center items-center'>
        <TextPoppins className='drop-shadow-lg shadow-[rgba(0, 0, 0, 0.35)] md:text-lg text-xl uppercase font-bold tracking-12.5%'>
          {title}
        </TextPoppins>
      </div>
    </div>
  );
}
