import { RefObject } from 'react';
import CustomSection from './CustomSection';
import { useTranslation } from 'react-i18next';

type Props = {
  viewSection: boolean;
  viewSectionRef: RefObject<HTMLDivElement>;
  onSectionChange: (value: number) => void;
};

export default function SectionsPopup({ viewSection, viewSectionRef, onSectionChange }: Props) {
  const { t } = useTranslation();
  return (
    <div
      ref={viewSectionRef}
      className={`w-full flex flex-col h-full z-20 overflow-hidden md:w-[400px] md:h-[300px] absolute right-0 top-0 md:right-4 transform transition-all duration-500 bg-black-transparent ${
        viewSection ? 'translate-y-0' : '-translate-y-full'
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className=' w-full flex justify-end items-center pr-8 bg-gradient-black'>
        <p className='mb-0 text-white font-bold text-[20px] md:text-[36px]'>
          {t('player.sections-title')}
        </p>
      </div>
      <div className=' overflow-y-auto scrollbar scrollbar-thumb-gray-300 scrollbar-track-black viewSlider py-5'>
        <CustomSection onSectionChange={onSectionChange} />
      </div>
    </div>
  );
}
