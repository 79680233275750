import { ReactNode } from "react";

export default function TextRoboto({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <span className={`font-roboto ${className}`}>{children}</span>;
}
