import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IInstuctor } from '../../models/Instructor'

interface IInitialState {
  allInstructors: IInstuctor[] | null
  isLoading: boolean
}
const initialState: IInitialState = {
  allInstructors: null,
  isLoading: false,
}
const instructorsSlice = createSlice({
  initialState,
  name: 'instructors',
  reducers: {
    setAllInstructors(state: IInitialState, action: PayloadAction<IInstuctor[]>) {
      state.allInstructors = action.payload
    },
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
})

export const { setAllInstructors, setIsLoading } = instructorsSlice.actions
export const instructorsReducer = instructorsSlice.reducer
