import { FC, useMemo } from 'react';
import close from '../../assets/img/close.svg';
import Modal from 'react-modal';
import { defaultModalStyles } from '../../utils/modal';
import { BaseModal } from '../../components/modals/BaseModal';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { addMonths, format, parseISO } from 'date-fns';

type AccountSubscriptionPopupProps = {
  open: boolean;
  handleCancel: () => void;
  cancelRenewal: () => void;
};

export const AccountSubscriptionPopup: FC<AccountSubscriptionPopupProps> = ({
  open,
  handleCancel,
  cancelRenewal,
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const plans = useAppSelector((state) => state.plans.plans);

  const plan = plans.find((plan) => plan.PK === user?.planId);

  const monthDelta = useMemo(() => {
    if (user?.planId && plans) {
      const plan = plans.find((plan) => plan.PK === user.planId);
      return plan?.type === 'Month' ? 1 : 12;
    }
    return 0;
  }, [plans, user?.planId]);

  const { t } = useTranslation();
  return (
    <BaseModal open={open} handleCancel={handleCancel}>
      {plan && user?.subscriptionTo ? (
        <div className='text-white uppercase'>
          <div className='mb-3 text-[#49FCD9]'>{t('account.subscription-popup-title')}</div>
          <div className='text-[#646464]'>{t('account.subscription-popup-since')}</div>
          <div className='mb-3'></div>
          <div className='text-[#646464]'>{t('account.subscription-popup-plan')}</div>
          <div>
            {plan.type === 'Month'
              ? t('account.subscription-popup-monthly-title')
              : t('account.subscription-popup-yearly-title')}
          </div>
          <div className='mb-3'>
            {plan.type === 'Month'
              ? t('account.subscription-popup-monthly-price', { price: plan.price })
              : t('account.subscription-popup-yearly-price', { price: plan.price })}
          </div>
          <div>
            <span className='text-[#646464]'>{t('account.subscription-popup-next-billing')}</span>{' '}
            {format(addMonths(parseISO(user.subscriptionTo), monthDelta), 'dd.MM.yyyy')}
          </div>
          <div>
            <button
              onClick={cancelRenewal}
              className='border-b border-slate-500 uppercase text-[#646464]'
            >
              {t('account.subscription-popup-cancel')}
            </button>
          </div>
        </div>
      ) : null}
    </BaseModal>
  );
};
