import { INSTANCE } from './instance';

export const playlists = {
  getPlaylist: async (id: string) => {
    return INSTANCE.get(`playlist/${id}`);
  },
  getPlaylistClasses: (id: string) => {
    return INSTANCE.get(`useraction/${id}/class`);
  },
  getUserPlaylists: async () => {
    return INSTANCE.get('user/playlist');
  },
  createPlaylist: async (title: string) => {
    return INSTANCE.post('user/playlist', { title });
  },
  addClassToPlaylist: async (playlistId: string, classId: string) => {
    return INSTANCE.post(`useraction/${playlistId}/class`, {
      id: classId,
    });
  },
  removePlaylist: async (playlistId: string) => {
    return INSTANCE.delete(`user/playlist/${playlistId}/user-item`);
  },
  removeClassFormPlaylist: async (playlistId: string, classId: string) => {
    return INSTANCE.delete(`useraction/${playlistId}/class`, {
      data: {
        id: classId,
      },
    });
  },
};
