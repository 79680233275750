import SettingBtn from '../../../assets/img/settingsLogo.svg';
import SoundBtn from '../../../assets/img/soundLogo.svg';
import muteIcon from '../../../assets/img/muteIcon.svg';
import ScreenBtn from '../../../assets/img/fullscreenLogo.svg';
import PlayerButton from './PlayerButton';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { RefObject, useMemo } from 'react';
import classNames from 'classnames';

type Props = {
  showSettings: boolean;
  setVideoResolution: (value: string) => void;
  showSettingsPopup: () => void;
  muteVideo: () => void;
  displayFullScreen: () => void;
  isMute: boolean;
  settingsButtonRef: RefObject<HTMLDivElement>;
  settingsRef: RefObject<HTMLDivElement>;
  videoResolution: string;
};
export default function GlobalSettings({
  showSettings,
  setVideoResolution,
  showSettingsPopup,
  muteVideo,
  displayFullScreen,
  isMute,
  settingsButtonRef,
  settingsRef,
  videoResolution,
}: Props) {
  const settings = useMemo(
    () => [
      { value: 'auto', title: 'auto' },
      { value: '360', title: '360P' },
      { value: '540', title: '540P' },
      { value: '720', title: '720P' },
      { value: '1080', title: '1080P' },
    ],
    [],
  );

  return (
    <div className='flex gap-x-6 justify-end w-30'>
      <div className='w-[30px] h-[30px]'></div>
      <div className='relative'>
        <PlayerButton
          buttonRef={settingsButtonRef}
          className='w-[30px] h-[30px]'
          src={SettingBtn}
          onClick={showSettingsPopup}
          alt='settings_button'
        />
        {showSettings && (
          <div
            ref={settingsRef}
            className='appearance-none w-[94px] black pt-4 absolute bottom-10 md:bottom-28 -left-4 bg-black-transparent rounded-xl'
            onClick={(e) => e.stopPropagation()}
          >
            {settings.map((item) => (
              <p
                key={item.value}
                className={classNames(
                  'text-center cursor-pointer uppercase h-[25px] md:h-[33px]   md:text-[18px]',
                  {
                    'text-aqua': item.value === videoResolution,
                    'text-white': item.value !== videoResolution,
                  },
                )}
                onClick={() => setVideoResolution(item.value)}
              >
                {item.title}
              </p>
            ))}
          </div>
        )}
      </div>

      {!isMute ? (
        <PlayerButton
          className='w-[30px] h-[30px]'
          src={SoundBtn}
          onClick={muteVideo}
          alt='sound_button'
        />
      ) : (
        <PlayerButton
          className='w-[30px] h-[30px]'
          src={muteIcon}
          onClick={muteVideo}
          alt='mute_button'
        />
      )}
      <PlayerButton
        className='w-[30px] h-[30px]'
        src={ScreenBtn}
        onClick={displayFullScreen}
        alt='full_screen'
      />
    </div>
  );
}
