import TextRoboto from '../text/text-roboto';
import { useCallback } from 'react';
import classNames from 'classnames';

type Props = {
  title: string;
  selected: boolean;
  value: string;
  onClick: (value: string) => void;
};

export default function FilterButton({ title, selected, onClick, value }: Props) {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  return (
    <button
      key={title}
      onClick={handleClick}
      className={classNames(
        'mb-4 lg:mb-0 flex items-center flex-shrink-0 justify-center hover:bg-gray-300 h-[30px] text-white hover:text-black text-center cursor-pointer py-1 px-4 rounded-3xl border w-[30%] max-w-[140px] md:w-[140px]',
        {
          'selected-category': selected,
        },
      )}
    >
      <TextRoboto className='tracking-2.8px uppercase text-xxs md:text-xs text-nowrap'>
        {title}
      </TextRoboto>
    </button>
  );
}
