import { useCallback, useEffect, useRef, useState } from 'react';
import SignUpForm, { SignUpFormValues } from '../../components/forms/signUpForm';
import { NavigationControls } from './NavigationControls';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { FormikProps } from 'formik/dist/types';
import { Trans, useTranslation } from 'react-i18next';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';

const CreateAccount = ({ nextStep, currentStep, onSetStep, previousStep }: any) => {
  // @ts-ignore
  const formRef = useRef<FormikProps<SignUpFormValues>>({});
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    onSetStep(currentStep);
  }, [onSetStep, currentStep]);

  const { emailToConfirm } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (submitted && emailToConfirm) {
      nextStep();
    }
  }, [submitted, emailToConfirm]);

  const match = useMediaQuery(breakpoints.lg);

  const handleNextStep = useCallback(async () => {
    try {
      setSubmitted(false);
      console.log(formRef.current);
      await formRef.current?.submitForm();
      setSubmitted(true);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className='bg-[#1C1C1C] text-white relative w-full lg:h-[calc(100vh-63px)] flex flex-col justify-center items-center'>
      {match && <NavigationControls previousStep={previousStep} nextStep={handleNextStep} />}
      <div className='text-center text-white text-[40px] uppercase mb-10'>
        {t('sign-up.create-account-title')}
      </div>
      <div className='w-full lg:px-0 lg:w-[40%]'>
        <SignUpForm formRef={formRef} />

        <div className='text-14 text-center uppercase mt-8 text-white'>
          <Trans
            i18nKey='sign-up.create-account-privacy-and-terms'
            components={{
              privacyLink: <a href='#' className='border-b border-white' />,
              termsLink: <a href='#' className='border-b border-white' />,
            }}
          ></Trans>
        </div>
      </div>

      {!match && (
        <div className='mt-5 w-full'>
          <NavigationControls previousStep={previousStep} nextStep={handleNextStep} />
        </div>
      )}
    </div>
  );
};

export default CreateAccount;
