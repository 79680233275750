import { INSTANCE } from './instance';

export const home = {
  getMainContent: async () => {
    return INSTANCE.get('section?place=main&place=right');
  },
  getSectionBottom: async () => {
    return INSTANCE.get('section?place=bottom');
  },
  getClasses: async () => {
    return INSTANCE.get('class');
  },
  getSpecificClass: async () => {
    return INSTANCE.get('class');
  },
};
