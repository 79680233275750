import { ReactNode } from 'react';
import TextPoppins from '../text/text-poppins';
import classNames from 'classnames';

type Props = {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: any;
};

export default function PrimaryButton({ type, children, className, disabled, onClick }: Props) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(' bg-orange rounded-[23px] py-2 uppercase tracking-2.8px', className, {
        'bg-formGrey pointer-events-none': disabled,
      })}
    >
      <TextPoppins>{children}</TextPoppins>
    </button>
  );
}
