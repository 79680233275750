import { Formik } from 'formik';
import { userSignUp } from '../../redux/auth/auth-actions';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import DropDown from '../DropDown/DropDown';
import FormInput from '../Input/FormInput';
import ConfirmEmailModal from '../modals/confirm-email-modal';
import * as Yup from 'yup';
import { passwordValidation } from '../../utils/validation';
import React, { FC, MutableRefObject } from 'react';
import { FormikProps } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  confirmEmail: Yup.string()
    .required()
    .email()
    .when('email', (email, schema) => {
      return schema.oneOf([email], 'confirm email should be equal email');
    }),
  name: Yup.string()
    .required()
    .matches(/^[a-zA-Z ]+$/, 'only english letter allowed'),
  password: passwordValidation,
});

export type SignUpFormValues = {
  email: string;
  confirmEmail: string;
  password: string;
  name: string;
};

type SignUpFormProps = {
  formRef: MutableRefObject<FormikProps<SignUpFormValues>>;
};

const SignUpForm: FC<SignUpFormProps> = ({ formRef }) => {
  const dispatch = useAppDispatch();
  const { emailToConfirm, registrationError } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          email: '',
          confirmEmail: '',
          password: '',
          name: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(userSignUp(values));
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isSubmitting, handleChange, handleSubmit, values, handleBlur }) => (
          <form onSubmit={handleSubmit} className='w-full flex flex-col gap-y-10'>
            {registrationError && <div className='text-xs text-red-600'>{registrationError}</div>}
            <FormInput
              onBlur={handleBlur}
              value={values.name}
              error={errors.name}
              touched={touched.name}
              name='name'
              label={t('sign-up.create-account-field-name')}
              type='text'
              onChange={handleChange}
            />
            <FormInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              touched={touched.email}
              name='email'
              label={t('sign-up.create-account-field-email')}
              type='email'
            />
            <FormInput
              onBlur={handleBlur}
              value={values.confirmEmail}
              error={errors.confirmEmail}
              touched={touched.confirmEmail}
              name='confirmEmail'
              label={t('sign-up.create-account-field-confirm-email')}
              type='email'
              onChange={handleChange}
            />
            <FormInput
              onBlur={handleBlur}
              value={values.password}
              error={errors.password}
              touched={touched.password}
              name='password'
              label={t('sign-up.create-account-field-password')}
              type='password'
              onChange={handleChange}
            />
          </form>
        )}
      </Formik>
      {emailToConfirm && <ConfirmEmailModal email={emailToConfirm} />}
    </>
  );
};

export default SignUpForm;
