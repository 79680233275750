import { BasicContent } from '../../components/BasicContent/BasicContent';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import VideosStack from '../../videos/ViedeosStack/VideosStack';
import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { getClassHistory } from '../../redux/history/history-actions';

const History = () => {
  const { t } = useTranslation();
  const { classHistory, isLoading } = useAppSelector((state) => state.history);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClassHistory());
  }, []);

  return (
    <BasicContent title={t('history.title')} loading={isLoading}>
      <VideosStack videos={classHistory} />
    </BasicContent>
  );
};

export default History;
