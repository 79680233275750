import ScrollToTop from '../scroll-to-top';
import VideosStack from '../../videos/ViedeosStack/VideosStack';
import Banner from './Banner';
import TrendVideos from './TrendVideos';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { Playlists } from './Playlists';
import { useTranslation } from 'react-i18next';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { fetchMainContent, fetchSectionBottom } from '../../redux/videos/video-actions';
import { useSearchParams } from 'react-router-dom';
import { useToggle } from '../../hooks/useToggle';
import SubscriptionPopupSuccess from '../SubscribtionPopup/SubscriptionPoupSuccess';
import SubscriptionPopupFail from '../SubscribtionPopup/SubscriptionPoupFail';

export default function MainContent() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { hotVideo, filtersBottom, videosOfTheWeek } = useAppSelector((state) => state.videos);
  const dispatch = useAppDispatch();
  const subscription = searchParams.get('subscription');
  const [
    subscriptionSuccess,
    _toggleSubscriptionSuccess,
    _openSubscriptionSuccess,
    closeSubscriptionSuccess,
  ] = useToggle(subscription === 'success');
  const [subscriptionFail, _toggleSubscriptionFail, _openSubscriptionFail, closeSubscriptionFail] =
    useToggle(subscription === 'fail');

  useEffect(() => {
    dispatch(fetchMainContent());
    dispatch(fetchSectionBottom());
  }, []);

  const isMatch = useMediaQuery(breakpoints.md);

  return (
    <ScrollToTop>
      <div className='text-white pl-[1%] md:pl-0 pt-[1%]'>
        <div className='md:flex bg-black md:justify-between overflow-hidden  md:h-[40vw] md:pr-[0.5%]'>
          {hotVideo && <Banner video={hotVideo} />}
          {videosOfTheWeek && <TrendVideos videos={videosOfTheWeek} />}
        </div>
      </div>
      <div className='ml-2 mt-16'>
        <Playlists />
        {filtersBottom.map((filter, index) => {
          if (!filter.items.length) {
            return null;
          }
          return (
            <div className='px-6 pt-20' key={`${filter.title}-${index}`}>
              <p className='font-bold text-md uppercase'>
                {filter.title} <span className='text-take-training'>{`>`}</span>
              </p>
              <div className='pt-8'>
                <VideosStack section={filter.title} videos={filter.items} isHorizontal={!isMatch} />
              </div>
            </div>
          );
        })}
      </div>
      <SubscriptionPopupSuccess open={subscriptionSuccess} handleClose={closeSubscriptionSuccess} />
      <SubscriptionPopupFail open={subscriptionFail} handleClose={closeSubscriptionFail} />
    </ScrollToTop>
  );
}
