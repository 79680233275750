import React, { ClipboardEvent, FC, useCallback, useState } from 'react'
import TextRoboto from '../text/text-roboto'
import classNames from 'classnames'
import FormInput, { InputProps } from './FormInput'
import { useField } from 'formik'

type FormInputFieldProps = {
  name: string
} & Omit<InputProps, 'name'>

const FormInputField: FC<FormInputFieldProps> = ({ name, ...rest }) => {
  const [field, meta] = useField(name)

  return <FormInput {...rest} {...field} {...meta} name={name} />
}

export default FormInputField
