import React, { useEffect, useRef, useState } from 'react';
import { setHotMuted, setPreviewMuted } from '../../redux/videos/videoSlice';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import SecondaryButton from '../buttons/secondary-button';
import TakeTrainingButton from '../buttons/take-training-button';
import TextRoboto from '../text/text-roboto';
import TextPoppins from '../text/text-poppins';
import { baseUrl, videoPreviewUrl } from '../../services/instance';
import { IClass } from '../../models/Video';
import { BannerIconControls } from './BannerIconControls';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import ClassTrainingPopup from '../popups/TrainingPopup/ClassTrainingPopup';

type Props = {
  video: IClass;
};

export default function Banner({ video }: Props) {
  const { preview, title, PK } = video;
  const videoRef = useRef<HTMLVideoElement>(null);
  const matches = useMediaQuery(breakpoints.md);
  const [src, setSrc] = useState<null | string>(null);
  const [addTrainingPopup, setAddTrainingPopup] = useState(false);

  useEffect(() => {
    setSrc(`${videoPreviewUrl}${preview.file}`);
  }, []);

  const playVideo = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch((error: string) => {
        console.error('Error attempting to play', error);
      });
  };

  const { hotMuted } = useAppSelector((state) => state.videos);
  const dispatch = useAppDispatch();

  const handleMute = () => {
    if (hotMuted) {
      dispatch(setHotMuted(false));
      dispatch(setPreviewMuted(false));
    } else {
      dispatch(setHotMuted(true));
      dispatch(setPreviewMuted(true));
    }
  };
  const { t } = useTranslation();

  return (
    <div className='w-full  md:w-[70%] h-full'>
      <div className='relative'>
        {!matches && (
          <div className='absolute z-10 flex gap-x-2 justify-end w-full pr-2 top-2'>
            <BannerIconControls
              video={video}
              hotMuted={hotMuted}
              handleMute={handleMute}
              classId={PK}
            />
          </div>
        )}
        <div className='cursor-pointer h-[auto]'>
          {src && (
            <video
              id='bannerVideo'
              ref={videoRef}
              className='w-full h-full object-cover'
              src={src}
              muted={hotMuted}
              playsInline
              onLoadedMetadata={playVideo}
              loop
            />
          )}
        </div>
        <div className='info absolute bottom-8 w-full md:pl-[41px] md:pr-[22px] pl-2 pr-2'>
          <TextRoboto className='text-lg tracking-2.8px'>{title}</TextRoboto>
          <div className='flex justify-between items-center mt-4'>
            <div className='relative'>
              <SecondaryButton
                onClick={() => setAddTrainingPopup(true)}
                className='px-6 text-black uppercase text-xs py-2'
              >
                {t('main.banner-add-to-training-button')}
              </SecondaryButton>
              {addTrainingPopup && (
                <div
                  onMouseLeave={() => setAddTrainingPopup(false)}
                  className='absolute -left-1 -top-2'
                >
                  <ClassTrainingPopup open={addTrainingPopup} classId={PK} />
                </div>
              )}
            </div>
            <div className='flex gap-x-8 items-center'>
              {matches && (
                <BannerIconControls
                  video={video}
                  classId={PK}
                  hotMuted={hotMuted}
                  handleMute={handleMute}
                />
              )}
              <TakeTrainingButton video={video} />
            </div>
          </div>
        </div>
      </div>
      <TextPoppins className='flex md:justify-end font-bold uppercase text-xl'>
        {t('main.banner-title')}
      </TextPoppins>
    </div>
  );
}
