import UnderlineButton from '../../buttons/underline-button';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import { useAppDispatch } from '../../../redux/reduxHooks/useAppDispatch';
import { removePlaylist } from '../../../redux/playlist/playlist-actions';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/reduxHooks/useAppSelector';
import { setIsLoginModalOpen } from '../../../redux/auth/auth-slice';
import TrainingPopupContent from './TrainingPopupContent';
import { Playlist } from '../../../redux/playlist/playlist-slice';

type CreateTrainingPopupProps = {
  open: boolean;
};

export default function TrainingListPopup({ open }: CreateTrainingPopupProps) {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (open && !user) {
      dispatch(setIsLoginModalOpen(true));
    }
  }, [open]);

  const onRemove = useCallback((playlist: Playlist) => {
    dispatch(removePlaylist(playlist));
  }, []);

  const playlistButtonRenderer = useCallback(
    (playlist: Playlist) => (
      <UnderlineButton className='uppercase' onClick={() => onRemove(playlist)}>
        {t('trainings.training-popup-delete-playlist-button')}
      </UnderlineButton>
    ),
    [],
  );

  return <TrainingPopupContent open={open} playlistButtonRenderer={playlistButtonRenderer} />;
}
