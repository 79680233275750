import { useState } from 'react';
import plus from '../../assets/img/Icon feather-plus.svg';
import { useTakeTraining } from '../../hooks/useTakeTraining';
import { IClass } from '../../models/Video';
import CreateTrainingPop from '../popups/TrainingPopup/ClassTrainingPopup';
import TextRoboto from '../text/text-roboto';

type Props = {
  count: number;
  thumbnail: string;
  title: string;
  classId: string;
  video: IClass;
};

export default function TrendVideoBox({ count, classId, thumbnail, title, video }: Props) {
  const [popup, setPopup] = useState(false);
  const takeTraining = useTakeTraining(video);

  const style = {
    number: {
      fontSize: '115px',
      lineHeight: '172.5px',
      left: '-10px',
      top: '-60px',
    },
    plusIcon: {
      width: '13px',
      height: '13px',
    },
  };

  return (
    <div className='wrapper flex justify-between mt-8 relative pl-[8%] min-w-[60vw] md:min-w-[250px]'>
      <h1 style={style.number} className='absolute strokeText text-xs'>
        {count}
      </h1>
      <div className='relative w-full'>
        <div
          onClick={takeTraining}
          className='video-thumbnail cursor-pointer'
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <img
          onTouchStart={() => setPopup(true)}
          onClick={() => setPopup(true)}
          className='absolute cursor-pointer w-[30px] right-4 top-5 z-20'
          src={plus}
          alt='plus_icon'
        />
        <div className=' z-10 absolute flex justify-end items-end pr-2 pb-8 pointer-events-none bg-trandVideo top-0 left-0 h-full w-full'>
          <TextRoboto className='text-s uppercase '>{title}</TextRoboto>
        </div>
        {popup && (
          <div onMouseLeave={() => setPopup(false)} className='absolute top-4 right-2 z-[1000]'>
            <CreateTrainingPop open={popup} classId={classId} />
          </div>
        )}
      </div>
    </div>
  );
}
