import React, { ClipboardEvent, FC, useCallback, useState } from 'react';
import TextRoboto from '../text/text-roboto';
import classNames from 'classnames';
import * as events from 'events';

export type InputProps = {
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  type?: string;
  error?: string;
  touched?: boolean;
  name?: string;
  value?: string;
  onChange?: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onFocus?: (e: React.FocusEvent<any>) => void;
  inputClassName?: string;
};

const FormInput: FC<InputProps> = ({
  label,
  type,
  error,
  touched,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  disabled,
  onFocus,
  inputClassName,
}) => {
  const preventPaste = useCallback((e: ClipboardEvent) => {
    e.preventDefault();
    return false;
  }, []);

  const [inputType, setInputType] = useState(type);

  const passwordVisibilityToggle = () => {
    if (inputType === 'password') setInputType('text');
    if (inputType === 'text') setInputType('password');
  };

  return (
    <div className='relative'>
      <fieldset
        className={classNames('border relative rounded-md h-[50px] border-formGrey', {
          'border-red-600': error,
        })}
      >
        {label && (
          <legend
            className={classNames('ml-[8%] pl-[2%] w-[30%] text-formGrey min-w-fit', {
              'text-red-600': error && touched,
            })}
          >
            <TextRoboto className='uppercase text-[12px] tracking-12.5%'>{label}</TextRoboto>
          </legend>
        )}
        <input
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={name === 'confirmEmail' ? 'new-password' : undefined}
          onBlur={onBlur}
          className={classNames(
            'bg-transparent p-2 rounded-lg focus:outline-none active-outline-none w-full text-white',
            inputClassName,
          )}
          type={inputType}
          onFocus={onFocus}
          name={name}
          value={value}
          onChange={onChange}
        />
        {type === 'password' && (
          <div
            onClick={passwordVisibilityToggle}
            className='absolute top-1/2 right-3  translate-y-[-55%] cursor-pointer text-white'
          >
            {inputType === 'password' ? 'show' : 'hide'}
          </div>
        )}
      </fieldset>
      {error && touched && <span className='text-xs text-red-600'>{error}</span>}
    </div>
  );
};

export default FormInput;
