import { instructors } from '../../services/instructors'
import { AppDispatch } from '../store'
import { setAllInstructors, setIsLoading } from './instructors-slice'

export const fetchAllInstructors = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    const response = await instructors.getAllInstructors()
    dispatch(setAllInstructors(response.data))
  } catch (error) {
    console.log(error)
  }
  dispatch(setIsLoading(false))
}
