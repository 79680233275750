import MainContent from '../../components/MainContent/MainContent';
import Category from '../Category/Category';
import Classes from '../Classes/Classes';
import Instructors from '../Instructors/Instructors';
import Account from '../Account/Account';
import History from '../History/History';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import MainTrainingList from '../MainTrainingList/MainTrainingList';
import MainTraining from '../MainTraining/MainTraining';
import { MyClasses } from '../MyClasses/MyClasses';
import Instructor from '../Instructor/Instructor';
import { useResetPlayingPreviewVideo } from '../../hooks/useResetPlayingPreviewVideo';

type Props = {
  open: boolean;
};
export default function Home({ open }: Props) {
  useResetPlayingPreviewVideo();
  return (
    <div className=' min-h-full text-white flex justify-between'>
      <Sidebar open={open} />
      <div className='w-full flex-shrink-1 overflow-hidden relative'>
        <Routes>
          <Route path='/' element={<MainContent />} />
          <Route path='/classes' element={<Classes />} />
          <Route path='/mein-trainings' element={<MainTrainingList />} />
          <Route path='/mein-trainings/:id' element={<MainTraining />} />
          <Route path='/instructors' element={<Instructors />} />
          <Route path='/instructors/:id' element={<Instructor />} />
          <Route path='/category' element={<Category />} />
          <Route path='/history' element={<History />} />
          <Route path='/account' element={<Account />} />
          <Route path='/my-classes' element={<MyClasses />} />
          <Route path='*' element={<div>Not Found 404</div>} />
        </Routes>
      </div>
    </div>
  );
}
