import { ChangeEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import 'rc-slider/assets/index.css';
import loop from '../../assets/img/loop.svg';
import PlayerActions from './player-components/PlayerActions';
import VideoStyles from './player-components/VideoStyles';
import GlobalSettings from './player-components/GlobalSettings';
import SectionsPopup from './player-components/SectionsPopup';
import bigPlayButton from '../../assets/icons/bigPlayButton.svg';
import bigPauseButton from '../../assets/img/bigPauseButton.svg';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import TooltipSlider from './player-components/loop-slider';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  handleShowControls: () => void;
  progress: number;
  handleVideoProgress: (e: ChangeEvent<HTMLInputElement>) => void;
  playPauseVideo: (e: MouseEvent) => void;
  backVideo: () => void;
  nextVideo: () => void;
  loopVideo: () => void;
  resetSpeed: () => void;
  speed: number;
  speedHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  setVideoResolution: (value: string) => void;
  videoResolution: string;
  isMute: boolean;
  muteVideo: () => void;
  displayFullScreen: () => void;
  isPlay: boolean;
  isLoop: boolean;
  mirrorVideo: () => void;
  isMirror: boolean;
  handleChangeView: () => void;
  isBackViewMode: boolean;
  elapsedTime: string;
  totalDuration: string;
  handleSectionChange: (value: number) => void;
  showControls: boolean;
  handleSliderChange: (value: number[]) => void;
  rangesValue: {
    startValue: number;
    endValue: number;
  };
  showSpeedPopup: boolean;
  setShowSpeedPopup: ActionCreatorWithPayload<boolean, string>;
  showViewSection: boolean;
  setShowViewSection: ActionCreatorWithPayload<boolean, string>;
  showSettings: boolean;
  setShowSettings: ActionCreatorWithPayload<boolean, string>;
  speedRef: RefObject<HTMLDivElement>;
  viewSectionRef: RefObject<HTMLDivElement>;
  viewSectionButtonRef: RefObject<HTMLButtonElement>;
  settingsRef: RefObject<HTMLDivElement>;
  settingsButtonRef: RefObject<HTMLDivElement>;
  speedButtonRef: RefObject<HTMLDivElement>;
  bottomControlsRef: RefObject<HTMLDivElement>;
  topControlsRef: RefObject<HTMLDivElement>;
  screenClicked: boolean;
  format: (seconds: number) => string;
  duration: number | '00:00';
};
const PlayerControls = ({
  handleShowControls,
  progress,
  handleVideoProgress,
  playPauseVideo,
  backVideo,
  nextVideo,
  loopVideo,
  resetSpeed,
  speed,
  speedHandler,
  setVideoResolution,
  isMute,
  muteVideo,
  displayFullScreen,
  isPlay,
  isLoop,
  mirrorVideo,
  isMirror,
  handleChangeView,
  isBackViewMode,
  elapsedTime,
  totalDuration,
  handleSectionChange,
  videoResolution,
  showControls: showControlsProp,
  handleSliderChange,
  rangesValue,
  showSpeedPopup,
  setShowSpeedPopup,
  showViewSection,
  setShowViewSection,
  showSettings,
  setShowSettings,
  speedRef,
  viewSectionRef,
  viewSectionButtonRef,
  settingsRef,
  settingsButtonRef,
  speedButtonRef,
  bottomControlsRef,
  topControlsRef,
  screenClicked,
  format,
  duration,
}: Props) => {
  const sliderRangeRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [showStopButton, setShowStopButton] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showControls = true;

  useEffect(() => {
    if (isPlay && screenClicked) {
      setShowPlayButton(true);
      setTimeout(() => {
        setShowPlayButton(false);
      }, 1000);
    }
    if (!isPlay && screenClicked) {
      setShowStopButton(true);
      setTimeout(() => {
        setShowStopButton(false);
      }, 1000);
    }
  }, [isPlay]);

  const speedPopupHandle = () => {
    dispatch(setShowSpeedPopup(!showSpeedPopup));
    dispatch(setShowSettings(false));
    dispatch(setShowViewSection(false));
  };
  const showSettingsPopup = () => {
    dispatch(setShowSettings(!showSettings));
    dispatch(setShowSpeedPopup(false));
    dispatch(setShowViewSection(false));
  };
  const showViewSectionPopup = () => {
    dispatch(setShowViewSection(!showViewSection));
    dispatch(setShowSettings(false));
    dispatch(setShowSpeedPopup(false));
  };

  const isMd = useMediaQuery(breakpoints.md);
  const navigate = useNavigate();

  const endClass = useCallback(() => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <div className='w-full h-full relative flex justify-center items-center'>
      <div
        ref={topControlsRef}
        className={`absolute z-[10] top-0 pt-10 w-full px-10 transition-all duration-500 bg-top-controls ${
          showControls ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className='flex w-full items-center flex-wrap justify-between'>
          <button
            ref={viewSectionButtonRef}
            onClick={showViewSectionPopup}
            className='focus:outline-none h-7 w-40   order-1 md:order-2 border border-white text-sm text-white rounded-2xl'
          >
            {t('player.controls-sections')}
          </button>
          <button
            className='order-0 md:inline-block focus:outline-none h-7 w-40 border border-aqua text-sm text-aqua rounded-2xl'
            onClick={endClass}
          >
            {t('player.controls-end-class')}
          </button>
          <div className='md:flex w-full md:w-auto flex-shrink-0 md:flex-shrink-1 text-center order-2 md:order-1  md:text-left items-baseline'>
            <h2 className='text-md text-aqua'>{t('player.controls-title')}</h2>
            <span className='text-[24px] md:text-[34px] font-bold uppercase text-white ml-4'>
              Warm Up with Dan
            </span>
          </div>
        </div>
      </div>
      {showPlayButton && (
        <img //@ts-ignore
          onClick={playPauseVideo}
          className={`absolute disappear`}
          src={bigPlayButton}
        />
      )}
      {showStopButton && (
        <img //@ts-ignore
          onClick={playPauseVideo}
          className={`absolute disappear`}
          src={bigPauseButton}
        />
      )}
      <div
        ref={bottomControlsRef}
        className={`z-[10] absolute bottom-0 w-full pb-4 px-10 transition-all duration-500 bg-bottom-controls lg:pt-[80px] md:pt-[100px] ${
          showControls ? 'translate-y-0' : 'translate-y-full'
        }`}
      >
        {!isMd && (
          <VideoStyles
            speedButtonRef={speedButtonRef}
            speedRef={speedRef}
            isLoop={isLoop}
            loopVideo={loopVideo}
            mirrorVideo={mirrorVideo}
            onChangeView={handleChangeView}
            resetSpeed={resetSpeed}
            speed={speed}
            speedPopup={showSpeedPopup}
            isMirror={isMirror}
            isView={isBackViewMode}
            setSpeedPopup={speedPopupHandle}
            speedHandler={speedHandler}
          />
        )}
        <div className='w-full lg:mb-4 md:mb-2 flex items-center justify-between'>
          {isLoop ? (
            <TooltipSlider
              handleShowControls={handleShowControls}
              duration={duration}
              className='slider'
              range
              step={0.01}
              min={0}
              max={100}
              //@ts-ignore
              onChange={handleSliderChange}
              pushable={true}
              count={2}
              value={[rangesValue.startValue, progress * 100, rangesValue.endValue]}
              tipFormatter={format}
              trackStyle={[{ backgroundColor: '#FF652F' }, { backgroundColor: 'gray' }]}
              handleStyle={{
                backgroundImage: `url('${loop}')`,
                height: 28,
                width: 28,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                top: -14,
                backgroundColor: 'transparent',
                border: 0,
                boxShadow: '0 0 0 0 #fff',
                cursor: 'pointer',
              }}
            />
          ) : (
            <input
              style={{
                background: `linear-gradient(to right, #ff652f 0%, #ff652f ${
                  progress * 100
                }%, #dddddd ${progress * 100}%, #dddddd 100%)`,
              }}
              ref={sliderRangeRef}
              className='slider'
              type='range'
              min='0'
              max='100'
              value={progress * 100}
              onChange={(e) => handleVideoProgress(e)}
            />
          )}
          <div className='text-white flex text-[14px] w-40 justify-end'>
            <p className='mr-0.5'>{elapsedTime}</p>/<p className='ml-0.5'>{totalDuration}</p>
          </div>
        </div>

        <div className='flex items-center justify-between w-full'>
          <PlayerActions
            playPauseVideo={playPauseVideo}
            backVideo={backVideo}
            isPlaying={isPlay}
            nextVideo={nextVideo}
          />
          {isMd && (
            <VideoStyles
              speedButtonRef={speedButtonRef}
              speedRef={speedRef}
              isLoop={isLoop}
              loopVideo={loopVideo}
              mirrorVideo={mirrorVideo}
              onChangeView={handleChangeView}
              resetSpeed={resetSpeed}
              speed={speed}
              speedPopup={showSpeedPopup}
              isMirror={isMirror}
              isView={isBackViewMode}
              setSpeedPopup={speedPopupHandle}
              speedHandler={speedHandler}
            />
          )}
          <GlobalSettings
            videoResolution={videoResolution}
            settingsButtonRef={settingsButtonRef}
            settingsRef={settingsRef}
            displayFullScreen={displayFullScreen}
            muteVideo={muteVideo}
            showSettingsPopup={showSettingsPopup}
            setVideoResolution={setVideoResolution}
            showSettings={showSettings}
            isMute={isMute}
          />
        </div>
      </div>
      <SectionsPopup
        onSectionChange={handleSectionChange}
        viewSection={showViewSection}
        viewSectionRef={viewSectionRef}
      />
    </div>
  );
};

export default PlayerControls;
