import { classes } from '../../services/classes';
import { home } from '../../services/home';
import { AppDispatch } from '../store';
import { setHotVideo, setIsVideoLoading, setFiltersBottom, setVideosOfTheWeek } from './videoSlice';

export const fetchMainContent = () => async (dispatch: AppDispatch) => {
  dispatch(setIsVideoLoading(true));
  try {
    const response = await home.getMainContent();
    const main = response.data.find((item: any) => item.sectionPlace === 'main');
    const right = response.data.find((item: any) => item.sectionPlace === 'right');
    dispatch(setHotVideo(main.items[0]));
    dispatch(setVideosOfTheWeek(right.items));
  } catch (error) {
    console.log(error);
  }
  dispatch(setIsVideoLoading(false));
};

export const fetchSectionBottom = () => async (dispatch: AppDispatch) => {
  dispatch(setIsVideoLoading(true));
  try {
    const response = await home.getSectionBottom();
    console.log(response);
    dispatch(setFiltersBottom(response.data));
  } catch (error) {
    console.log(error);
  }
  dispatch(setIsVideoLoading(false));
};
