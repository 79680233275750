export const formatTime = (seconds: number, withHour = false) => {
  const nullHourString = `${withHour ? '00:' : ''}`;
  if (seconds === 0) {
    return `${nullHourString}00:00`;
  } else {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${nullHourString}${mm}:${ss}`;
  }
};

// time format "00:00:00". returns seconds
export const parseTime = (time: string) => {
  const times = time.split(':');
  if (times.length === 3) {
    return parseInt(times[0]) * 3600 + parseInt(times[1]) * 60 + parseInt(times[2]);
  } else if (times.length === 2) {
    return parseInt(times[0]) * 60 + parseInt(times[1]);
  } else {
    return 0;
  }
};
