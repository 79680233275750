import { Tooltip } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/system';
import { FC, useState } from 'react';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';

function valuetext(value: any, index: number) {
  return `${index === 0 ? 'MIN ' + value : 'MAX ' + value}`;
}

type FilterSliderProps = {
  value?: [number, number];
  onChange: (value: [number, number]) => void;
};

const FilterSlider: FC<FilterSliderProps> = ({ value, onChange }) => {
  const handleChange = (event: Event, newValue: number[] | number) => {
    if (Array.isArray(newValue)) {
      const [first, second] = newValue;
      onChange([first, second]);
    }
  };

  return (
    <StyledFilterSlider
      min={0}
      max={60}
      step={1}
      value={value}
      onChange={handleChange}
      valueLabelDisplay='on'
      valueLabelFormat={valuetext}
      components={{
        ValueLabel: ValueLabelComponent,
      }}
    />
  );
};
const StyledFilterSlider = styled(Slider)({
  '& .MuiSlider-track': {
    border: 'none',
    height: '4px',
    background: 'linear-gradient(89.99deg, #ff652f -0.83%, #48ffde 98.89%)',
  },
  '& .MuiSlider-thumb': {
    borderRadius: '100%',
    background: '#454a4e',
    cursor: 'pointer',
    border: '4px solid rgba(181, 181, 181, 0.4)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-rail': {
    border: 'none',
    height: '4px',
    background: 'linear-gradient(89.99deg, #ff652f -0.83%, #48ffde 98.89%)',
    opacity: '0.7',
  },
});
function ValueLabelComponent(props: any) {
  const { children, value } = props;
  const { isFilterOpen } = useAppSelector((state) => state.filter);
  const matches = useMediaQuery(breakpoints.sm);
  return (
    <Tooltip
      open={isFilterOpen}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            fontSize: 14,
            fontFamily: 'roboto',
            letterSpacing: '1px',
            display: 'flex',
            justifyContent: 'flex-start',
            cursor: 'pointer',
          },
        },
      }}
      placement={matches ? 'bottom' : 'top'}
      title={value}
    >
      {children}
    </Tooltip>
  );
}

export default FilterSlider;
