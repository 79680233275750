import React, { MouseEventHandler, TouchEvent, useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IClass } from '../models/Video';
import { useAppSelector } from '../redux/reduxHooks/useAppSelector';
import {
  setHotMuted,
  setPlayingPreviewVideoClassId,
  setPreviewMuted,
} from '../redux/videos/videoSlice';
import { assetsUrl, baseUrl, videoPreviewUrl } from '../services/instance';
import PausedVideoLayer from './pausedVideoLayer';
import PlayingVideoLayer from './playingVideoLayer';
import classNames from 'classnames';
import { useMatch } from 'react-router-dom';
import useMediaQuery, { breakpoints } from '../hooks/useMediaQuery';

type Props = {
  video: IClass;
  className?: string;
  section?: string;
};

export default function PreviewVideo({ video, className, section }: Props) {
  const { preview, thumbnail, PK, level, duration, title, relations } = video;
  const [classLevel, setClassLevel] = useState('');

  const { previewMuted, playingPreviewVideoClassId } = useAppSelector((state) => state.videos);
  const { isTouchScreen } = useAppSelector((state) => state.app);
  const { selectedCategories } = useAppSelector((state) => state.filter);
  const { isSubscriptionValid } = useAppSelector((state) => state.auth);
  const previewId = `${section ? section + '-' : ''}${PK}`;

  const classType = relations?.find((relation) => relation.PK.includes('type'))?.name;

  const classInstructor = relations?.find((relation) => relation.PK.includes('instructor'))?.name;

  const category = useMemo(() => {
    const allCategories = relations?.filter((relation) => relation.PK.includes('category')) || [];
    let category = allCategories.find((item) => selectedCategories.includes(item.PK));
    if (!category && allCategories.length > 0) {
      category = allCategories[0];
    }
    return category?.title;
  }, [selectedCategories, relations]);

  const locked = useMemo(() => {
    if (!video.premium) {
      return false;
    }

    return !isSubscriptionValid;
  }, [isSubscriptionValid, video]);
  const dispatch = useDispatch();

  const videoRef = useRef<HTMLVideoElement>(null);
  const isPlaying = useMemo(
    () => previewId === playingPreviewVideoClassId,
    [previewId, playingPreviewVideoClassId],
  );

  useEffect(() => {
    const levelHandler = () => {
      switch (level) {
        case '1':
          setClassLevel('beginner');
          break;
        case '2':
          setClassLevel('intermediate');
          break;
        case '3':
          setClassLevel('advanced');
          break;
        default:
          break;
      }
    };
    if (video) levelHandler();
  }, [video]);

  const muteToggle = () => {
    if (previewMuted) return dispatch(setPreviewMuted(false));
    dispatch(setPreviewMuted(true));
  };

  const play = () => {
    dispatch(setPlayingPreviewVideoClassId(previewId));
  };

  const pause = () => {
    dispatch(setPlayingPreviewVideoClassId(undefined));
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current?.play();
      dispatch(setHotMuted(true));
    } else {
      videoRef.current?.pause();
      if (videoRef.current && videoRef.current.currentTime !== 0) videoRef.current.currentTime = 0;
    }
  }, [isPlaying]);

  const playToggleProps = useMemo(
    () =>
      !isTouchScreen
        ? {
            onMouseEnter: play,
            onMouseLeave: pause,
          }
        : {
            onTouchStart: play,
          },
    [isTouchScreen],
  );

  return (
    <div className={className}>
      <div
        className={classNames(
          'relative aspect-w-16 aspect-h-9 rounded-[6px] border border-mat-black',
        )}
        {...playToggleProps}
      >
        {isPlaying ? (
          <video
            className={`${!isPlaying && 'opacity-0'} absolute w-full l-0 t-0 rounded-[6px]`}
            muted={previewMuted}
            controls={false}
            src={`${videoPreviewUrl}${preview.file}`}
            preload='metadata'
            ref={videoRef}
            playsInline={true}
            style={{ pointerEvents: 'none' }}
          />
        ) : null}
        {isPlaying ? (
          <PlayingVideoLayer
            video={video}
            classId={PK}
            muteToggle={muteToggle}
            isMuted={previewMuted}
          />
        ) : (
          <PausedVideoLayer
            type={classType}
            duration={duration}
            style={category}
            instructor={classInstructor}
            title={title}
            level={classLevel}
            locked={locked}
          />
        )}
        <div
          className={classNames(
            ' bg-cover bg-no-repeat bg-center  transition-opacity ease-in duration-100 absolute top-0 left-0 w-full h-full rounded-[6px] z-0',
            {
              'opacity-0': isPlaying,
            },
          )}
          style={{ backgroundImage: `url(${assetsUrl}${thumbnail.file})` }}
        />
      </div>
    </div>
  );
}
