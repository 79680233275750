import MainMenu from './mainMenu/MainMenu';
import SecondaryMenu from './secondaryMenu/SecondaryMenu';
import classNames from 'classnames';

type Props = {
  open: boolean;
};
export default function Sidebar({ open }: Props) {
  return (
    <>
      <div
        className={classNames(
          'z-30 w-full overflow-x-hidden scrollbar-hide fixed pt-5 bottom-0 md:bottom-auto left-0 lg:text-s text-[14px] md:max-h-[calc(100vh-62px)] font-poppins',
          {
            'md:w-[15%] md:min-w-[235px]': open,
            'md:w-[90px] lg:w-[110px]': !open,
          },
        )}
      >
        <MainMenu open={open} />
      </div>
      <div
        className={classNames('scrollbar-hide flex-shrink-0 hidden md:block', {
          'md:w-[17%] md:min-w-[235px]': open,
          'md:w-[90px] lg:w-[110px]': !open,
        })}
      ></div>
    </>
  );
}
