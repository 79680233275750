import { useEffect, useRef } from 'react';

export const useDebounceEffect = (callback: Function, deps: any[], delay = 300) => {
  const timeoutRef = useRef<number>(0);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay) as unknown as number;
  }, deps);
};
