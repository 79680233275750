import { AppDispatch } from '../store';
import { setClassHistory, setIsLoading } from './history-slice';
import { userAction } from '../../services/useraction';

export const getClassHistory = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await userAction.getListOfClassHistory();
    dispatch(setClassHistory(response.data));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const setClassToHistory = (classId: string) => async (dispatch: AppDispatch) => {
  try {
    await userAction.setClassToHistory(classId);
  } catch (e) {
    console.log(e);
  }
};
