import { classes } from '../../services/classes'
import { AppDispatch } from '../store'
import { setCurrentClass, setIsLoading, setLink } from './player-slice'
import { media } from '../../services/media'

export const fetchClass = (classId: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    const response = await classes.getSingleClass(classId)
    const linkResponse = await media.getOriginalLink(classId)
    console.log(linkResponse)
    dispatch(setLink(linkResponse.data))
    dispatch(setCurrentClass(response.data))
  } catch (error) {
    console.log(error)
  }
  dispatch(setIsLoading(false))
}
