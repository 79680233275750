import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AccountSubscriptionPopup } from './AccountSubscriptionPopup';
import { AccountSubscriptionConfirmPopup } from './AccountSubscriptionConfirmPopup';
import { AccountSubscriptionSuccessPopup } from './AccountSubscriptionSuccessPopup';
import { AccountSubscription } from './AccountSubscription';
import { AccountForm } from './AccountForm';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { getAccount } from '../../redux/auth/auth-actions';
import { AccountChangePasswordPopup } from './AccountChangePasswordPopup';
import { useTranslation } from 'react-i18next';
import { useToggle } from '../../hooks/useToggle';
import { fetchPlans } from '../../redux/plans/plans-actions';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';

Modal.setAppElement('#root');

const Account = () => {
  const { t } = useTranslation();

  const [subscriptionPopup, _toggleSub, openSubscriptionPopup, closeSubscriptionPopup] =
    useToggle();
  const [subConfirmPopup, _toggleConfirm, openSubConfirmPopup, closeSubConfirmPopup] = useToggle();
  const [subSuccessPopup, _toggleSuccess, openSubSuccessPopup, closeSubSuccessPopup] = useToggle();
  const [changePasswordPopup, _togglePassword, openChangePasswordPopup, closeChangePasswordPopup] =
    useToggle();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccount());
    dispatch(fetchPlans());
  }, []);

  const handleCancelRenewal = useCallback(() => {
    closeSubscriptionPopup();
    openSubConfirmPopup();
  }, []);

  const onSuccessCancelRenewal = useCallback(() => {
    closeSubConfirmPopup();
    openSubSuccessPopup();
  }, []);

  return (
    <div className='max-w-[700px] lg:max-w-[1200px] mx-auto px-5 lg:px-0'>
      <AccountSubscriptionPopup
        cancelRenewal={handleCancelRenewal}
        open={subscriptionPopup}
        handleCancel={closeSubscriptionPopup}
      />
      <AccountSubscriptionConfirmPopup
        onSuccess={onSuccessCancelRenewal}
        open={subConfirmPopup}
        handleCancel={closeSubConfirmPopup}
      />
      <AccountSubscriptionSuccessPopup open={subSuccessPopup} handleCancel={closeSubSuccessPopup} />
      <div className='text-center text-3xl uppercase pt-10 mb-10'>{t('account.title')}</div>
      <div className='lg:flex w-auto '>
        <div className='w-[100%]  pb-5 mb-5  border-b lg:pb-0 lg:mb-0 lg:pr-5 lg:mr-5 lg:border-b-0 lg:border-r lg:border-white'>
          <AccountForm />
          <div className=' flex justify-end px-3'>
            <button onClick={openChangePasswordPopup} className='uppercase'>
              {t('account.change-password-button')}
            </button>
          </div>
        </div>
        <AccountSubscription setOpen={openSubscriptionPopup} />
        <AccountChangePasswordPopup
          open={changePasswordPopup}
          handleCancel={closeChangePasswordPopup}
        />
      </div>
    </div>
  );
};

export default Account;
