import { account } from './../../services/account';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClass } from '../../models/Video';

export type Playlist = {
  PK: string;
  SK: string;
  relations: Array<Partial<IClass> | null>;
  title: string;
};

interface IInitialState {
  isLoading: boolean;
  playlists: Array<Playlist>;
  currentPlaylist?: Playlist;
  playlistClasses: IClass[];
}

const initialState: IInitialState = {
  isLoading: false,
  playlists: [],
  playlistClasses: [],
};

const playlistSlice = createSlice({
  initialState,
  name: 'playlist',
  reducers: {
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPlaylists(state: IInitialState, action: PayloadAction<Playlist[]>) {
      state.playlists = action.payload;
    },
    setCurrentPlaylist(state: IInitialState, action: PayloadAction<Playlist>) {
      state.currentPlaylist = action.payload;
    },
    setPlaylistClasses(state: IInitialState, action: PayloadAction<IClass[]>) {
      state.playlistClasses = action.payload;
    },

    addClassToPlaylist(
      state: IInitialState,
      action: PayloadAction<{ classId: string; playlistId: string }>,
    ) {
      const { playlistId, classId } = action.payload;
      const playlistIndex = state.playlists.findIndex((item) => item.PK === playlistId);
      if (playlistIndex > -1) {
        state.playlists[playlistIndex].relations = [
          ...state.playlists[playlistIndex].relations,
          { PK: classId },
        ];

        state.playlists = [...state.playlists];
      }
    },
    removeClassFromPlaylist(
      state: IInitialState,
      action: PayloadAction<{ classId: string; playlistId: string }>,
    ) {
      const { playlistId, classId } = action.payload;
      const playlistIndex = state.playlists.findIndex((item) => item.PK === playlistId);
      if (playlistIndex > -1) {
        state.playlists[playlistIndex].relations = state.playlists[playlistIndex].relations.filter(
          (item) => item?.PK !== classId,
        );
        state.playlists = [...state.playlists];
      }
      if (state.currentPlaylist?.PK === playlistId) {
        state.playlistClasses = state.playlistClasses.filter((item) => {
          return item?.PK !== classId;
        });
      }
    },
    removePlaylist(state, action: PayloadAction<string>) {
      state.playlists = state.playlists.filter((item) => item.PK !== action.payload);
    },
    addPlaylist(state, action: PayloadAction<Playlist>) {
      state.playlists = [...state.playlists, action.payload];
    },
  },
});

export const {
  setPlaylists,
  setIsLoading,
  setCurrentPlaylist,
  setPlaylistClasses,
  removeClassFromPlaylist,
  addClassToPlaylist,
  removePlaylist,
  addPlaylist,
} = playlistSlice.actions;

export const playlistReducer = playlistSlice.reducer;
