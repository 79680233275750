import { AppDispatch } from '../store';
import { userAction } from '../../services/useraction';
import {
  setLikedClasses,
  setIsLoading,
  addLikedClass as addLikedClassSlice,
  removeLikedClass as removeLikedClassSlice,
} from './liked-class-slice';

export const fetchLikedClass = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await userAction.getLikedClass();
    dispatch(setLikedClasses(response.data));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const addLikedClass = (classId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(addLikedClassSlice(classId));
    await userAction.addLikedClass(classId);
  } catch (e) {
    dispatch(removeLikedClassSlice(classId));
    console.log(e);
  }
};

export const removeLikedClass = (classId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(removeLikedClassSlice(classId));
    await userAction.removeLikedClass(classId);
  } catch (e) {
    dispatch(addLikedClassSlice(classId));
    console.log(e);
  }
};
