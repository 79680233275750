import { useMatch } from 'react-router-dom'
import { useEffect, useRef } from 'react'

export const useMenuItem = (path: string, onActiveItem: (top: number) => void) => {
  const isActive = !!useMatch(path)
  const ref = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (isActive && ref.current) {
      const top = ref.current?.offsetTop
      onActiveItem(top)
    }
  }, [isActive])

  return {
    ref,
    isActive,
  }
}
