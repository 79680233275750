import SecondaryButton from '../buttons/secondary-button';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { setIsLoginModalOpen } from '../../redux/auth/auth-slice';
import { setSubscriptionPopupOpen } from '../../redux/app/app-slice';

export const SubscribeButton = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (user) {
      dispatch(setSubscriptionPopupOpen(true));
    } else {
      dispatch(setIsLoginModalOpen(true));
    }
  };

  return (
    <SecondaryButton onClick={onClick} className='font-bold tracking-2.8px text-[14px] px-6'>
      {t('header.subscribe-button-text')}
    </SecondaryButton>
  );
};
