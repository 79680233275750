import { IClass } from '../../models/Video';
import PreviewVideo from '../previewVideo';
import classNames from 'classnames';

type Props = {
  videos: IClass[] | null;
  emptyMessage?: string;
  isHorizontal?: boolean;
  isLoading?: boolean;
  section?: string;
};

export default function VideosStack({
  videos,
  emptyMessage,
  isHorizontal,
  isLoading,
  section,
}: Props) {
  const isNeedRenderEmptyMessage = emptyMessage && (!videos || !videos.length);
  if (isNeedRenderEmptyMessage && !isLoading) {
    return <div className='text-center text-md font-medium'>{emptyMessage}</div>;
  }
  return (
    <div
      className={classNames('md:grid-cols-2 lg:grid-cols-3 gap-x-[3%] gap-y-12 scrollbar-hide', {
        'flex flex-nowrap overflow-auto max-w-screen': isHorizontal,
        'grid grid-cols-1 ': !isHorizontal,
      })}
    >
      {videos &&
        videos.map((video, index) => (
          <PreviewVideo
            className={classNames({
              'w-10/12  md:w-[30%]  flex-shrink-0 flex-grow-0 ': isHorizontal,
            })}
            key={video.PK}
            section={section}
            video={video}
          />
        ))}
      {isHorizontal && <div className='w-4 h-full flex-shrink-0' />}
    </div>
  );
}
