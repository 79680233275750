import PlayBtn from "../../../assets/img/playLogo.svg";
import PauseButton from "../../../assets/img/pauseLogo.svg";
import PreviousBtn from "../../../assets/img/previousLogo.svg";
import NextBtn from "../../../assets/img/nextLogo.svg";
import arrowIcon from "../../../assets/icons/infoArrow.svg";
import PlayerButton from "./PlayerButton";
import { HashLink } from "react-router-hash-link";


type Props = {
  isPlaying: boolean
  playPauseVideo: (e: MouseEvent) => void
  backVideo: () => void
  nextVideo: () => void
}

export default function PlayerActions({
  isPlaying,
  playPauseVideo,
  backVideo,
  nextVideo,
}: Props) {
  
  return (
    <div className="flex gap-x-4 w-30">
      {isPlaying ? (
        <PlayerButton
          className="w-[30px] h-[30px]"
          src={PauseButton}
          onClick={playPauseVideo}
          alt="pause_icon"
        />
      ) : (
        <PlayerButton
          className="w-[30px] h-[30px]"
          src={PlayBtn}
          onClick={playPauseVideo}
          alt="play_icon"
        />
      )}
      <PlayerButton
        className="w-[30px] h-[30px]"
        src={PreviousBtn}
        onClick={backVideo}
        alt="back_video_icon"
      />
      <PlayerButton
        className="w-[30px] h-[30px]"
        onClick={nextVideo}
        src={NextBtn}
        alt="next_video_icon"
      />
      <HashLink smooth to="#preview">
        <PlayerButton
          src={arrowIcon}
          alt="arrow_icon"
          className="w-[30px] h-[30px]"
        />
      </HashLink>
    </div>
  );
}
