import { formatTime, parseTime } from '../../../utils/time';
import { useTranslation } from 'react-i18next';
import { useRelationProps } from '../../../hooks/useRelationProps';
import { useAppSelector } from '../../../redux/reduxHooks/useAppSelector';

type Props = {
  onSectionChange: (value: number) => void;
};

export default function CustomSection({ onSectionChange }: Props) {
  const { t } = useTranslation();
  const { currentClass } = useAppSelector((state) => state.player);
  const properties = useRelationProps(['section'], currentClass);

  return (
    <div className='pl-7'>
      <h2 className='text-white font-bold  text-[16px] md:text-[24px] uppercase'>
        {t('player.sections-sub-title')}
      </h2>
      <div className='py-6'>
        {properties.section?.map((section) => (
          <div
            key={section.start}
            onClick={() => {
              onSectionChange(parseTime(section.start));
            }}
            className='text-aqua text-[20px] md:text-[14px] flex items-center justify-between px-10 p cursor-pointer'
          >
            <span>{section.title}</span>
            <span>{section.start}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
