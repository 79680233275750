import {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../redux/reduxHooks/useAppDispatch";
import {createPlaylist} from "../redux/playlist/playlist-actions";

export const useCreatePlaylist = (open?: boolean) => {
    const [training, setTraining] = useState('');
    const dispatch = useAppDispatch();


    useEffect(() => {
        if(open) {
            setTraining('');
        }
    }, [open]);

    const onCreate = useCallback(() => {
        if(training) {
            dispatch(createPlaylist(training));
            setTraining("");
        }
    }, [training]);

    return {training, setTraining, onCreate};
}