import { FC, useEffect } from 'react';
import { Link, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useMenuItem } from '../../../hooks/useMenuItem';

type Props = {
  Icon?: FC<{ active: boolean }>;
  label: string;
  path: string;
  open?: boolean;
  onActiveItem: (top: number) => void;
};
const MainMenuItem = ({ Icon, label, path, open, onActiveItem }: Props) => {
  const { ref, isActive } = useMenuItem(path, onActiveItem);
  return (
    <Link
      ref={ref}
      to={path}
      className={classNames(
        'h-[51px] menu-item  flex flex-1 justify-center md:justify-start md:flex-auto items-center',
        {
          active: isActive,
        },
      )}
    >
      <div className='flex w-fit lg:ml-16 md:ml-12 md:mx-6 gap-x-7 flex-wrap justify-center'>
        {Icon && (
          <div className='flex justify-center items-center w-[30px]'>
            <Icon active={isActive} />
          </div>
        )}
        {open && <div>{label}</div>}
        <div className='text-center md:hidden text-sm mt-1 w-full'>{label}</div>
      </div>
    </Link>
  );
};
export default MainMenuItem;
