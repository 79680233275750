import { Modal } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setIsLoginModalOpen } from '../../redux/auth/auth-slice';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import ForgotPassword from '../forms/forgotPassword';
import LoginForm from '../forms/loginForm';

export default function LoginModal() {
  const dispatch = useAppDispatch();

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { isLoginModalOpen, user } = useAppSelector((state) => state.auth);

  const location = useLocation();
  const { pathname } = location;

  const handleClose = () => {
    if (pathname === 'player') {
      if (!user) return;
    } else dispatch(setIsLoginModalOpen(false));
  };

  return (
    <Modal
      onClose={handleClose}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        backdropFilter: 'blur(10px)',
      }}
      open={isLoginModalOpen || (!user && pathname === '/player')}
    >
      <div className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-4/5 max-w-3xl '>
        {showForgotPassword ? (
          <ForgotPassword />
        ) : (
          <LoginForm onForgotPassword={() => setShowForgotPassword(true)} />
        )}
      </div>
    </Modal>
  );
}
