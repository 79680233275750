import { BaseModal } from '../modals/BaseModal';
import TextPoppins from '../text/text-poppins';

type SubscriptionPopupSuccessProps = {
  open: boolean;
  handleClose: () => void;
};

export default function SubscriptionPopupSuccess({
  open,
  handleClose,
}: SubscriptionPopupSuccessProps) {
  return (
    <BaseModal
      open={open}
      handleCancel={handleClose}
      className='max-w-[980px] md:min-w-[980px] !px-0 !pt-0 !pb-3'
      buttonClassName='!top-6 !right-7 !translate-x-0 !translate-y-0 border border-white rounded-full w-[40px] h-[40px] flex items-center justify-center'
    >
      <h3 className='text-white text-center text-[30px] py-6 text-normal uppercase'>
        <TextPoppins>Subscription successfully purchased!</TextPoppins>
      </h3>
      <div className='text-center p-5 bg-white text-lg'>
        You can now access all the features of the app.
      </div>
    </BaseModal>
  );
}
