import { useNavigate } from 'react-router-dom';
import { appSignOut } from '../../redux/auth/auth-actions';
import { setIsLoginModalOpen } from '../../redux/auth/auth-slice';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import TextRoboto from '../text/text-roboto';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import { SubscribeButton } from '../header/SubscribeButton';

type AccountPopupProps = {
  onClose: () => void;
};

const AccountPopup: FC<AccountPopupProps> = ({ onClose }) => {
  const { user } = useAppSelector((state) => state.auth);
  const matches = useMediaQuery(breakpoints.sm);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const openLogin = () => {
    onClose();
    dispatch(setIsLoginModalOpen(true));
  };

  const signOut = () => {
    onClose();
    dispatch(appSignOut());
    navigate('/');
  };
  const accountClick = () => {
    onClose();
    if (!user) return openLogin();
    navigate('/account');
  };
  return (
    <div className='w-full p-4 bg-[#101010] mb:rounded-[19px] text-white text-[16px] flex flex-col items-center justify-center z-1'>
      {matches && (
        <>
          <SubscribeButton />
          <hr className='w-[45%] my-5' />
        </>
      )}
      <button onClick={accountClick}>
        <TextRoboto className='uppercase tracking-2.8px'>
          <>{t('header.account-popup-my-account')}</>
        </TextRoboto>
      </button>
      <hr className='w-[45%] my-5' />
      {user ? (
        <button onClick={signOut}>
          <TextRoboto className='uppercase tracking-2.8px'>
            <>{t('header.account-popup-log-out')}</>
          </TextRoboto>
        </button>
      ) : (
        <button onClick={openLogin}>
          <TextRoboto className='uppercase tracking-2.8px'>
            <>{t('header.account-popup-log-in')}</>
          </TextRoboto>
        </button>
      )}
    </div>
  );
};

export default AccountPopup;
