import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '../../assets/icons/HamburgerIcon';
import { MeinKontoIcon } from '../../assets/icons/MeinKontoIcon';
import { StageDanceLogo } from '../../assets/icons/StageDanceLogo';
import AccountPopup from '../popups/AccountPopup';
import SearchBar from './searchbar/SearchBar';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import { SubscribeButton } from './SubscribeButton';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

function Header({ setOpen, open }: Props) {
  const navigate = useNavigate();
  const [accountPopup, setAccountPopup] = useState(false);
  const matches = useMediaQuery(breakpoints.md);
  const { isSubscriptionValid } = useAppSelector((state) => state.auth);

  return (
    <div className='header z-[2000]'>
      <div
        className='main-logo w-[18%] md:min-w-[235px] min-w-[150px] ml-7 cursor-pointer'
        onClick={() => navigate('/')}
      >
        <StageDanceLogo />
      </div>
      <div className='w-full flex md:justify-between justify-end'>
        <div className='ml-20 flex items-center'>
          {matches && (
            <div className='cursor-pointer' onClick={() => setOpen(!open)}>
              <HamburgerIcon />
            </div>
          )}
          <SearchBar />
        </div>
        <div className='flex flex-shrink-0 items-center gap-x-12'>
          {matches && !isSubscriptionValid ? <SubscribeButton /> : null}
          <div
            onClick={() => setAccountPopup((accountPopup) => !accountPopup)}
            className='cursor-pointer'
          >
            <MeinKontoIcon />
          </div>
          {accountPopup && (
            <div
              onMouseLeave={() => setAccountPopup(false)}
              className='absolute bottom-0 translate-y-full right-0 left-0 md:right-1 md:w-[220px] md:left-auto'
            >
              <AccountPopup onClose={() => setAccountPopup(false)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Header;
