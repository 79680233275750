import TextRoboto from '../text/text-roboto';
import { useTranslation } from 'react-i18next';
import { useTakeTraining } from '../../hooks/useTakeTraining';
import { IClass } from '../../models/Video';

type Props = {
  className?: string;
  disabled?: boolean;
  icon?: string;
  onClick?: () => void;
  video: IClass;
};

export default function TakeTrainingButton({ className, disabled, video }: Props) {
  const { t } = useTranslation();
  const onTakeTrainingClick = useTakeTraining(video);
  return (
    <button
      onClick={onTakeTrainingClick}
      disabled={disabled}
      className={`${className} cursor-pointer bg-orange rounded-[16.5px] px-10 py-2`}
    >
      <TextRoboto className='uppercase text-black text-xs tracking-2.8px'>
        {t('main.banner-take-training-button')}
      </TextRoboto>
    </button>
  );
}
