import { useAppSelector } from '../../redux/reduxHooks/useAppSelector'
import Profile from './Profile'
import { BasicContent } from '../../components/BasicContent/BasicContent'
import { useTranslation } from 'react-i18next'

export default function Instructors() {
  const { t } = useTranslation()

  const { allInstructors, isLoading } = useAppSelector((state) => state.instructors)

  return (
    <BasicContent title={t('instructors.title')} loading={isLoading}>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-5 gap-y-6'>
        {allInstructors &&
          allInstructors.map((instructor) => <Profile key={instructor.PK} {...instructor} />)}
      </div>
    </BasicContent>
  )
}
