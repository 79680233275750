import { Divider } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode
  color?: string
  textColor?: string
  px?: string
};

export default function MiddleTextDivider({ children, color, textColor, px }: Props) {
  return (
    <Divider
      sx={{
        "&::before, &::after": {
          borderColor: color,
        },
      }}
    >
      <div className={`text-${textColor} px-[${px}] uppercase`}>{children}</div>
    </Divider>
  );
}
