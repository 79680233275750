import { classes } from '../../services/classes';
import { AppDispatch } from '../store';
import { setAllTypes, setFilteredClasses, setLoading } from './filter-slice';

export const fetchAllTypes = () => async (dispatch: AppDispatch) => {
  try {
    const response = await classes.getAllTypes();
    dispatch(setAllTypes(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllClasses = (filters: URLSearchParams) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await classes.getAllClasses(filters);
    dispatch(setFilteredClasses(response.data));
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoading(false));
};
