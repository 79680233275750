import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "./loaders/loader";

type Props = {
  children: ReactNode;
};
export default function ScrollToTop({ children }: Props) {
  const [load, setLoad] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setLoad(true);
    window.scrollTo(0, 0);
    setTimeout(() => setLoad(false), 300);
  }, [pathname]);

  return (
    <>
      {load ? (
        <div className="w-full h-screen flex justify-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </>
  );
}
