import pix from '../../assets/img/Instructor.png'
import { baseUrl } from '../../services/instance'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { useSplitName } from '../../hooks/useSplitName'

type Props = {
  PK: string
  SK: string
  image: {
    type: string
    file: string
  }
  name: string
  searchTerms: string
  _type: string
}
export default function Profile({ name, image, PK }: Props) {
  const { file, type } = image

  const { firstName, secondName } = useSplitName(name)

  return (
    <Link to={`/instructors/${PK}`}>
      <div
        className=' aspect-w-[412] aspect-h-[624] bg-cover bg-no-repeat bg-center'
        style={{ backgroundImage: `url(${baseUrl}/${type}/${file})` }}
      />
      <div className='flex flex-col items-end'>
        <div className='text-3xl uppercase py-2'>
          {firstName}
          {secondName && <span className='text-orange'>&nbsp;{secondName}</span>}
        </div>
      </div>
    </Link>
  )
}
