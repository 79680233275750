import { INSTANCE } from './instance';

export type AccountUpdateData = { name: string; email: string; city?: string };

export type AccountChangePassword = {
  proposedPassword: string;
  previousPassword: string;
};

export const account = {
  getAccount: () => {
    return INSTANCE.get('account');
  },
  updateAccount: (data: AccountUpdateData) => {
    return INSTANCE.put('account', data);
  },
  changePassword: (data: AccountChangePassword) => {
    return INSTANCE.post('account/changePassword', data);
  },
};
