import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CheckIcon from '../../assets/img/check.svg';
import PPIcon from '../../assets/img/pp.png';
import { useTranslation } from 'react-i18next';
import LockIcon from '../../assets/img/lock.svg';
import FormInput from '../Input/FormInput';
import classNames from 'classnames';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { fetchPlans } from '../../redux/plans/plans-actions';
import { Plan } from '../../redux/plans/plans-slice';
import { createCustomer, createStripeSubscription } from '../../redux/payment/payment-actions';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeForm } from './StripeForm';
import { buyPaypalSubscription as buyPaypalSubscriptionAction } from '../../redux/payment/payment-actions';
import { Loader } from '../loaders/loader';

type SubscriptionFormProps = {
  rounded?: boolean;
  className?: string;
};

const initialOptions = {
  'client-id': 'test',
  currency: 'EUR',
  intent: 'capture',
  'data-client-token': 'abc123xyz==',
};

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ rounded = true, className }) => {
  const { t } = useTranslation();
  const { plans } = useAppSelector((state) => state.plans);
  const { stripeId, stripeClientSecret, isLoading, isPurchaseSuccessful } = useAppSelector(
    (state) => state.payment,
  );
  const dispatch = useAppDispatch();
  const [plan, setPlan] = useState<Plan | null>(null);

  const plansMap = useMemo(() => {
    const map = new Map<'Month' | 'Year', Plan>();
    plans.forEach((plan) => map.set(plan.type, plan));
    return map;
  }, [plans]);

  useEffect(() => {
    if (plans.length === 0) {
      dispatch(fetchPlans());
      dispatch(createCustomer());
    }
  }, []);

  useEffect(() => {
    const priorityPlan = plansMap.get('Year') ?? plansMap.get('Month');
    if (!plan && priorityPlan) {
      setPlan(priorityPlan);
      // dispatch(setStripeClientSecret(''));
    }
  }, [plan, plansMap]);

  useEffect(() => {
    if (plan && stripeId) {
      dispatch(createCustomer());
    }
  }, [plan, stripeId]);

  const changePlan = (e: React.MouseEvent) => {
    e.preventDefault();
    const newPlan = plan?.type === 'Month' ? plansMap.get('Year') : plansMap.get('Month');
    if (newPlan) {
      setPlan(newPlan);
    }
  };

  const { price, totalPrice, anotherPlanPrice } = useMemo(() => {
    if (!plan) {
      return { price: 0, totalPrice: 0, anotherPlanPrice: 0 };
    }
    if (plan.type === 'Year') {
      return {
        price: (plan.price / 12).toFixed(2),
        totalPrice: plan.price,
        anotherPlanPrice: plansMap.get('Month')?.price ?? 0,
      };
    } else {
      return {
        price: plan.price,
        totalPrice: plan.price,
        anotherPlanPrice: plansMap.get('Year')?.price ?? 0,
      };
    }
  }, [plan]);

  const buyPaypalSubscription = useCallback(() => {
    if (plan) {
      dispatch(buyPaypalSubscriptionAction(plan.paypalId, plan.PK));
    }
  }, [plan]);

  const stripePromise = useMemo(() => loadStripe(`${process.env.REACT_APP_STRIPE_PK}`), []);

  return isPurchaseSuccessful ? (
    <div className='text-center p-5 bg-white text-lg'>
      {t('subscription.purchase-successfully')}
    </div>
  ) : (
    <>
      <div
        className={classNames(
          'flex flex-col md:flex-row max-w-3xl lg:max-w-none mx-auto w-[90%] lg:h-[420px] bg-white relative mb-1',
          {
            'radius-22': rounded,
          },
          className,
        )}
      >
        <Loader show={isLoading} global={true} withOverlay={true} />
        <div className='pt-8 pl-6 border-r order-2 md:order-1 border-black pr-3 pb-3 text-black md:w-[50%]'>
          <div className='mb-5 uppercase tracking-wider'>
            {t('subscription.payment-card-title')}
          </div>
          
            <Elements stripe={stripePromise}>
              <StripeForm plan={{ plan }}/>
            </Elements>
        </div>
        <div className='md:w-[50%] text-black flex order-1 md:order-2 flex-col justify-between'>
          <div
            className={classNames('py-5  text-center', {
              'lg:rounded-tr-[22px]': rounded,
            })}
            style={{ background: '#49FCD9' }}
          >
            <div className='text-28 text-poppins font-bold'>
              {t('subscription.payment-price', { price })}
            </div>
            <div>
              {plan?.type === 'Year'
                ? t('subscription.payment-annual-debiting')
                : t('subscription.payment-monthly-debiting')}
            </div>
          </div>
          <div className='w-[70%] mx-auto'>
            <div className='border-b border-black mb-5' />
            <div className='flex justify-between mb-5'>
              <div>{t('subscription.payment-total')}</div>
              <div>{t('subscription.payment-total-price', { price: totalPrice })}</div>
            </div>
            <div>
              <div className='flex mb-3'>
                <img src={CheckIcon} alt='Check' className='mr-2' />
                <div>{t('subscription.payment-advantage-1')}</div>
              </div>
              <div className='flex'>
                <img src={CheckIcon} alt='Check' className='mr-2' />
                <div>{t('subscription.payment-advantage-2')}</div>
              </div>
            </div>
          </div>
          <a
            className='text-14 mt-2 lg:mt-0 uppercase mx-auto w-80 text-center cursor-pointer p-2.5 radius-22 mb-2'
            onClick={buyPaypalSubscription}
            style={{ background: '#49FCD9' }}
          >
            <img src={PPIcon} alt='Pay pall' className='text-center mx-auto' />
          </a>
        </div>
      </div>
      <div className='text-center  p-4'>
        <a
          href='#'
          onClick={changePlan}
          className='text-14 text-slate-500 border-b border-slate-500'
        >
          {plan?.type === 'Year'
            ? t('subscription.payment-month-plan', { price: anotherPlanPrice })
            : t('subscription.payment-year-plan', { price: anotherPlanPrice })}
        </a>
      </div>
    </>
  );
};
