import UnderlineButton from '../../components/buttons/underline-button';
import TextRoboto from '../../components/text/text-roboto';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { removePlaylist } from '../../redux/playlist/playlist-actions';
import { Playlist } from '../../redux/playlist/playlist-slice';
import { useCallback } from 'react';
import pic from '../../assets/img/category bg pic.png';
import { assetsUrl } from '../../services/instance';

type Props = {
  item: Playlist;
};

export default function MyTrainingThumbnail({ item }: Props) {
  const dispatch = useAppDispatch();
  const { PK, title } = item;

  const onRemove = () => {
    dispatch(removePlaylist(item));
  };

  const getImage = useCallback((playlist: Playlist) => {
    return playlist?.relations[0]?.thumbnail
      ? `${assetsUrl}${playlist?.relations[0]?.thumbnail.file}`
      : pic;
  }, []);

  return (
    <div className='relative w-full md:w-[70vw]'>
      <Link
        to={`/mein-trainings/${PK}`}
        className='h-[19vw] overflow-hidden flex items-center rounded-3xl relative cursor-pointer'
      >
        <img className='w-full' src={getImage(item)} alt='background-image' />
        <div className='absolute top-0 left-0 w-full h-full bg-gray-12 flex justify-center items-center'>
          <div className='border-y border-aqua w-fit max-w-[70%] text-center'>
            <TextRoboto
              className={classNames('tracking-12.5% text-l lg:text-[64px]  uppercase text-white', {
                'text-l lg:text-[42px]': title.length > 20,
              })}
            >
              {title}
            </TextRoboto>
          </div>
        </div>
      </Link>
      <UnderlineButton
        onClick={onRemove}
        className='absolute top-2/4 -translate-y-1/2 !sm:tracking-[0.1rem] !tracking-1px right-5 lg:right-20'
      />
    </div>
  );
}
