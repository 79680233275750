import { useMemo } from 'react'

export const useSplitName = (name: string) => {
  return useMemo(() => {
    const splitName = name.split(' ')
    return {
      firstName: splitName[0],
      secondName: splitName.splice(1, 2).join(' '),
    }
  }, [name])
}
