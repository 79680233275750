import { Slider } from '@mui/material';
import { styled } from '@mui/system';
import { ChangeEvent } from 'react';

const StyledSlider = styled(Slider)({
  '& .MuiSlider-track': {
    color: '#DDDDDD',
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#49FCD9',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#4D4D4D',
  },
  '& .MuiSlider-valueLabel': {
    color: '#49FCD9',
    fontSize: 8,
    background: 'unset',
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: 'rgba(196, 196, 196, 0.4)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -50%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

type Props = {
  speedHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  speed: number;
  resetSpeed: () => void;
};

export default function SpeedSlider({ speedHandler, speed, resetSpeed }: Props) {
  return (
    <>
      <div className='flex items-center justify-between h-6 mb-4'>
        <p className='text-[14px] text-white'>SPEED</p>
        <p className='text-[18px] text-aqua cursor-pointer' onClick={resetSpeed}>
          RESET
        </p>
      </div>
      <StyledSlider
        min={0.1}
        max={2}
        step={0.01}
        value={speed} //@ts-ignore
        onChange={(e) => speedHandler(e)}
        defaultValue={1}
        valueLabelDisplay='on'
      />
    </>
  );
}
