import close from '../../assets/img/close.svg';
import littleLock from '../../assets/img/littleLock.svg';
import littleUser from '../../assets/img/littleUser.svg';
import downLock from '../../assets/img/downLock.svg';
import Modal from 'react-modal';
import { FC, useEffect } from 'react';
import { defaultModalStyles } from '../../utils/modal';
import { BaseModal } from '../../components/modals/BaseModal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { declinePlan } from '../../redux/plans/plans-actions';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { Loader } from '../../components/loaders/loader';
import { getAccount } from '../../redux/auth/auth-actions';

type AccountSubscriptionConfirmPopupProps = {
  open: boolean;
  handleCancel: () => void;
  onSuccess: () => void;
};

export const AccountSubscriptionConfirmPopup: FC<AccountSubscriptionConfirmPopupProps> = ({
  open,
  handleCancel,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.plans.isLoading);

  const onConfirm = async () => {
    await dispatch(declinePlan());
    dispatch(getAccount());
    onSuccess();
  };

  return (
    <BaseModal open={open} handleCancel={handleCancel} className='md:w-[500px]'>
      <Loader show={isLoading} global={true} />
      <div className='text-white uppercase'>
        <div className='mb-4 text-center text-[20px] text-white'>
          {t('account.confirm-subscription-title')}
        </div>
        <div>
          <div className='mb-5 flex'>
            <div className='mr-3'>
              <img className='w-[10px]' src={littleLock} alt='Lock' />
            </div>
            <div>
              <div>{t('account.confirm-subscription-limit-access')}</div>
              <div className='text-[#646464]'>
                {t('account.confirm-subscription-limit-access-desc')}
              </div>
            </div>
          </div>
          <div className='mb-5 flex'>
            <div className='mr-3'>
              <img className='w-[20px]' src={littleUser} alt='User' />
            </div>
            <div>
              <div>{t('account.confirm-subscription-limit-reverted')}</div>
              <div className='text-[#646464]'>
                {t('account.confirm-subscription-limit-reverted-desc')}
              </div>
            </div>
          </div>
          <div className='mb-5 flex'>
            <div className='mr-3'>
              <img className='w-[20px]' src={downLock} alt='Down Lock' />
            </div>
            <div>
              <div>{t('account.confirm-subscription-limit-downloaded')}</div>
              <div className='text-[#646464]'>
                {t('account.confirm-subscription-limit-downloaded-desc')}
              </div>
            </div>
          </div>
          <div className='text-center'>
            <button
              onClick={onConfirm}
              className='mb-5 border border-[#DDDDDD] w-[100%] py-3 rounded-[40px] uppercase text-[14px] text-[#DDDDDD]'
            >
              {t('account.confirm-subscription-limit-button-cancel')}
            </button>
            <button className='border-b border-slate-500 uppercase text-[#646464]'>
              {t('account.confirm-subscription-limit-button-keep')}
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
