import { ReactNode } from "react";
import TextRoboto from "../text/text-roboto";

type Props = {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  icon?: string;
  onClick?: () => void;
};

export default function SecondaryButton({
  children,
  className,
  disabled,
  onClick,
}: Props) {
  return (
    <button
      onTouchStart={onClick}
      onClick={onClick}
      disabled={disabled}
      className={`${className} bg-aqua rounded-[20px] text-xs text-black uppercase tracking-2.8px`}
    >
      <TextRoboto>{children}</TextRoboto>
    </button>
  );
}
