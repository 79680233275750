import { useCallback, useState } from 'react';
import { deserializeFilters, getDefaultFilters, serializeFilters } from '../utils/filters';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_FILTER_VALUE } from '../components/Filter/filter';

export type FilterTypeRange = [number, number];

export type FiltersType = {
  type: Set<string>;
  sort: string;
  level: Set<string>;
  instructor: Set<string>;
  category: Set<string>;
  duration: FilterTypeRange;
};

export type FILTER_KEYS_SETS = 'type' | 'level' | 'instructor' | 'category';
export type FILTER_KEYS_RANGE = 'duration';

export type FILTER_KEYS = 'type' | 'level' | 'instructor' | 'category' | 'duration';

export const useFilterState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtered, setFiltered] = useState<FiltersType>(() => {
    return deserializeFilters(searchParams);
  });

  const clear = useCallback(() => {
    setFiltered(getDefaultFilters());
  }, []);

  const apply = useCallback(() => {
    const filterUrlParams = serializeFilters(filtered);
    setSearchParams(filterUrlParams);
    return filterUrlParams;
  }, [filtered]);

  const handleFilterRangeChange = useCallback(
    (filterName: FILTER_KEYS_RANGE, value: FilterTypeRange) => {
      setFiltered((current) => {
        current[filterName] = value;
        return { ...current };
      });
    },
    [],
  );

  const handleFilterChangeWithDefaultValue = useCallback(
    (filterKey: FILTER_KEYS_SETS, levelValue: string, allItems: Record<string, string>) => {
      setFiltered((current) => {
        const filter = current[filterKey];

        if (levelValue === DEFAULT_FILTER_VALUE) {
          filter.clear();
          filter.add(DEFAULT_FILTER_VALUE);
          return {
            ...current,
            [filterKey]: filter,
          };
        }

        if (filter.has(levelValue)) {
          filter.delete(levelValue);
        } else {
          filter.delete(DEFAULT_FILTER_VALUE);
          filter.add(levelValue);
        }

        if (filter.size === 0 || filter.size === Object.keys(allItems).length - 1) {
          filter.clear();
          filter.add(DEFAULT_FILTER_VALUE);
        }

        return {
          ...current,
          [filterKey]: filter,
        };
      });
    },
    [],
  );

  const handleFilterChange = useCallback((filterKey: FILTER_KEYS_SETS, value: string) => {
    setFiltered((current) => {
      const filter = current[filterKey];
      if (filter.has(value)) {
        filter.delete(value);
      } else {
        filter.add(value);
      }
      return {
        ...current,
      };
    });
  }, []);

  const handleSortFilter = (sortBy: string) => {
    setFiltered((current) => ({
      ...filtered,
      sort: current.sort === sortBy ? '' : sortBy,
    }));
  };

  return {
    apply,
    clear,
    filtered,
    handleFilterChange,
    handleFilterChangeWithDefaultValue,
    handleFilterRangeChange,
    handleSortFilter,
  };
};
