import { FC, useEffect } from 'react';
import SecondaryButton from '../../components/buttons/secondary-button';
import TextRoboto from '../../components/text/text-roboto';
import MyTrainingThumbnail from '../MainTrainingList/my-training-thumbnail';
import pic from '../../assets/img/category bg pic.png';
import { Loader } from '../../components/loaders/loader';
import ScrollToTop from '../../components/scroll-to-top';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import VideosStack from '../../videos/ViedeosStack/VideosStack';
import { fetchLikedClass } from '../../redux/likedClass/liked-class-actions';
import { BasicContent } from '../../components/BasicContent/BasicContent';

type MyClassesProps = {};

export const MyClasses: FC<MyClassesProps> = () => {
  const { t } = useTranslation();
  const { likedClasses, isLoading } = useAppSelector((state) => state.likedClass);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLikedClass());
  }, []);

  return (
    <BasicContent title={t('sections.my-classes')} loading={isLoading}>
      <VideosStack videos={likedClasses} />
    </BasicContent>
  );
};
