import { INSTANCE } from './instance'

export const media = {
  getVideoLink: (classId: string) => {
    return INSTANCE.get(`item/${classId}/media/video`)
  },
  getOriginalLink: (classId: string) => {
    return INSTANCE.get(`item/${classId}/media/original`)
  },
}
