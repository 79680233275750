import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPlayingPreviewVideoClassId } from '../redux/videos/videoSlice';

export const useResetPlayingPreviewVideo = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPlayingPreviewVideoClassId(undefined));
  }, [location.pathname]);
};
