import React, { useCallback, useEffect, useMemo, useState } from 'react';
import pic from '../../assets/img/category bg pic.png';
import arrowLeft from '../../assets/img/arrowLeft.svg';
import arrowRight from '../../assets/img/arrowRight.svg';
import activeStyle from '../../assets/img/activeStyle.svg';
import PrimaryButton from '../../components/buttons/primary-button';
import { NavigationControls } from './NavigationControls';
import useMediaQuery, { breakpoints } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { baseUrl } from '../../services/instance';

const Styles = ({ nextStep, currentStep, onSetStep, previousStep }: any) => {
  const [activeStyles, setActiveStyles] = useState<string[]>([]);

  const handleChange = (id: string) => {
    let arr: string[] = [];
    if (activeStyles.includes(id)) {
      arr = activeStyles.filter((item) => item !== id);
      setActiveStyles(arr);
    } else {
      arr.push(id);
      setActiveStyles((activeStyles) => [...activeStyles, ...arr]);
    }
  };
  const handleChangeStep = useCallback(() => {
    if (activeStyles.length > 0) {
      nextStep();
    }
  }, [activeStyles]);
  useEffect(() => {
    onSetStep(currentStep);
  }, [onSetStep, currentStep]);
  useEffect(() => {
    return () => setActiveStyles([]);
  }, []);

  const match = useMediaQuery(breakpoints.lg);
  const { t } = useTranslation();
  const { categories } = useAppSelector((state) => state.categories);

  const styles = useMemo<
    Array<{
      id: string;
      img: string;
      title: string;
    }>
  >(
    () =>
      categories.map((item) => ({
        title: item.title,
        img: `${baseUrl}/${item.image.type}/${item.image.file}`,
        id: item.PK,
      })),
    [],
  );

  return (
    <div className='bg-[#1C1C1C] lg:h-[calc(100vh-63px)] text-white w-full flex flex-col justify-center items-center relative'>
      {match && <NavigationControls previousStep={previousStep} nextStep={handleChangeStep} />}
      <div className='text-center text-[40px] uppercase mb-10'>{t('sign-up.styles-title')}</div>
      <div className='grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5'>
        {styles.map((item) => (
          <div
            key={item.id}
            className={`relative cursor-pointer ${
              activeStyles.includes(item.id) ? 'dance-style-active' : ''
            }`}
            onClick={() => handleChange(item.id)}
          >
            <div
              className=' aspect-w-[345] w-full aspect-h-[194] bg-cover bg-center bg-no-repeat'
              style={{ backgroundImage: `url(${item.img})` }}
            />
            <div className='absolute top-0 left-0 rounded-md flex items-center justify-center bg-gray-84 w-full h-full '>
              <p className='uppercase text-4xl'>{item.title}</p>
            </div>
            {activeStyles.includes(item.id) && (
              <img className='absolute bottom-2 right-2 z-10' src={activeStyle} alt='Active' />
            )}
          </div>
        ))}
      </div>

      {!match && (
        <div className='mt-5 w-full'>
          <NavigationControls previousStep={previousStep} nextStep={handleChangeStep} />
        </div>
      )}
    </div>
  );
};

export default Styles;
