import TextPoppins from "../text/text-poppins";

type Props = {
    onClick: () => void
    pic: string
    level: string
}
export default function DanceLevelCard({onClick, pic, level }: Props) {
    return (
        <div onClick={onClick} className=" cursor-pointer border rounded-3xl border-slate-500 duration-150 hover:border-[#49FCD9] hover:scale-110">
                    <img className="rounded-3xl object-cover" src={pic} alt={level + "_img"} />
                    <div className="rounded-3xl flex items-center justify-center">
                        <TextPoppins className="uppercase text-lg tracking-2.8px py-10">{level}</TextPoppins>
                    </div>
                </div>
    )
}