import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Plan = {
  PK: string;
  SK: string;
  duration: number;
  type: 'Year' | 'Month';
  GSI1: string;
  paypalId: string;
  stripeId: string;
  name: string;
  price: number;
  created: string;
  status: boolean;
};

interface IInitialState {
  isLoading: boolean;
  plans: Array<Plan>;
}

const initialState: IInitialState = {
  isLoading: false,
  plans: [],
};

const plansSlice = createSlice({
  initialState,
  name: 'playlist',
  reducers: {
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPlans(state: IInitialState, action: PayloadAction<Plan[]>) {
      state.plans = action.payload;
    },
  },
});

export const { setIsLoading, setPlans } = plansSlice.actions;

export const plansReducer = plansSlice.reducer;
