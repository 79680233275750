import { useState } from 'react';
import ForgotPassword from '../../components/forms/forgotPassword';
import LoginForm from '../../components/forms/loginForm';

export default function Login() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <div className='w-full h-screen flex justify-center pt-[70px]'>
      <div className='w-4/5 max-w-3xl '>
        {showForgotPassword ? (
          <ForgotPassword />
        ) : (
          <LoginForm onForgotPassword={() => setShowForgotPassword(true)} />
        )}
      </div>
    </div>
  );
}
