import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Cross from '../../assets/img/cross.svg';
import FilterButton from '../buttons/filter-button';
import FilterStaticButton from '../buttons/filter-static-button';
import TextRoboto from '../text/text-roboto';
import FilterSlider from './filter-slider';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { setIsFilterOpen, setSelectedCategories } from '../../redux/filter/filter-slice';
import { fetchAllClasses } from '../../redux/filter/filter-actions';
import { getInstructorImgUrl } from '../../utils/images';
import classNames from 'classnames';
import { FilterTypeRange, useFilterState } from '../../hooks/useFilterState';
import { serializeFilters } from '../../utils/filters';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDebounceEffect } from '../../hooks/useDebounceEffect';
import ButtonFilterWrapper from './ButtonFilterWrapper';
import arrowRight from '../../assets/img/arrowRight.svg';

type FilterValueItem = {
  label: string;
};

export const DEFAULT_FILTER_VALUE = '__DEFAULT__';

const LEVELS_FILTER = {
  ALLE_LEVELS: DEFAULT_FILTER_VALUE,
  BEGINNER: '1',
  INTERMEDIATE: '2',
  ADVANCED: '3',
};

const CLASS_TYPE_FILTER = {
  WARM_UP: 'Warm up',
  CHOREOGRAPHY: 'Choreografie',
  COOL_DOWN: 'Cooldown',
  STRETCHING: 'Stretching',
  FOLLOW_ALONE: 'Follow alone',
};

export const SORTS = {
  NEWEST: 'newest',
  OLDEST: 'oldest',
  EASYEST: 'easyest',
  HARDEST: 'hardest',
};

export const getValues = (t: (key: string) => string) => {
  const levels = new Map<string, FilterValueItem>([
    [
      LEVELS_FILTER.ALLE_LEVELS,
      {
        label: t('filters.value-level-all-levels'),
      },
    ],
    [
      LEVELS_FILTER.BEGINNER,
      {
        label: t('filters.value-level-beginner'),
      },
    ],
    [
      LEVELS_FILTER.INTERMEDIATE,
      {
        label: t('filters.value-level-intermediate'),
      },
    ],
    [
      LEVELS_FILTER.ADVANCED,
      {
        label: t('filters.value-level-advanced'),
      },
    ],
  ]);
  const classTypes = new Map<string, FilterValueItem>([
    [
      CLASS_TYPE_FILTER.WARM_UP,
      {
        label: i18next.t('filters.value-level-class-type-warm-up'),
      },
    ],
    [
      CLASS_TYPE_FILTER.CHOREOGRAPHY,
      {
        label: i18next.t('filters.value-level-class-type-choreography'),
      },
    ],
    [
      CLASS_TYPE_FILTER.COOL_DOWN,
      {
        label: i18next.t('filters.value-level-class-type-cool-down'),
      },
    ],
    [
      CLASS_TYPE_FILTER.STRETCHING,
      {
        label: i18next.t('filters.value-level-class-type-stretching'),
      },
    ],
    [
      CLASS_TYPE_FILTER.FOLLOW_ALONE,
      {
        label: i18next.t('filters.value-level-class-type-follow-alone'),
      },
    ],
  ]);
  const sortBy = new Map<string, FilterValueItem>([
    [
      SORTS.NEWEST,
      {
        label: i18next.t('filters.value-level-sort-newest'),
      },
    ],
    [
      SORTS.OLDEST,
      {
        label: i18next.t('filters.value-level-sort-oldest'),
      },
    ],
    [
      SORTS.EASYEST,
      {
        label: i18next.t('filters.value-level-sort-easiest'),
      },
    ],
    [
      SORTS.HARDEST,
      {
        label: i18next.t('filters.value-level-sort-hardest'),
      },
    ],
  ]);

  return {
    levels,
    classTypes,
    sortBy,
  };
};

export default function Filter() {
  const dispatch = useAppDispatch();
  const { isFilterOpen, allTypes } = useAppSelector((state) => state.filter);
  const { allInstructors } = useAppSelector((state) => state.instructors);
  const { categories } = useAppSelector((state) => state.categories);
  const { t } = useTranslation();

  const { levels, sortBy } = useMemo(() => getValues(t), []);

  const openFilter = () => {
    dispatch(setIsFilterOpen(true));
  };
  const closeFilter = () => dispatch(setIsFilterOpen(false));

  const [showTrani, setShowTrani] = useState(false);

  const handleTrani = () => {
    setShowTrani(!showTrani);
  };

  const {
    clear,
    filtered,
    handleFilterChange,
    handleFilterChangeWithDefaultValue,
    handleSortFilter,
    handleFilterRangeChange,
    apply,
  } = useFilterState();

  const categoryPks = Array.from(filtered.category.values());
  useEffect(() => {
    dispatch(setSelectedCategories(categoryPks));
    return () => {
      dispatch(setSelectedCategories([]));
    };
  }, [JSON.stringify(categoryPks)]);

  const handleInstructorFilter = useCallback((value: string) => {
    handleFilterChange('instructor', value);
  }, []);

  const handleClassFilter = useCallback((value: string) => {
    handleFilterChange('type', value);
  }, []);

  const handleCategoryFilter = useCallback((value: string) => {
    handleFilterChange('category', value);
  }, []);

  const handleLevelFilter = useCallback((value: string) => {
    handleFilterChangeWithDefaultValue('level', value, LEVELS_FILTER);
  }, []);

  const handleRadio = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleInstructorFilter(value);
  }, []);

  const handleClassLengthChange = useCallback((value: FilterTypeRange) => {
    handleFilterRangeChange('duration', value);
  }, []);

  useEffect(() => {
    const filterUrlParams = serializeFilters(filtered);
    dispatch(fetchAllClasses(filterUrlParams));
  }, []);

  useDebounceEffect(
    () => {
      const filterUrlParams = apply();
      dispatch(fetchAllClasses(filterUrlParams));
    },
    [filtered],
    1000,
  );

  useEffect(() => {
    return () => {
      closeFilter();
    };
  }, []);

  return (
    <div className='py-8 shadow-bottom shadow-md mb-10'>
      <div className='wrapper'>
        <div
          className={
            'overflow-hidden relative transition-all h-full duration-1000 ' +
            (isFilterOpen ? 'max-h-[900px]' : 'max-h-0')
          }
        >
          <div className='flex flex-col gap-y-4 px-4'>
            <div
              onClick={closeFilter}
              className='absolute w-5 md:w-[34px] right-5  top-0 md:top-[14px] cursor-pointer'
            >
              <img src={Cross} alt='Cross' className=' w-full' />
            </div>
            <div className={`flex flex-col gap-y-4`}>
              <div className='flex gap-x-4 w-full pt-10'>
                <div className='class-type hidden lg:flex lg:flex-col items-end justify-around w-[98px]'>
                  <div className='text-14 text-category'>
                    {t('filters.filter-class-type-title')}
                  </div>
                  <p className='text-14 text-category '>{t('filters.filter-category-title')}</p>
                  <p className='text-14 text-category mb-0'>{t('filters.filter-sort-title')}</p>
                </div>
                <div className='sort lg:flex lg:flex-col lg:gap-y-4 lg:gap-x-4 w-full max-w-full '>
                  <ButtonFilterWrapper title={t('filters.filter-class-type-title')}>
                    {allTypes?.map((item) => (
                      <FilterButton
                        value={item.PK}
                        title={item.name}
                        key={item.PK}
                        onClick={handleClassFilter}
                        selected={filtered.type.has(item.PK)}
                      />
                    ))}
                  </ButtonFilterWrapper>
                  <ButtonFilterWrapper title={t('filters.filter-category-title')}>
                    {categories.map((item) => (
                      <FilterButton
                        value={item.PK}
                        title={item.title}
                        key={item.PK}
                        onClick={handleCategoryFilter}
                        selected={filtered.category.has(item.PK)}
                      />
                    ))}
                  </ButtonFilterWrapper>
                  <ButtonFilterWrapper title={t('filters.filter-sort-title')}>
                    {Array.from(sortBy.entries()).map(([value, item]) => (
                      <FilterButton
                        value={value}
                        title={item.label}
                        key={value}
                        onClick={handleSortFilter}
                        selected={filtered.sort === value}
                      />
                    ))}
                  </ButtonFilterWrapper>
                </div>
              </div>

              <div className='mt-12'>
                <div className='flex w-[100%] lg:gap-x-4 flex-wrap md:flex-nowrap md:items-center'>
                  <TextRoboto className='text-[14px] w-full md:w-auto flex-shrink-0 tracking-2.8px'>
                    {t('filters.filter-class-length-title')}
                  </TextRoboto>
                  <div className='w-[90%] lg:w-[600px] md:w-[60%] ml-[3%]'>
                    <FilterSlider value={filtered.duration} onChange={handleClassLengthChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {allInstructors && (
            <div className=''>
              <div
                onClick={handleTrani}
                className='border filter-shadow cursor-pointer flex justify-end pr-4 mt-9 py-1 border-[#2d2d2d] items-center'
              >
                <span className='mr-4 p-0  '>
                  <img
                    src={arrowRight}
                    className={classNames('w-3 transition-transform duration-300 relative', {
                      'rotate-90': showTrani,
                    })}
                    alt='open'
                  />
                </span>{' '}
                <TextRoboto className='text-[14px] tracking-2.8px uppercase text-right md:text-left'>
                  {t('filters.filter-instructors-title')}
                </TextRoboto>
              </div>
              <div
                className={`overflow-hidden relative transition-all h-full duration-700 mb-8 ${
                  showTrani ? 'max-h-[160px]' : 'max-h-0'
                }`}
              >
                <div className='flex flex-wrap  justify-between mt-4 gap-y-1 pr-4 px-6'>
                  {allInstructors.map((instructor) => (
                    <div
                      key={instructor.PK}
                      className='flex justify-between w-full lg:w-30 items-center'
                    >
                      <div className='flex gap-x-2 items-center'>
                        <div
                          className='w-11 h-11 bg-cover bg-center bg-no-repeat border border-[#2d2d2d] rounded-md'
                          style={{ backgroundImage: `url(${getInstructorImgUrl(instructor)})` }}
                        ></div>
                        <TextRoboto className='text-white text-[18px]'>
                          {instructor.name}
                        </TextRoboto>
                      </div>
                      <div className=''>
                        <label
                          className={classNames(
                            `w-9 h-9 block cursor-pointer rounded-full bg-[rgba(255, 255, 255, .14)] border-2 border-white`,
                            {
                              'bg-aqua': filtered.instructor.has(instructor.PK),
                            },
                          )}
                        >
                          <input
                            className='hidden'
                            type='checkbox'
                            value={instructor.PK}
                            onChange={handleRadio}
                          />
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={'lg:flex justify-between'}>
          <div className='flex flex-row flex-wrap lg:flex-nowrap gap-x-5 justify-between md:justify-start items-center uppercase px-4'>
            {Array.from(levels.entries()).map(([value, item]) => (
              <FilterButton
                title={item.label}
                key={value}
                onClick={handleLevelFilter}
                value={value}
                selected={filtered.level.has(value)}
              />
            ))}
          </div>
          <div className='w-full mt-5 lg:mt-0 px-4 lg:px-0 lg:w-[20%] flex lg:justify-end gap-x-10'>
            {isFilterOpen ? (
              <FilterStaticButton
                title={t('filters.button-filter-close')}
                theme='secondary'
                onClick={closeFilter}
              />
            ) : (
              <FilterStaticButton
                title={t('filters.button-filter')}
                theme='secondary'
                onClick={openFilter}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
