import classNames from 'classnames';
import { FC } from 'react';

type LoaderProps = {
  withOverlay?: boolean;
  global?: boolean;
  show?: boolean;
};

export const Loader: FC<LoaderProps> = ({ withOverlay = false, show = true, global = false }) => {
  let classes = 'loaderWrapper';
  if (withOverlay) {
    classes += '  absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]';
  }
  if (global) {
    classes +=
      ' fixed top-1/2 left-1/2 w-full h-full flex items-center justify-center pointer-events-none opacity-0 z-50 translate-x-[-50%] translate-y-[-50%] transition-opacity';
  }

  return withOverlay ? (
    <div
      className={`transition-opacity left-0 top-0 absolute h-full w-full ${
        show ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
      }`}
    >
      <div className={classes}>
        <div className='loader'></div>
      </div>
    </div>
  ) : (
    <div
      className={classNames(classes, {
        'pointer-events-auto opacity-100': show,
      })}
    >
      <div className='loader'></div>
    </div>
  );
};
