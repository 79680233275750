import { FC, useCallback, useMemo, useState } from 'react';
import DropDown, { DropDownOption } from '../../components/DropDown/DropDown';
import { useAppSelector } from '../../redux/reduxHooks/useAppSelector';
import { Formik } from 'formik';
import FormInputField from '../../components/Input/FormInputField';
import * as Yup from 'yup';
import { Loader } from '../../components/loaders/loader';
import { useAppDispatch } from '../../redux/reduxHooks/useAppDispatch';
import { updateUserInfo } from '../../redux/auth/auth-actions';
import { ResetFormik } from '../../components/ResetFormik/ResetFormik';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(1),
  email: Yup.string().email().required(),
});

type AccountFormProps = {};

type UserFormValues = {
  name: string;
  email: string;
};

export const AccountForm: FC<AccountFormProps> = () => {
  const { t } = useTranslation();
  const [isFormEdit, setFormEdit] = useState(false);
  const { user, updateUserLoading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const initialValues = useMemo<UserFormValues | null>(
    () =>
      user
        ? {
            name: user.name,
            email: user.email,
          }
        : null,
    [user],
  );

  const onSubmit = useCallback(async (data: UserFormValues) => {
    await dispatch(
      updateUserInfo({
        ...data,
      }),
    );
    setFormEdit(false);
  }, []);

  return initialValues ? (
    <>
      <div
        className={classNames('z-40 pointer-events-none fixed left-0 right-0 top-0 bottom-0', {
          'bg-black/75': isFormEdit,
        })}
      ></div>
      <div className={`px-3 py-5 z-50 relative text-white  ${isFormEdit ? 'black-900' : null}`}>
        <Loader withOverlay={true} show={updateUserLoading} />

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => {
            return (
              <>
                <ResetFormik needToReset={!isFormEdit} />
                <form onSubmit={handleSubmit}>
                  <div className='md:flex md:justify-between text-center  uppercase mb-5'>
                    <div className='text-l'>{t('account.info-form-title')}</div>
                    <div className='flex w-full md:w-fit justify-end'>
                      {isFormEdit ? (
                        <>
                          <button
                            className='uppercase mr-3 hover:text-orange '
                            type='button'
                            onClick={() => setFormEdit(false)}
                          >
                            {t('account.info-form-button-cancel')}
                          </button>
                          <button className='uppercase hover:text-aqua ' type='submit'>
                            {t('account.info-form-button-save')}
                          </button>
                        </>
                      ) : (
                        <button
                          className='uppercase hover:text-aqua '
                          type='button'
                          onClick={() => setFormEdit(true)}
                        >
                          {t('account.info-form-button-edit')}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='mb-5'>
                    <FormInputField
                      disabled={!isFormEdit}
                      name='name'
                      label={t('account.info-form-control-name')}
                      type='text'
                      placeholder={user?.name}
                    />
                  </div>
                  <div className='mb-5'>
                    <FormInputField
                      disabled={!isFormEdit}
                      name='email'
                      label={t('account.info-form-control-email')}
                      type='email'
                      placeholder={user?.email}
                    />
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  ) : null;
};
