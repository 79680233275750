import { FC } from 'react';
import checkSuccessIcon from '../../assets/img/checkSuccesIcon.svg';
import { BaseModal } from '../../components/modals/BaseModal';
import { useTranslation } from 'react-i18next';

type AccountSubscriptionSuccessPopupProps = {
  open: boolean;
  handleCancel: () => void;
};

export const AccountSubscriptionSuccessPopup: FC<AccountSubscriptionSuccessPopupProps> = ({
  open,
  handleCancel,
}) => {
  const { t } = useTranslation();
  return (
    <BaseModal open={open} handleCancel={handleCancel} className='md:w-[500px]'>
      <div className='text-white  uppercase'>
        <div className='mb-10 flex justify-center'>
          <img className='mr-3' src={checkSuccessIcon} alt='Success' />
          <div>{t('account.subscription-success-popup-title')}</div>
        </div>
        <button
          onClick={handleCancel}
          className='mb-5 border border-[#DDDDDD] w-[100%] py-3 rounded-[40px] uppercase text-[14px] text-[#DDDDDD]'
        >
          {t('account.subscription-success-popup-cancel')}
        </button>
      </div>
    </BaseModal>
  );
};
