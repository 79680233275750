import { AppDispatch } from '../store';
import { setIsLoading, setSearchResult } from './search-slice';
import { search as searchApi } from '../../services/search';

export const search = (query: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await searchApi.search(query);
    dispatch(setSearchResult(response.data));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};
