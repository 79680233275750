import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
  initialized: boolean;
  isTouchScreen: boolean;
  subscriptionPopupOpen: boolean;
}
const initialState: IInitialState = {
  initialized: false,
  isTouchScreen: false,
  subscriptionPopupOpen: false,
};
const appSlice = createSlice({
  initialState,
  name: 'filter',
  reducers: {
    setInitialized(state: IInitialState, action: PayloadAction<boolean>) {
      state.initialized = action.payload;
    },
    setIsTouchScreen(state: IInitialState, action: PayloadAction<boolean>) {
      state.isTouchScreen = action.payload;
    },
    setSubscriptionPopupOpen(state: IInitialState, action: PayloadAction<boolean>) {
      state.subscriptionPopupOpen = action.payload;
    },
  },
});

export const { setInitialized, setIsTouchScreen, setSubscriptionPopupOpen } = appSlice.actions;
export const appReducer = appSlice.reducer;
