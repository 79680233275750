import { getAccount } from './../auth/auth-actions';
import { checkForAuthUser } from '../auth/auth-actions';
import { fetchAllCategories } from '../categories/categories-actions';
import { fetchAllTypes } from '../filter/filter-actions';
import { fetchAllInstructors } from '../instructors/instructors-actions';
import { AppDispatch } from '../store';
import { setInitialized } from './app-slice';
import { fetchPlaylists } from '../playlist/playlist-actions';
import { fetchLikedClass } from '../likedClass/liked-class-actions';

export const loadApp = () => async (dispatch: AppDispatch) => {
  try {
    Promise.all([
      dispatch(checkForAuthUser()),
      dispatch(fetchAllCategories()),
      dispatch(fetchAllTypes()),
      dispatch(fetchAllInstructors()),
    ]).then(([user]) => {
      if (user) {
        dispatch(getAccount());
        dispatch(fetchPlaylists());
        dispatch(fetchLikedClass());
      }
      dispatch(setInitialized(true));
    });
  } catch (error) {
    console.log(error);
  }
};
