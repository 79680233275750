import { AppDispatch } from '../store';
import {
  Playlist,
  setCurrentPlaylist,
  setIsLoading,
  setPlaylistClasses,
  setPlaylists,
  addClassToPlaylist as addClassToPlaylistSlice,
  removeClassFromPlaylist as removeClassFromPlaylistSlice,
  addPlaylist,
  removePlaylist as removePlaylistSlice,
} from './playlist-slice';
import { playlists } from '../../services/playlists';

export const fetchPlaylists = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await playlists.getUserPlaylists();
    dispatch(setPlaylists(response.data?.filter((item: Playlist) => item.PK) ?? []));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const createPlaylist = (title: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    await playlists.createPlaylist(title);
    const response = await playlists.getUserPlaylists();
    dispatch(setPlaylists(response.data));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const addClassToPlaylist =
  (playlistId: string, classId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(addClassToPlaylistSlice({ playlistId, classId }));
      await playlists.addClassToPlaylist(playlistId, classId);
      const response = await playlists.getUserPlaylists();
      dispatch(setPlaylists(response.data));
    } catch (e) {
      dispatch(removeClassFromPlaylistSlice({ playlistId, classId }));
      console.log(e);
    }
  };

export const removeClassFromPlaylist =
  (playlistId: string, classId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(removeClassFromPlaylistSlice({ playlistId, classId }));
      await playlists.removeClassFormPlaylist(playlistId, classId);
      const response = await playlists.getUserPlaylists();
      dispatch(setPlaylists(response.data));
    } catch (e) {
      dispatch(addClassToPlaylistSlice({ playlistId, classId }));
      console.log(e);
    }
  };

export const getPlaylist = (playlistId: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));
  try {
    const response = await playlists.getPlaylist(playlistId);
    const playlistClassResponse = await playlists.getPlaylistClasses(playlistId);
    dispatch(setCurrentPlaylist(response.data));
    dispatch(setPlaylistClasses(playlistClassResponse.data));
  } catch (e) {
    console.log(e);
  }
  dispatch(setIsLoading(false));
};

export const removePlaylist = (playlist: Playlist) => async (dispatch: AppDispatch) => {
  try {
    dispatch(removePlaylistSlice(playlist.PK));
    await playlists.removePlaylist(playlist.PK);
    const response = await playlists.getUserPlaylists();
    dispatch(setPlaylists(response.data));
  } catch (e) {
    dispatch(addPlaylist(playlist));
    console.log(e);
  }
};
