import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClass } from '../../models/Video';

interface IInitialState {
  isLoading: boolean;
  likedClassesIds: Array<string>;
  likedClasses: Array<IClass>;
}

const initialState: IInitialState = {
  isLoading: false,
  likedClassesIds: [],
  likedClasses: [],
};

const likedClassSlice = createSlice({
  initialState,
  name: 'likedClass',
  reducers: {
    setLikedClasses(state: IInitialState, action: PayloadAction<Array<IClass>>) {
      state.likedClassesIds = action.payload.map((item) => item.PK);
      state.likedClasses = action.payload;
    },
    addLikedClass(state: IInitialState, action: PayloadAction<string>) {
      state.likedClassesIds = [...state.likedClassesIds, action.payload];
    },
    removeLikedClass(state: IInitialState, action: PayloadAction<string>) {
      state.likedClassesIds = state.likedClassesIds.filter((item) => item !== action.payload);
      state.likedClasses = state.likedClasses.filter((item) => item.PK !== action.payload);
    },
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { setLikedClasses, removeLikedClass, addLikedClass, setIsLoading } =
  likedClassSlice.actions;

export const likedClassReducer = likedClassSlice.reducer;
