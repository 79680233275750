import { INSTANCE } from './instance'

export const classes = {
  getAllClasses: async (filters = {}) => {
    return INSTANCE.get('class', {
      params: filters,
    })
  },
  getSingleClass: async (classId: string) => {
    return INSTANCE.get(`class/${classId}`)
  },
  getAllTypes: async () => {
    return INSTANCE.get('type')
  },
}
