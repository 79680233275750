import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClass } from '../../models/Video';

interface IInitialState {
  isLoading: boolean;
  showSettings: boolean;
  showViewSection: boolean;
  showSpeedPopup: boolean;
  progress: number;
  isLiked: boolean;
  speed: number;
  isMute: boolean;
  isPlay: boolean;
  isLoop: boolean;
  isMirror: boolean;
  isBackViewMode: boolean;
  showControls: boolean;
  rangesValue: {
    startValue: number;
    endValue: number;
  };
  progressSliderValues: {
    startValue: number;
    progressValue: number;
    endValue: number;
  };
  screenClicked: boolean;
  currentClass: null | IClass;
  link: string;
}
const initialState: IInitialState = {
  isLoading: false,
  showSettings: false,
  showViewSection: false,
  showSpeedPopup: false,
  progress: 1,
  isLiked: false,
  speed: 1,
  isMute: false,
  isPlay: false,
  isLoop: false,
  isMirror: false,
  isBackViewMode: false,
  showControls: false,
  rangesValue: {
    startValue: 0,
    endValue: 0,
  },
  progressSliderValues: {
    startValue: 0,
    progressValue: 0,
    endValue: 0,
  },
  screenClicked: false,
  currentClass: null,
  link: '',
};

const playerSlice = createSlice({
  initialState,
  name: 'player',
  reducers: {
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setShowSettings(state: IInitialState, action: PayloadAction<boolean>) {
      state.showSettings = action.payload;
    },
    setShowViewSection(state: IInitialState, action: PayloadAction<boolean>) {
      state.showViewSection = action.payload;
    },
    setShowSpeedPopup(state: IInitialState, action: PayloadAction<boolean>) {
      state.showSpeedPopup = action.payload;
    },
    setProgress(state: IInitialState, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setIsLiked(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLiked = action.payload;
    },
    setSpeed(state: IInitialState, action: PayloadAction<number>) {
      state.speed = action.payload;
    },
    setIsMute(state: IInitialState, action: PayloadAction<boolean>) {
      state.isMute = action.payload;
    },
    setIsPlay(state: IInitialState, action: PayloadAction<boolean>) {
      state.isPlay = action.payload;
    },
    setIsLoop(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoop = action.payload;
    },
    setIsMirror(state: IInitialState, action: PayloadAction<boolean>) {
      state.isMirror = action.payload;
    },
    setIsBackViewMode(state: IInitialState, action: PayloadAction<boolean>) {
      state.isBackViewMode = action.payload;
    },
    setShowControls(state: IInitialState, action: PayloadAction<boolean>) {
      state.showControls = action.payload;
    },
    setRangesValue(
      state: IInitialState,
      action: PayloadAction<{
        startValue: number;
        endValue: number;
      }>,
    ) {
      state.rangesValue = action.payload;
    },
    setProgressSliderValues(
      state: IInitialState,
      action: PayloadAction<{
        startValue: number;
        progressValue: number;
        endValue: number;
      }>,
    ) {
      state.progressSliderValues = action.payload;
    },
    setScreenClicked(state: IInitialState, action: PayloadAction<boolean>) {
      state.screenClicked = action.payload;
    },
    setCurrentClass(state: IInitialState, action: PayloadAction<IClass | null>) {
      state.currentClass = action.payload;
    },
    setLink(state: IInitialState, action: PayloadAction<string>) {
      state.link = action.payload;
    },
    resetToDefault(state: IInitialState) {
      return { ...initialState };
    },
  },
});

export const {
  setIsBackViewMode,
  setIsLiked,
  setIsLoading,
  setIsLoop,
  setIsMirror,
  setIsMute,
  setIsPlay,
  setProgress,
  setProgressSliderValues,
  setRangesValue,
  setShowControls,
  setShowSettings,
  setShowSpeedPopup,
  setShowViewSection,
  setSpeed,
  setScreenClicked,
  setCurrentClass,
  setLink,
  resetToDefault,
} = playerSlice.actions;

export const playerReducer = playerSlice.reducer;
