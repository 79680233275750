import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { appReducer } from './app/app-slice';
import { authReducer } from './auth/auth-slice';
import { categoriesReducer } from './categories/categories-slice';
import { filterReducer } from './filter/filter-slice';
import { instructorsReducer } from './instructors/instructors-slice';
import { playerReducer } from './player/player-slice';
import { videosReducer } from './videos/videoSlice';
import { playlistReducer } from './playlist/playlist-slice';
import { likedClassReducer } from './likedClass/liked-class-slice';
import { historyReducer } from './history/history-slice';
import { searchReducer } from './search/search-slice';
import { plansReducer } from './plans/plans-slice';
import { paymentReducer } from './payment/payment-slice';

const rootReducer = combineReducers({
  videos: videosReducer,
  player: playerReducer,
  filter: filterReducer,
  auth: authReducer,
  categories: categoriesReducer,
  instructors: instructorsReducer,
  playlist: playlistReducer,
  likedClass: likedClassReducer,
  history: historyReducer,
  search: searchReducer,
  app: appReducer,
  plans: plansReducer,
  payment: paymentReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  devTools: true,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
