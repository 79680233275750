import React, { useCallback, useMemo, useState } from 'react';
import plus from '../assets/img/Icon feather-plus.svg';
import muteIcon from '../assets/img/soundOff.svg';
import SoundIcon from '../assets/img/soundOn.svg';
import { useNavigate } from 'react-router-dom';
import heartIcon from '../assets/img/heartLogo.svg';
import filledHeartIcon from '../assets/img/heartFillLogo.svg';
import TakeTrainingButton from '../components/buttons/take-training-button';
import ClassTrainingPopup from '../components/popups/TrainingPopup/ClassTrainingPopup';
import { useAppSelector } from '../redux/reduxHooks/useAppSelector';
import { setIsLoginModalOpen } from '../redux/auth/auth-slice';
import { useAppDispatch } from '../redux/reduxHooks/useAppDispatch';
import { addLikedClass, removeLikedClass } from '../redux/likedClass/liked-class-actions';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { IClass } from '../models/Video';

type Props = {
  muteToggle: () => void;
  isMuted: boolean;
  classId: string;
  video: IClass;
};

const div = document.createElement('div');
document.querySelector('body')!.appendChild(div);

export default function PlayingVideoLayer({ muteToggle, isMuted, classId, video }: Props) {
  const { user } = useAppSelector((state) => state.auth);
  const { likedClassesIds } = useAppSelector((state) => state.likedClass);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useAppDispatch();

  const isLiked = useMemo(() => likedClassesIds.includes(classId), [classId, likedClassesIds]);

  const like = useCallback(() => {
    if (!user) {
      dispatch(setIsLoginModalOpen(true));
      return;
    }
    dispatch(addLikedClass(classId));
  }, [classId, user]);
  const dislike = useCallback(async () => {
    if (!user) {
      dispatch(setIsLoginModalOpen(true));
      return;
    }
    await dispatch(removeLikedClass(classId));
  }, [classId, user]);

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [],
    strategy: 'fixed',
    placement: 'bottom-end',
  });

  return (
    <div className='absolute top-0 left-0 w-full h-full z-10 p-6 flex flex-col justify-between z-20'>
      <div className='flex justify-end'>
        {isLiked ? (
          <img
            alt='dislike'
            className='cursor-pointer w-8'
            src={filledHeartIcon}
            onClick={dislike}
          />
        ) : (
          <img alt='like' className='cursor-pointer w-8' src={heartIcon} onClick={like} />
        )}
      </div>
      <div className='flex justify-between'>
        {isMuted ? (
          <img
            alt='mute'
            className='cursor-pointer w-8'
            onClick={muteToggle}
            onTouchStart={muteToggle}
            src={muteIcon}
          />
        ) : (
          <img
            alt='sound'
            className='cursor-pointer w-8'
            onClick={muteToggle}
            onTouchStart={muteToggle}
            src={SoundIcon}
          />
        )}
        <TakeTrainingButton video={video} />
        <div className='relative'>
          <button
            ref={setReferenceElement}
            onClick={() => setShowPopup(true)}
            onTouchStart={() => setShowPopup(true)}
          >
            <img src={plus} className='w-8 cursor-pointer' alt='Plus' />
          </button>
          {showPopup &&
            ReactDOM.createPortal(
              <div
                ref={setPopperElement}
                style={styles.popper}
                className='absolute -top-2 -left-[240px] z-20'
                onTouchEnd={() => setShowPopup(false)}
                onMouseLeave={() => setShowPopup(false)}
                {...attributes.popper}
              >
                <ClassTrainingPopup open={showPopup} classId={classId} />
              </div>,
              div,
            )}
        </div>
      </div>
    </div>
  );
}
